/* eslint-disable camelcase */
import Vue from 'vue';

import {
  addDepartmentCannedMessage, deleteDepartmentCannedMessage,
  editDepartmentCannedMessage, getDepartmentCannedMsgs,
} from '@/api/apiList';

const msgState = {
  departmentCannedMsgs: {}, // department name as key and messages list as value
};

const getters = {
  getDepartmentCannedMsgs: (state) => (departmentId) => state.departmentCannedMsgs[departmentId]
    || [],
};

const mutations = {
  SET_CANNED_MSGS(state, { departmentId, msgs }) {
    Vue.set(state.departmentCannedMsgs, departmentId, msgs);
  },
  ADD_NEW_CANNED_MSG(state, { departmentId, data }) {
    state.departmentCannedMsgs[departmentId].push(data);
  },
  REMOVE_CANNED_MSG(state, { departmentId, id }) {
    const cannedMsgIndex = state.departmentCannedMsgs[departmentId]
      .findIndex((cm) => cm.id === id);
    state.departmentCannedMsgs[departmentId].splice(cannedMsgIndex, 1);
  },
  UPDATE_CANNED_MSG(state, { departmentId, id, data }) {
    const cannedMsgIndex = state.departmentCannedMsgs[departmentId]
      .findIndex((cm) => cm.id === id);
    Vue.set(state.departmentCannedMsgs[departmentId], cannedMsgIndex, data);
  },
};

const actions = {
  async ensureDepartmentCannedMsgsFetched({ state, dispatch }, { departmentId }) {
    if (!Object.prototype.hasOwnProperty.call(state.departmentCannedMsgs, departmentId)) {
      return dispatch('fetchAndSetDepartmentCannedMsgs', { departmentId });
    }
    return state.departmentCannedMsgs[departmentId];
  },
  async fetchAndSetDepartmentCannedMsgs({ commit }, { departmentId }) {
    const msgs = await getDepartmentCannedMsgs(departmentId);
    commit('SET_CANNED_MSGS', { departmentId, msgs });
    return msgs;
  },
  async addDepartmentCannedMsg({ commit }, {
    departmentId, content, subject, eligible_for_prediction,
  }) {
    const data = await addDepartmentCannedMessage(
      departmentId,
      content,
      subject,
      eligible_for_prediction,
    );
    commit('ADD_NEW_CANNED_MSG', { departmentId, data });
  },
  async deleteDepartmentCannedMsg({ commit }, { departmentId, id }) {
    const resp = await deleteDepartmentCannedMessage(departmentId, id);

    if (resp.status === 204) {
      commit('REMOVE_CANNED_MSG', { departmentId, id });
    }
  },
  async editDepartmentCannedMsg({ commit }, {
    departmentId, id, content, subject, eligible_for_prediction,
  }) {
    const data = await editDepartmentCannedMessage(
      departmentId,
      id,
      content,
      subject,
      eligible_for_prediction,
    );
    commit('UPDATE_CANNED_MSG', { departmentId, id, data });
  },
};

export default {
  state: msgState,
  getters,
  mutations,
  actions,
};
