<template>
  <span
    class="status-indicator"
    :class="statusTitles[status].color"
    :title="statusTitles[status].text"
  />
</template>

<script>

export default {
  name: 'StatusIndicator',
  props: {
    status: {
      type: String,
      default: 'IN',
    },
  },
  data() {
    return {
    };
  },
  computed: {
    statusTitles() {
      return {
        IN: {
          text: this.$t('vocabulary.invisible'),
          color: 'bg-danger',
        },
        AW: {
          text: this.$t('vocabulary.away'),
          color: 'bg-warning',
        },
        ON: {
          text: this.$t('vocabulary.online'),
          color: 'bg-success',
        },
      };
    },
  },
};
</script>

<style scoped>
  .status-indicator {
    min-height: .55rem;
    max-height: .55rem;
    min-width: .55rem;
    max-width: .55rem;
    border-radius: .55rem;
  }
</style>
