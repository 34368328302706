import { mapActions } from 'vuex';
import IncomingUpdateAndNotification from './incomingUpdateAndNotification';

export default {
  mixins: [IncomingUpdateAndNotification],
  data() {
    return {
    };
  },
  async created() {
    this.ensureControlSocketSet();
    try {
      const agentTenants = await this.ensureTenantsFetched();
      await Promise.all(agentTenants.map(async (tenant) => {
        const config = await this.ensureFullConfigOfTenantFetched({ tenantId: tenant.id });
        if (config.kpi && !config.kpi.apply) {
          this.ensureDepartmentsFetched();
        }
      }));
    } catch (err) { this.$log.error(err); }
  },

  methods: {
    ...mapActions('agent', ['ensureTenantsFetched']),
    ...mapActions('tenants', ['ensureFullConfigOfTenantFetched']),
    ...mapActions('departments', ['ensureDepartmentsFetched']),
    ...mapActions('controlSocket', ['ensureControlSocketSet']),

  },
};
