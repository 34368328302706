import Vue from 'vue';

import {
  getOpeningHoursPreview,
} from '@/api/apiList';

const openingHourState = {
  fetchingState: {},
};

const openingHourGetters = {
  getDepartmentOpeningHours:
    (state) => ({ tenantId, departmentId }) => state?.[tenantId]?.[departmentId] || [],
  getDepartmentCurrentOpenHours: (state, getters) => ({ tenantId, departmentId }) => {
    const departmentOpenHours = getters.getDepartmentOpeningHours({ tenantId, departmentId });
    if (!departmentOpenHours.length) return false;
    return departmentOpenHours.find(({ open, close }) => {
      const now = Date.now();
      return open <= now && close > now;
    });
  },
  getIsDepartmentOpen:
    (state, getters) => ({ tenantId, departmentId }) => !!getters
      .getDepartmentCurrentOpenHours({ tenantId, departmentId }),
};

const mutations = {
  SET_OPENING_HOURS(state, { tenantId, openingHours }) {
    const preparedOpeningHours = {};
    Object.entries(openingHours).forEach(([departmentId, openArray]) => {
      preparedOpeningHours[departmentId] = openArray.map(({ open, close }) => ({
        open: new Date(open),
        close: new Date(close),
      }));
    });
    Vue.set(state, tenantId, preparedOpeningHours);
  },
  SET_FETCHING(state, { tenantId, status }) {
    Vue.set(state.fetchingState, tenantId, status);
  },
};

const actions = {
  ensureOpeningHours({ state, dispatch }, { tenantId }) {
    if (tenantId in state) return;
    dispatch('fetchOpeningHours', { tenantId });
  },
  async fetchOpeningHours({ state, commit }, { tenantId }) {
    if (state.fetchingState[tenantId]) return;
    try {
      commit('SET_FETCHING', { tenantId, status: true });
      const openingHours = await getOpeningHoursPreview(tenantId, Math.floor(Date.now() / 1000), 1);
      commit('SET_OPENING_HOURS', { tenantId, openingHours });
    } catch (error) {
      // TODO
    } finally {
      commit('SET_FETCHING', { tenantId, status: false });
    }
  },
};

export default {
  namespaced: true,
  state: openingHourState,
  getters: openingHourGetters,
  mutations,
  actions,
};
