import Vue from 'vue';
import { sortBy } from 'lodash';
import { waitUntil } from 'supwiz/supchat/generalUtils';
import {
  addDepartment,
  updateDepartment,
  deleteDepartment,
  getDepartments,
} from '@/api/apiList';
import cannedMessages from './cannedMessage';
import openingHours from './openingHours';

let fetchingDepartments = false;

const departmentState = {
  departments: [],
};

const departmentGetters = {
  systemDepartments: (state) => state.departments.map((dep) => ({
    ...dep,
    fullName: `${dep.tenant_name} ${dep.name}`,
  })),
  departmentsOfTenant: (state, getters) => (tenantId) => {
    const filteredDeps = sortBy(
      getters.systemDepartments.filter((dep) => dep.tenant_id === tenantId),
      [(department) => `${department.name}`.toLowerCase()],
    );
    return filteredDeps;
  },
  getSingleDepartment: (state, getters) => (depId) => getters.systemDepartments
    .find((dep) => dep.id === depId),
};

const mutations = {
  SET_DEPARTMENTS(state, payload) {
    state.departments = payload;
  },
  DELETE_DEPARTMENT(state, { index }) {
    state.departments.splice(index, 1);
  },

};

const actions = {
  async fetchAndSetDepartments({ state, commit }) {
    try {
      if (fetchingDepartments) {
        await waitUntil(() => !fetchingDepartments);
      } else {
        fetchingDepartments = true;
        const data = await getDepartments();
        commit('SET_DEPARTMENTS', data);
        fetchingDepartments = false;
      }
    } catch (error) {
      commit('errorDisplay/ADD_MSG', {
        message: 'message.errorUnexpected',
        variant: 'danger',
      }, { root: true });
      Vue.$log.error(error);
    }
    return state.departments;
  },

  async ensureDepartmentsFetched({ state, dispatch }) {
    if (state.departments.length === 0) {
      return dispatch('fetchAndSetDepartments');
    }
    return state.departments;
  },

  async addDepartment({ commit, dispatch }, { departmentName, tenantId }) {
    try {
      await addDepartment({
        departmentName,
        tenantId,
        selectableByVisitor: true, // no space in frontend to set during creation currently
      });
      await dispatch('fetchAndSetDepartments');
      commit('errorDisplay/ADD_MSG', {
        message: 'message.tenantManagement.depAddSuccess',
        variant: 'success',
      }, { root: true });
    } catch (error) {
      commit('errorDisplay/ADD_MSG', {
        message: 'message.tenantManagement.depAddError',
        variant: 'danger',
      }, { root: true });
      Vue.$log.error(error);
      throw error;
    }
  },
  async updateDepartment({ commit, dispatch }, {
    id: departmentId,
    selectable_by_visitor: selectableByVisitor,
    can_be_transferred_to: canBeTransferredTo,
    data_field: dataField,
  }) {
    try {
      await updateDepartment({
        departmentId,
        selectableByVisitor,
        canBeTransferredTo,
        dataField,
      });
      await dispatch('fetchAndSetDepartments');
    } catch (error) {
      commit('errorDisplay/ADD_MSG', {
        message: 'errors.unknownError',
        variant: 'danger',
      }, { root: true });
      Vue.$log.error(error);
      throw error;
    }
  },
  async updateDepartmentKPI({ dispatch }, {
    id: departmentId,
    kpi_chat_response_time: kpiChatResponseTime,
    kpi_total_waiting_time: kpiTotalWaitingTime,
  }) {
    await updateDepartment({
      departmentId,
      kpiChatResponseTime,
      kpiTotalWaitingTime,
    });
    await dispatch('agent/fetchAndSetAgentDepartments', null, { root: true });
  },
  async deleteDepartment({ state, commit, dispatch }, { departmentId }) {
    try {
      const resp = await deleteDepartment(departmentId);
      if (resp.status === 204) {
        const index = state.departments.findIndex((x) => x.id === departmentId);
        commit('DELETE_DEPARTMENT', { index });
        await dispatch('fetchAndSetDepartments');
        return true;
      }
      return false;
    } catch (error) {
      commit('errorDisplay/ADD_MSG', {
        message: 'errors.unknownError',
        variant: 'danger',
      }, { root: true });
      Vue.$log.error(error);
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state: departmentState,
  getters: departmentGetters,
  mutations,
  actions,
  modules: {
    cannedMessages,
    openingHours,
  },
};
