/** Contains the API shared among different frontend apps  */
import fetchData from 'supwiz/supchat/httpRequest';

// historyParsingMixin
function getActorInfo(senderID, baseURL = null) {
  return fetchData({
    url: `/control/actor-info/${senderID}`, method: 'GET', baseURL,
  });
}

// widget, tenantwidget
function chatOngoing(baseURL = null) {
  return fetchData({ url: '/control/is-chat-ongoing', method: 'GET', baseURL });
}

export {
  chatOngoing,
  getActorInfo,
};
