// used by getMetadataIcon
// if there's multiple matches, the top-most one is used
const metadataIconMap = {
  fallback: 'info',
  department: 'building',
  agent: 'user-tie',
  name: 'user',
  mail: 'at',
  ticket: 'ticket',
  tel: 'phone',
  phone: 'phone',
  device: 'mobile-screen',
  os: 'mobile-screen',
  browser: 'globe',
  link: 'link',
  url: 'link',
  language: 'language',
  comment: 'comment',
  rating: 'star',
};

export function getMetadataIcon(key) {
  const lKey = `${key}`.toLowerCase();
  if (metadataIconMap[lKey]) return metadataIconMap[lKey];
  const iconKeys = Object.keys(metadataIconMap);
  const matchingKey = iconKeys.find((iKey) => lKey.includes(iKey));
  return metadataIconMap[matchingKey] || metadataIconMap.fallback;
}

export function getEnvironment() {
  // Be cautious of what you allow yourself to use this value for. An enduser
  // (although a technical one) may change the value him/herself in the developer console.
  // In any case you should not rely on information based in the frontend to allow/disallow making
  // changes in the backend; the backend should always itself verify that the user is allowed to
  // perform the requested operation.

  // window.supchatEnvironment is not set anywhere yet.
  return process.env.NODE_ENV !== 'production' ? process.env.NODE_ENV : window.supchatEnvironment;
}

export function showTimeDifference(deltaTime, locale = 'en') {
  const deltaTimeS = Math.floor(deltaTime / 1000);
  const formatter = new Intl.RelativeTimeFormat(locale, { numeric: 'auto' });
  let tempVal = deltaTimeS >= 0 ? deltaTimeS : deltaTimeS * -1;
  const map = [
    ['year', 31_536_000],
    ['month', 2_592_000],
    ['week', 604_800],
    ['day', 86_400],
    ['hour', 3_600],
    ['minute', 60],
  ];
  let unit = 'second';
  for (const [key, s] of map) {
    if (tempVal >= s) {
      unit = key;
      tempVal = Math.floor(deltaTimeS / s);
      break;
    }
  }
  return formatter.format(tempVal, unit);
}

export function getTimezone() {
  return Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || 'Europe/Copenhagen';
}

export function formatToIsoString(date) {
  const string = new Date(date).toISOString();
  return `${string.split('.')[0]}Z`;
}

export function mapDecimalToColor(decimal, options) {
  const saturation = options?.saturation || 70;
  const lightness = options?.lightness || 50;
  const offset = options?.offset || 120;
  const hue = (decimal * offset).toString(10);
  return `hsl(${hue},${saturation}%,${lightness}%)`;
}
