import { render, staticRenderFns } from "./AgentItem.vue?vue&type=template&id=8d2e8dac&scoped=true"
import script from "./AgentItem.vue?vue&type=script&lang=js"
export * from "./AgentItem.vue?vue&type=script&lang=js"
import style0 from "./AgentItem.vue?vue&type=style&index=0&id=8d2e8dac&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8d2e8dac",
  null
  
)

export default component.exports