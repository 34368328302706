import { getSenderName } from 'supwiz/supchat/generalUtils';
import { chatEvent } from 'supwiz/supchat/constants';

export default {
  data() {
    return {
      visitorIsTyping: false,
      timeout: null,
    };
  },
  computed: {
    typingSender() {
      const fallback = {
        name: '',
        id: '',
        role: '',
      };
      for (let i = this.history.length - 1; i >= 0; i--) {
        const msg = this.history[i];
        if (msg.command === chatEvent.TYPING && msg.text === false
          && msg.sender_role === this.monitorTypingSenderRole) {
          return fallback;
        }
        if (msg.command === chatEvent.TYPING && msg.text === true
          && msg.sender_role === this.monitorTypingSenderRole) {
          return {
            name: getSenderName(this.sendersInfo, msg.sender_id, msg.sender_role),
            id: msg.sender_id,
            role: msg.sender_role,
          };
        }
      }
      return fallback;
    },
  },
  methods: {
    typingOn() {
      if (this.visitorIsTyping) {
        clearTimeout(this.timeout);
        if (this.message === '') {
          this.typingOff();
        } else {
          this.timeout = setTimeout(this.typingOff, 1000);
        }
        return;
      }
      const cmd = {
        command: chatEvent.TYPING,
        chat_id: this.chatId,
        text: true,
      };
      this.sendCommandWrapper(cmd);
      this.visitorIsTyping = true;
      this.timeout = setTimeout(this.typingOff, 1000);
    },
    typingOff() {
      const cmd = {
        command: chatEvent.TYPING,
        chat_id: this.chatId,
        text: false,
      };
      this.sendCommandWrapper(cmd);
      this.visitorIsTyping = false;
    },
  },
};
