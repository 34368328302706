<template>
  <transition
    v-if="!multiple"
    name="fade-up"
    mode="out-in"
    v-bind="$attrs"
  >
    <slot />
  </transition>
  <transition-group
    v-else
    name="fade-up"
    mode="out-in"
    v-bind="$attrs"
  >
    <slot />
  </transition-group>
</template>

<script>
export default {
  name: 'TransitionFadeUp',
  inheritAttrs: false,
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
  .fade-up-enter-move,
  .fade-up-enter-active,
  .fade-up-leave-active {
    transition: all 0.15s ease!important;
  }

  .fade-up-enter,
  .fade-up-enter-from,
  .fade-up-leave-to {
    opacity: 0!important;
    transform: translateY(10px)!important;
  }

  @media (prefers-reduced-motion: reduce) {
    .fade-up-enter-active,
    .fade-up-leave-active {
      transition: none;
    }
  }
</style>
