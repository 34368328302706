<template>
  <transition
    v-if="!multiple"
    name="fade-in"
    mode="out-in"
    v-bind="$attrs"
  >
    <slot />
  </transition>
  <transition-group
    v-else
    name="fade-in"
    mode="out-in"
    v-bind="$attrs"
  >
    <slot />
  </transition-group>
</template>

<script>
export default {
  name: 'TransitionFadeIn',
  inheritAttrs: false,
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
.fade-in-enter-move,
.fade-in-enter-active,
.fade-in-leave-active {
  transition: all 0.15s ease!important;
}

.fade-in-enter,
.fade-in-enter-from,
.fade-in-leave-to {
  opacity: 0!important;
}

@media (prefers-reduced-motion: reduce) {
  .fade-in-enter-active,
  .fade-in-leave-active {
    transition: none;
  }
}
</style>
