import Vue from 'vue';
import { sortBy } from 'lodash';
import { waitUntil } from 'supwiz/supchat/generalUtils';
import {
  getSystemTenants,
  addSystemTenant, deleteSystemTenant,
  getTags,
} from '../../../api/apiList';
import cannedMessages from './cannedMessage';
import openingHours from './openingHours';
import predictions from './predictions';
import configs from './config';
import anonymizer from './anonymizer';
import chatWidgetConfigs from './chatWidgetConfigs';
import languages from './languages';

let fetchingTags = false;
let fetchingTenants = false;

const tenantState = {
  systemTenants: {},
  tenantTags: {},
};

const tenantGetters = {
  systemTenantList: (state) => sortBy(Object.entries(state.systemTenants)
    .map(([id, { name }]) => ({ id, name })), [(t) => t.name.toLowerCase()]),
  getDepartmentsOfTenant: (
    state,
    getters,
    rootState,
    rootGetters,
  ) => (tenantId) => rootGetters['departments/departmentsOfTenant'](tenantId),
  getTags: (state) => state.tenantTags,
};

const mutations = {
  SET_TAGS(state, { tags }) {
    Vue.set(state, 'tenantTags', tags);
  },
  SET_SYSTEM_TENANTS(state, tenants) {
    tenants.forEach(({ id, ...details }) => {
      Vue.set(state.systemTenants, id, { id, ...details });
    });
  },
  DELETE_SYSTEM_TENANT(state, { tenantId }) {
    Vue.delete(state.systemTenants, tenantId);
  },
};

const actions = {
  async fetchTags({ commit, state }) {
    if (fetchingTags) {
      await waitUntil(() => !fetchingTags);
    } else {
      fetchingTags = true;
      const { pk2tag } = await getTags();
      commit('SET_TAGS', { tags: pk2tag });
      fetchingTags = false;
    }
    return state.tenantTags;
  },
  async ensureDepartmentsOfTenantFetched({ getters, dispatch }, { tenantId }) {
    if (!getters.getDepartmentsOfTenant(tenantId).length) {
      return dispatch('departments/fetchAndSetDepartments', true, { root: true });
    }
    return getters.getDepartmentsOfTenant(tenantId);
  },

  async fetchAndSetSystemTenants({ commit, state }) {
    if (fetchingTenants) {
      await waitUntil(() => !fetchingTenants);
    } else {
      fetchingTenants = true;
      const tenants = await getSystemTenants();
      commit('SET_SYSTEM_TENANTS', tenants);
      fetchingTenants = false;
    }
    return state.systemTenants;
  },
  async ensureSystemTenantsFetched({ state, dispatch }) {
    if (Object.keys(state.systemTenants).length === 0) {
      return dispatch('fetchAndSetSystemTenants');
    }
    return state.systemTenants;
  },
  async addSystemTenant({ commit, dispatch }, { tenantName }) {
    try {
      const tenant = await addSystemTenant(tenantName);
      commit('SET_SYSTEM_TENANTS', [tenant]);
      await dispatch('agent/fetchAndSetAgentTenants', null, { root: true });
      return tenant;
    } catch (error) {
      commit('errorDisplay/ADD_MSG', {
        message: 'message.tenantManagement.addToastErrorBody',
        variant: 'danger',
      }, { root: true });
      Vue.$log.error(error);
      throw error;
    }
  },
  async deleteSystemTenant({ commit, dispatch }, { tenantId }) {
    try {
      await deleteSystemTenant(tenantId);
      commit('DELETE_SYSTEM_TENANT', { tenantId });
      await dispatch('agent/fetchAndSetAgentTenants', null, { root: true });
    } catch (error) {
      commit('errorDisplay/ADD_MSG', {
        message: 'errors.unknownError',
        variant: 'danger',
      }, { root: true });
      Vue.$log.error(error);
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state: tenantState,
  getters: tenantGetters,
  mutations,
  actions,
  modules: {
    anonymizer,
    cannedMessages,
    configs,
    languages,
    openingHours,
    predictions,
    chatWidgetConfigs,
  },
};
