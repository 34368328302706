<template>
  <div>
    <div
      v-if="!ready"
      class="d-flex h-100 w-100 position-absolute justify-content-center align-items-center"
    >
      <b-spinner
        variant="muted"
        style="height:5rem;width:5rem;"
      />
    </div>
    <template v-else>
      <floating-container>
        <h4>{{ $t('message.greeting', { name: agentName }) }}</h4>
        {{ $t('message.chatsHandled', { count: agentStats.chat_count }) }}
        <hr>
        <NoticeBoard />
        <h4>{{ $t('message.depOverview') }}</h4>
        <table class="table table-bordered mb-0">
          <tr>
            <th>{{ $tc('vocabulary.tenant', 1) }}</th>
            <th>{{ $tc('vocabulary.department', 1) }}</th>
            <th>{{ $t('message.chatsInQueue') }}</th>
            <th>{{ $t('message.chatsActive') }}</th>
          </tr>
          <template v-for="(deps, tenant, i) in queueStats">
            <tr
              v-for="(queues, dep, index) in deps"
              :key="i + dep + index"
            >
              <td>{{ tenant }}</td>
              <td>{{ dep }}</td>
              <td>{{ queues.incoming.count }}</td>
              <td>{{ queues.active.count }}</td>
            </tr>
          </template>
        </table>
      </floating-container>
      <AgentOverviewsSection v-if="isManager" />
    </template>
    <router-view />
  </div>
</template>

<script>
import { getAgentTodayStats, getQueueStats } from '@/api/apiList';
import { getTimezone } from '@/utils/generalUtils';

export default {
  name: 'HomePage',
  components: {
    AgentOverviewsSection: () => import('./homePage/AgentOverview/TheSection.vue'),
    NoticeBoard: () => import('./homePage/NoticeBoard/TheSection.vue'),
  },
  data() {
    return {
      agentStats: {},
      queueStats: {},
      ready: false,
    };
  },
  computed: {
    agentName() {
      return this.$store.getters['agent/displayName'];
    },
    isManager() {
      return this.$store.getters['agent/maxRole'] > 1;
    },
  },
  async created() {
    const redirectPath = localStorage.getItem('redirect-path');
    if (redirectPath) {
      localStorage.removeItem('redirect-path');
      if (redirectPath !== this.$route.path) {
        this.$router.push({ path: redirectPath });
        return;
      }
    }
    try {
      const params = {
        timezone: getTimezone(),
      };
      const [agentStats, queueStats] = await Promise
        .all([getAgentTodayStats(params), getQueueStats()]);
      this.agentStats = agentStats;
      this.queueStats = queueStats;
      this.ready = true;
    } catch (err) {
      this.$log.debug(err);
    }
  },
};
</script>
