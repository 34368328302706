import Vue from 'vue';
import { waitUntil, deepFreeze } from 'supwiz/supchat/generalUtils';
import { sortBy } from 'lodash';

import {
  checkAgentAuth,
  getAgentInfo,
  getAgentTenants,
  getAgentDepartments,
  getManagedAgents,
  loginAgent,
  logoutAgent,
} from '@/api/apiList';

import settings from './settings';
import overviews from './overviews';

// Used for keeping track of what is currently being fetched
const fetchingStatus = new Set();

const INFO_TEMPLATE = {
  id: null,
  displayName: null,
  maxRole: null,
};

const AgentState = {
  isLoggedIn: false,
  info: { ...INFO_TEMPLATE },
  tenantsWithRoles: [], // Array[Object] as {id: xxx, name: xxx, role: yyy}
  departmentsWithRoles: [], // Array {id: x, name: x, role: y, tenant_id: x, tenant_name: x}
  managerAgents: [],
};

const agentGetters = {
  displayName: (state) => state.info.displayName,
  id: (state) => state.info.id,
  isLoggedIn: (state) => state.isLoggedIn,
  maxRole: (state) => state.info.maxRole,
  tenants: (state) => state.tenantsWithRoles,
  tenantsAsAgent: (state) => state.tenantsWithRoles.filter((t) => t.role >= 1),
  tenantsAsManager: (state) => state.tenantsWithRoles.filter((t) => t.role >= 2),
  getManagedAgents: (state) => state.managerAgents,
  isManagerOfDep: (state, getters) => (depId) => getters.departments
    .some((dep) => dep.id === depId && dep.role >= 2),
  isManagerOfTenant: (state, getters) => (tenantId) => getters.tenantsAsManager
    .some((tenant) => tenant.id === tenantId),
  departments: (state) => state.departmentsWithRoles,
  departmentsAsManager: (state, getters) => getters.departments
    .filter((dep) => dep.role >= 2),
  departmentNameIncludeTenantName: (state) => {
    const data = [];
    for (const dep of state.departmentsWithRoles) {
      const tmp = { id: dep.id, name: `${dep.tenant_name}: ${dep.name}` };
      data.push(tmp);
    }
    return data;
  },
};

const mutations = {
  SET_LOGIN_STATE(state, payload) {
    state.isLoggedIn = payload;
  },
  SET_AGENT_INFO(state, agentInfo) {
    Vue.set(state, 'info', agentInfo);
  },

  SET_TENANTS_WITH_ROLES(state, payload) {
    const sortedTenants = sortBy(payload, [(t) => t.name.toLowerCase(), 'id']);
    state.tenantsWithRoles = deepFreeze(sortedTenants);
  },

  SET_DEPS_WITH_ROLES(state, payload) {
    const result = payload.map((dep) => ({
      ...dep,
      fullName: `${dep.tenant_name}: ${dep.name}`,
    }));
    state.departmentsWithRoles = deepFreeze(result);
  },
  SET_MANAGED_AGENTS(state, data) {
    const agentList = [];
    for (const [id, name] of Object.entries(data)) {
      agentList.push({ id, name });
    }
    state.managerAgents = deepFreeze(agentList);
  },

};

const actions = {
  async login({ commit, dispatch }, data) {
    const resp = await loginAgent(data);
    if (Object.prototype.hasOwnProperty.call(resp, 'agent_id')) {
      commit('SET_LOGIN_STATE', true);
      await Promise.all([
        dispatch('updateSidebarState', true, { root: true }),
        dispatch('ensureInfoFetched'),
        dispatch('settings/ensureAgentSettings'),
        dispatch('ensureTenantsFetched'),
        dispatch('ensureDepartmentsFetched'),
        dispatch('chat/ensureChatSocketSet', true, { root: true }),
      ]);
      return { message: 'success' };
    }
    return resp;
  },
  async logout({ commit, dispatch }) {
    commit('SET_LOGIN_STATE', false);
    dispatch('status/setAgentStatus', { agentId: 'me', status: 'IN' }, { root: true });
    dispatch('updateSidebarState', false, { root: true });
    commit('SET_AGENT_INFO', { ...INFO_TEMPLATE });
    localStorage.clear();
    sessionStorage.clear();
    commit('chat/CLEAR_INCOMING_POLLING_INTERVAL', null, { root: true });
    commit('chat/CLEAR_ONGOING_POLLING_INTERVAL', null, { root: true });
    dispatch('chat/closeWebSocket', null, { root: true });
    dispatch('controlSocket/closeWebSocket', null, { root: true });
    return logoutAgent();
  },

  async fetchAndSetInfo({ state, commit }) {
    if (fetchingStatus.has('fetchingAgentInfo')) {
      await waitUntil(() => !fetchingStatus.has('fetchingAgentInfo'));
    } else {
      fetchingStatus.add('fetchingAgentInfo');
      const {
        id,
        email,
        display_name: displayName,
        external_uri: externalUri,
        max_role: maxRole,
      } = await getAgentInfo();
      commit('SET_AGENT_INFO', {
        id, displayName, maxRole, email, externalUri,
      });
      fetchingStatus.delete('fetchingAgentInfo');
    }
    return state.info;
  },
  async ensureInfoFetched({ state, dispatch }) {
    if (!state.info.id || !state.info.displayName || !state.info.maxRole) {
      return dispatch('fetchAndSetInfo');
    }
    return state.info;
  },
  async checkStatus({ state, commit, dispatch }) {
    if (fetchingStatus.has('checkingAgentAuth')) {
      await waitUntil(() => !fetchingStatus.has('checkingAgentAuth'));
    } else {
      try {
        fetchingStatus.add('checkingAgentAuth');
        const resp = await checkAgentAuth();
        if (resp.logged_in) {
          commit('SET_LOGIN_STATE', true);
          await Promise.all([
            dispatch('updateSidebarState', true, { root: true }),
            dispatch('ensureInfoFetched'),
            dispatch('settings/ensureAgentSettings'),
            dispatch('ensureTenantsFetched'),
            dispatch('ensureDepartmentsFetched'),
            dispatch('chat/ensureChatSocketSet', true, { root: true }),
            dispatch('status/getStatusOverview', true, { root: true }),
          ]);
        } else {
          commit('SET_LOGIN_STATE', false);
          dispatch('updateSidebarState', false, { root: true });
        }
      } catch (error) {
        Vue.$log.error(error);
        commit('SET_LOGIN_STATE', false);
        dispatch('updateSidebarState', false, { root: true });
      } finally {
        fetchingStatus.delete('checkingAgentAuth');
      }
    }
    return state.isLoggedIn;
  },
  /* ========================= Agent Tenant ============================ */
  async fetchAndSetAgentTenants({ state, commit }) {
    if (fetchingStatus.has('fetchingAgentTenants')) {
      await waitUntil(() => !fetchingStatus.has('fetchingAgentTenants'));
    } else {
      fetchingStatus.add('fetchingAgentTenants');
      const tenantsWithRoles = await getAgentTenants();
      commit('SET_TENANTS_WITH_ROLES', tenantsWithRoles);
      fetchingStatus.delete('fetchingAgentTenants');
    }
    return state.tenantsWithRoles;
  },
  async ensureTenantsFetched({ state, dispatch }) {
    if (state.tenantsWithRoles.length === 0) {
      return dispatch('fetchAndSetAgentTenants');
    }
    return state.tenantsWithRoles;
  },

  /* ========================= Agent Department ============================ */
  async fetchAndSetAgentDepartments({ state, commit }) {
    if (fetchingStatus.has('fetchingAgentDepartments')) {
      await waitUntil(() => !fetchingStatus.has('fetchingAgentDepartments'));
    } else {
      fetchingStatus.add('fetchingAgentDepartments');
      const data = await getAgentDepartments();
      commit('SET_DEPS_WITH_ROLES', data);
      fetchingStatus.delete('fetchingAgentDepartments');
    }
    return state.departmentsWithRoles;
  },

  async ensureDepartmentsFetched({ state, dispatch }) {
    if (state.departmentsWithRoles.length === 0) {
      return dispatch('fetchAndSetAgentDepartments');
    }
    return state.departmentsWithRoles;
  },
  /* ========================= Manager Specific ============================ */
  async ensureManagedAgentsFetched({ state, dispatch }) {
    if (state.managerAgents.length === 0) {
      return dispatch('fetchAndSetManagedAgents');
    }
    return state.managerAgents;
  },
  async fetchAndSetManagedAgents({ state, commit }) {
    if (fetchingStatus.has('fetchingManagedAgents')) {
      await waitUntil(() => !fetchingStatus.has('fetchingManagedAgents'));
    } else {
      fetchingStatus.add('fetchingManagedAgents');
      const data = await getManagedAgents();
      commit('SET_MANAGED_AGENTS', data);
      fetchingStatus.delete('fetchingManagedAgents');
    }
    return state.managerAgents;
  },

};

export default {
  namespaced: true,
  state: AgentState,
  getters: agentGetters,
  mutations,
  actions,
  modules: {
    overviews,
    settings,
  },
};
