export function simplifyWordMatching(matchingRules) {
  const result = {
    requiredWords: [[]],
    disallowedWords: [],
    categories: [],
    fuzzyMatchEnabled: false,
    categoriesMatchAsOr: false,
  };
  if (matchingRules) {
    const listAllowedWords = [];
    const disallowedWords = [];
    result.fuzzyMatchEnabled = JSON.stringify(matchingRules).includes('fuzzy_match_on_phrase_from_chat');

    matchingRules.children.forEach((outerChild) => {
      if (outerChild.kind === 'all') {
        outerChild.children.forEach((child) => {
          if (child.kind === 'category') {
            result.categories.push(child.category_id);
          } else if (child.kind === 'any') {
            const words = [];
            child.children.forEach((innerChild, index) => {
              if (innerChild.kind === 'category') {
                if (index === 0) result.categoriesMatchAsOr = true;
                result.categories.push(innerChild.category_id);
              } else {
                words.push(innerChild.phrase);
              }
            });
            if (words.join() !== '') listAllowedWords.push(words);
          }
        });
      } else if (outerChild.kind === 'not') {
        const words = outerChild.child.children.map((word) => word.phrase);
        if (words.join() !== '') disallowedWords.push(...words);
      }
    });
    result.requiredWords = listAllowedWords;
    result.disallowedWords = disallowedWords;
  }
  return result;
}

export function prepareBoolExpr({
  hasWordMatching,
  fuzzyMatchEnabled,
  requiredWords,
  categories,
  disallowedWords,
  categoriesMatchAsOr,
}) {
  if (!hasWordMatching) return null;
  const matchingKind = fuzzyMatchEnabled
    ? 'fuzzy_match_on_phrase_from_chat'
    : 'match_on_phrase_from_chat';
  const result = {
    kind: 'all',
    children: [
      {
        kind: 'all',
        children: [],
      },
    ],
  };
  if (categories) {
    if (categoriesMatchAsOr) {
      result.children[0].children.push({
        kind: 'any',
        children: [],
      });
    }
    for (const categoryId of categories) {
      const categoryObject = {
        kind: 'category',
        category_id: categoryId,
      };
      if (categoriesMatchAsOr) {
        result.children[0].children[0].children.push(categoryObject);
      } else {
        result.children[0].children.push(categoryObject);
      }
    }
  }
  for (const arrayOfWords of requiredWords) {
    if (!Array.isArray(arrayOfWords) || !arrayOfWords.length) continue;
    const anyObject = {
      kind: 'any',
      children: arrayOfWords.map((anyWord) => ({
        kind: matchingKind,
        phrase: anyWord,
      })),
    };
    result.children[0].children.push(anyObject);
  }

  // Finally, prepare and add the disallowed words
  const disAllowedObject = {
    kind: 'not',
    child: {
      kind: 'any',
      children: disallowedWords.map((disAllowedWord) => ({
        kind: matchingKind,
        phrase: disAllowedWord,
      })),
    },
  };
  result.children.push(disAllowedObject);
  return result;
}
