<template>
  <b-popover
    v-bind="{ ...$attrs }"
    placement="lefttop"
    :title="$t('vocabulary.preview')"
  >
    <div
      class="small"
      style="white-space:pre-wrap;"
    >
      {{ conversation }}
    </div>
  </b-popover>
</template>

<script>
import {
  msgsToPreview,
} from 'supwiz/supchat/generalUtils';

export default {
  name: 'HoverChatPopover',
  props: {
    chat: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    conversation() {
      const senderNames = {
        ...this.chat.active_agents,
        visitor: this.chat.visitor_name,
      };
      const conversation = msgsToPreview(this.chat.msgs, senderNames, '\n');
      if (!/\S/.test(conversation)) return this.$t('message.noVisitorMessages');
      return conversation;
    },
  },
};
</script>
