<template>
  <span>
    {{ formatted }}
  </span>
</template>

<script>
import { secondsToHHMMSS } from '@/utils/analytics';
import { useCurrentTime } from '@/composables/currentTime';

export default {
  name: 'QueueTimeDisplay',
  props: {
    startTime: {
      type: Number,
      required: true,
    },
    chatId: {
      type: String,
      required: true,
    },
  },
  setup() {
    const currentTime = useCurrentTime('QueueTimeDisplay');
    return { currentTime };
  },
  computed: {
    queueTime() {
      return this.currentTime - this.startTime;
    },
    formatted() {
      return secondsToHHMMSS((this.queueTime) / 1000);
    },
  },
};
</script>

<style>

</style>
