<template>
  <div
    :class="`${id}`"
    class="sw-multiselect"
  >
    <label
      :for="`${id}-input-el`"
      class="sr-only"
    >
      {{ `${$t('vocabulary.select')} ${$t(typename)}` }}
    </label>
    <input
      :id="`${id}-input-el`"
      type="text"
      class="custom-select w-100"
      :aria-invalid="!computedState"
      :class="[
        `${['sm', 'lg'].includes(size) ? `custom-select-${size}` : ''} ${id}`,
        { 'is-invalid': !computedState },
      ]"
      :value="
        `${value.length || 0} ${$tc(typename, value.length)} ${$t('vocabulary.selected')}`"
      readonly
      @click="showDropdown = !showDropdown"
    >
    <div class="w-100">
      <div
        v-if="showDropdown"
        class="bg-white shadow mx-2 py-2 r-50"
      >
        <b-button-group
          v-if="!noShortcuts"
          class="px-2 w-100 pb-2"
          size="sm"
        >
          <b-button variant="light" @click="bulkSelect('all')">
            {{ $t('vocabulary.all') }}
          </b-button>
          <b-button variant="light" @click="bulkSelect('none')">
            {{ $t('vocabulary.none') }}
          </b-button>
          <b-button variant="light" @click="bulkSelect('invert')">
            {{ $t('vocabulary.invert') }}
          </b-button>
        </b-button-group>
        <div class="px-2">
          <input
            v-if="searchable"
            ref="searchInput"
            v-model="filter"
            :aria-label="$t('vocabulary.search')"
            :placeholder="`${$t('vocabulary.search')} ${$tc(typename)}`"
            type="text"
            class="form-control-plaintext px-2 border-bottom mb-2"
            @keyup.down="indexChange"
            @keyup.up="indexChange"
            @keydown.enter="update(currentIndex)"
          >
        </div>
        <ul class="sw-multiselect-list">
          <li
            v-for="(option, i) in filteredSelectables"
            :key="option.value"
            class="py-2 pl-2 d-block"
            :class="{ 'bg-light': currentIndex === i }"
            @mouseover="currentIndex = i"
            @click="update(i)"
          >
            <div
              class="custom-control custom-checkbox"
            >
              <input
                :id="option.value"
                type="checkbox"
                :checked="finalResult.includes(option.value)"
                class="custom-control-input"
                :disabled="!finalResult.includes(option.value)
                  && amountSelected >= max && max > 0"
                @change="update(i)"
              >
              <label
                class="custom-control-label justify-content-start"
                :for="option.value"
              >{{ option.text }}</label>
            </div>
          </li>
        </ul>
        <div class="pl-2 mt-2">
          <b-button
            variant="primary"
            class="mr-2"
            @click="confirm"
          >
            {{ $t('vocabulary.confirm') }}
          </b-button>
          <b-button
            variant="secondary"
            @click="toggleDropdown"
          >
            {{ $t('vocabulary.close') }}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sortBy } from 'lodash';

export default {
  name: 'SWMultiSelect',
  props: {
    searchable: { type: Boolean, default: false },
    noSorting: { type: Boolean, default: false },
    noShortcuts: { type: Boolean, default: false },
    options: { type: Array, required: true },
    typename: { type: String, default: '' },
    size: { type: String, default: '' },
    value: { type: Array, default: () => [] },
    max: { type: Number, default: 0 },
    state: {
      validator: (val) => typeof !!val === 'boolean',
      default: null,
    },
  },
  data() {
    return {
      currentIndex: 0,
      filter: '',
      showDropdown: false,
      selectablesArray: [],
      finalResult: [],
    };
  },
  computed: {
    computedState() {
      if (this.state === null) return true;
      return !!this.state;
    },
    amountSelected() {
      return this.finalResult.length;
    },
    filteredSelectables() {
      const result = this.selectablesArray.filter(
        (x) => {
          if (typeof (x.value) === 'string') {
            return x.value.toLowerCase().includes(this.filter.toLowerCase())
              || x.text.toLowerCase().includes(this.filter.toLowerCase());
          }
          return x.value === this.filter
            || x.text.toLowerCase().includes(this.filter.toLowerCase());
        },
      );
      if (this.noSorting) return result;
      return sortBy(result, [(s) => s.text.toLowerCase()]);
    },
    id() {
      return `ms-${this._uid}`;
    },
  },
  watch: {
    options(newVal) {
      this.selectablesArray = newVal;
    },
    value(newVal) {
      this.finalResult = Array.from(newVal);
    },
    showDropdown(newVal) {
      if (newVal && this.searchable) {
        this.$nextTick(() => {
          this.$refs.searchInput.focus();
        });
      }
      if (this.value !== this.finalResult) {
        this.finalResult = Array.from(this.value);
      }
      this.outsideClick(newVal);
    },
    filter() {
      this.currentIndex = 0;
    },
  },
  mounted() {
    this.selectablesArray = this.options;
    this.finalResult = Array.from(this.value);
    this.confirm();
  },
  methods: {
    bulkSelect(value) {
      const allSelectable = this.selectablesArray.map((b) => b.value);
      if (value === 'all') {
        this.finalResult = allSelectable;
      } else if (value === 'invert') {
        this.finalResult = allSelectable.filter((single) => this.finalResult.indexOf(single) < 0);
      } else {
        this.finalResult = [];
      }
    },
    confirm() {
      const selectableValues = this.options.map((e) => e.value);
      const valids = this.finalResult.filter((e) => selectableValues.includes(e));
      if (valids.length !== this.finalResult.length) {
        this.finalResult = valids;
      }
      this.$emit('input', this.finalResult);
      this.showDropdown = false;
      this.filter = '';
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
      this.filter = '';
    },
    indexChange(ev) {
      if (ev.key === 'ArrowUp' && this.currentIndex > 0) {
        --this.currentIndex;
      }
      if (ev.key === 'ArrowDown' && this.currentIndex < (this.filteredSelectables.length - 1)) {
        ++this.currentIndex;
      }
    },
    update(i) {
      const val = this.filteredSelectables[i].value;
      const index = this.finalResult.indexOf(val);
      if (index !== -1) {
        this.finalResult.splice(index, 1);
      } else if (index === -1 && (this.max === 0 || this.max > this.amountSelected)) {
        this.finalResult.push(val);
      }
    },
    outsideClick(val) {
      const isClicked = (e) => {
        const el = document.querySelector(`.${this.id}`);
        if (el && !el.contains(e.target)) {
          document.removeEventListener('click', isClicked);
          this.showDropdown = false;
        }
      };
      if (val) {
        document.addEventListener('click', isClicked);
      }
    },
  },
};
</script>

<style>

</style>

<style scoped>
  .input-group > .sw-multiselect:not(:first-child) > .custom-select {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .sw-multiselect {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
  }
  .sw-multiselect > div {
    position: absolute;
    z-index: 99999;
    top: 20px;
  }
  .sw-multiselect .sw-multiselect-list {
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: visible;
    margin: 0;
    padding: 0;
    list-style: none;
  }
</style>
