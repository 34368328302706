<template>
  <div
    class="d-flex align-items-center agent-item"
  >
    <status-indicator
      :status="status"
      class="mr-2"
    />
    <span class="text-truncate font-weight-light text-color">
      {{ displayName }}
    </span>
  </div>
</template>

<script>
import StatusIndicator from '@/components/Sidebar/StatusIndicator.vue';

const propStringRequired = {
  type: String,
  required: true,
};
export default {
  components: {
    StatusIndicator,
  },
  inheritAttrs: false,
  props: {
    displayName: propStringRequired,
    status: propStringRequired,
  },
};
</script>

<style scoped>
.agent-item {
  padding: 1px;
}
</style>
