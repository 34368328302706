<template>
  <div
    v-if="(!availableFilters[filter].getter
      || availableFilters[filter].getter.length > 1) && ready"
  >
    <label
      :key="`${filter}-label`"
      class="sr-only"
      :for="`filter-${filter}`"
    >{{ availableFilters[filter].label }}</label>
    <b-input-group
      :key="`${filter}-group`"
      class="flex-grow-1"
    >
      <b-skeleton
        v-if="!ready"
        type="input"
        class="flex-grow-1"
      />
      <template v-else>
        <multi-select
          v-if="availableFilters[filter].component === 'multi-select'"
          :id="`filter-${filter}`"
          v-model="valueToInput"
          :typename="availableFilters[filter].typename"
          :options="toOptions(
            availableFilters[filter].getter,
            availableFilters[filter].dataStructure,
          )"
          :searchable="availableFilters[filter].getter.length >= 10"
        />
        <date-picker
          v-else-if="availableFilters[filter].component === 'date-picker'"
          :id="`filter-${filter}`"
          :key="`${filter}-component`"
          v-model="valueToInput"
          range
          :type="['minute', 'hour']
            .includes(datePickerResolution) ? 'datetime' : datePickerResolution"
          :lang="lang"
          :clearable="false"
          value-type="timestamp"
          :placeholder="$t('message.timepickerPlace')"
          confirm
          input-class="form-control"
          class="date-picker"
          :popup-style="{ width: '500px' }"
        >
          <template #header>
            <button
              v-for="shortcut in shortcuts"
              :key="shortcut.text"
              class="mx-btn mx-btn-text"
              type="button"
              @click="shortcut.onClick()"
            >
              {{ shortcut.text }}
            </button>
          </template>
        </date-picker>
        <component
          :is="availableFilters[filter].component"
          v-else-if="!availableFilters[filter].getter"
          :id="`filter-${filter}`"
          v-model="valueToInput"
        />
        <component
          :is="availableFilters[filter].component"
          v-else-if="availableFilters[filter].getter.length > 1"
          :id="`filter-${filter}`"
          v-model="valueToInput"
          :options="toOptions(
            availableFilters[filter].getter,
            availableFilters[filter].dataStructure,
          )"
        >
          <template
            v-if="availableFilters[filter].component === 'b-form-select'"
            #first
          >
            <b-form-select-option
              :value="''"
              disabled
            >
              {{ `${$t('vocabulary.select')} ${availableFilters[filter].label}` }}
            </b-form-select-option>
          </template>
        </component>
      </template>
    </b-input-group>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import moment from 'moment';
import { timeperiodShortcuts } from '@/utils/constants';

import { mapActions, mapGetters } from 'vuex';
import MultiSelect from '@/components/MultiSelect.vue';

const sessionStorageKey = 'supchat-filters';

export default {
  name: 'InputComponent',
  components: {
    DatePicker,
    MultiSelect,
  },
  props: {
    filter: {
      required: true,
      type: String,
    },
    noAutoSelect: {
      default: false,
      type: Boolean,
    },
    noSessionStorage: {
      default: false,
      type: Boolean,
    },
    customFilter: {
      type: Object,
      default: () => ({}),
    },
    datePickerType: {
      type: String,
      default: '',
    },
    value: {
      type: [Array, String],
      required: true,
    },
  },
  data() {
    return {
      ready: false,
      sessionStorageState: {},
    };
  },
  computed: {
    ...mapGetters('agent', {
      agentDepartments: 'departments',
      managerDepartments: 'departmentsAsManager',
      agentTenants: 'tenantsAsAgent',
      managerTenants: 'tenantsAsManager',
      managerAgents: 'getManagedAgents',
    }),
    ...mapGetters('tenants', ['systemTenantList']),
    ...mapGetters('departments', ['systemDepartments']),
    ...mapGetters('systemAgents', ['systemAgents']),
    availableFilters() {
      const availableFilters = {
        // all tenants where you are at least an agent
        agentTenants: {
          component: 'multi-select',
          label: this.$t('vocabulary.tenantMultiple'),
          typename: 'vocabulary.tenant',
          getter: this.agentTenants,
          fetcher: this.ensureAgentTenantsFetched,
          dataStructure: {
            value: 'id',
            text: 'name',
          },
          default: [],
        },
        // same as above but single selection
        agentTenantsSingle: {
          component: 'b-form-select',
          label: this.$t('vocabulary.tenantSingle'),
          getter: this.agentTenants,
          fetcher: this.ensureAgentTenantsFetched,
          dataStructure: {
            value: 'id',
            text: 'name',
          },
          default: '',
        },
        // all tenants where you are at least a manager
        managerTenants: {
          component: 'multi-select',
          label: this.$t('vocabulary.tenantMultiple'),
          typename: 'vocabulary.tenant',
          getter: this.managerTenants,
          fetcher: this.ensureAgentTenantsFetched,
          dataStructure: {
            value: 'id',
            text: 'name',
          },
          default: [],
        },
        // same as above but single selection
        managerTenantsSingle: {
          component: 'b-form-select',
          label: this.$t('vocabulary.tenantSingle'),
          getter: this.managerTenants,
          fetcher: this.ensureAgentTenantsFetched,
          dataStructure: {
            value: 'id',
            text: 'name',
          },
          default: '',
        },
        // all tenants in system
        systemTenants: {
          component: 'multi-select',
          label: this.$t('vocabulary.tenantMultiple'),
          typename: 'vocabulary.tenant',
          getter: this.systemTenantList,
          fetcher: this.ensureSystemTenantsFetched,
          dataStructure: {
            value: 'id',
            text: 'name',
          },
          default: [],
        },
        // same as above but single selection
        systemTenantsSingle: {
          component: 'b-form-select',
          label: this.$t('vocabulary.tenantSingle'),
          typename: 'vocabulary.tenant',
          getter: this.systemTenantList,
          fetcher: this.ensureSystemTenantsFetched,
          dataStructure: {
            value: 'id',
            text: 'name',
          },
          default: '',
        },
        // all departments with access
        agentDepartments: {
          component: 'multi-select',
          label: this.$t('vocabulary.departmentMultiple'),
          typename: 'vocabulary.department',
          getter: this.agentDepartments,
          fetcher: this.ensureAgentDepartmentsFetched,
          dataStructure: {
            value: 'id',
            text: 'fullName',
          },
          default: [],
        },
        // all departments with manager access
        managerDepartments: {
          component: 'multi-select',
          label: this.$t('vocabulary.departmentMultiple'),
          typename: 'vocabulary.department',
          getter: this.managerDepartments,
          fetcher: this.ensureSystemDepartmentsFetched,
          dataStructure: {
            value: 'id',
            text: 'fullName',
          },
          default: [],
        },
        // all departments in system
        systemDepartments: {
          component: 'multi-select',
          label: this.$t('vocabulary.departmentMultiple'),
          typename: 'vocabulary.department',
          getter: this.systemDepartments,
          fetcher: this.ensureSystemDepartmentsFetched,
          dataStructure: {
            value: 'id',
            text: 'fullName',
          },
          default: [],
        },
        // all agents in system
        systemAgents: {
          component: 'multi-select',
          label: this.$t('vocabulary.agentMultiple'),
          typename: 'vocabulary.agent',
          getter: this.systemAgents,
          fetcher: this.ensureAgentsFetched,
          dataStructure: {
            value: 'id',
            text: 'display_name',
          },
          default: [],
        },
        // all managed agents
        managerAgents: {
          component: 'multi-select',
          label: this.$t('vocabulary.agentMultiple'),
          typename: 'vocabulary.agent',
          getter: this.managerAgents,
          fetcher: this.ensureManagedAgentsFetched,
          dataStructure: {
            value: 'id',
            text: 'name',
          },
          default: [],
        },
        // time range
        timeRange: {
          component: 'date-picker',
          label: this.$t('vocabulary.timeRange'),
          default: [moment().startOf('day').valueOf(), moment().add(1, 'days').endOf('day').valueOf()],
        },
        // resolutions
        resolution: {
          component: 'b-form-select',
          label: this.$t('vocabulary.resolution'),
          getter: [
            { value: 'hour', text: this.$t('vocabulary.hourly') },
            { value: 'day', text: this.$t('vocabulary.daily') },
            { value: 'week', text: this.$t('vocabulary.weekly') },
            { value: 'month', text: this.$t('vocabulary.monthly') },
          ],
          dataStructure: {
            value: 'value',
            text: 'text',
          },
          default: 'day',
        },
        // temporary, for analytics page
        resolutionNoWeekly: {
          component: 'b-form-select',
          label: this.$t('vocabulary.resolution'),
          getter: [
            { value: 'minute', text: this.$t('vocabulary.everyMinute') },
            { value: 'hour', text: this.$t('vocabulary.hourly') },
            { value: 'day', text: this.$t('vocabulary.daily') },
            { value: 'month', text: this.$t('vocabulary.monthly') },
          ],
          dataStructure: {
            value: 'value',
            text: 'text',
          },
          default: 'day',
        },
      };
      if (Object.keys(this.customFilter).length) {
        return Object.assign(availableFilters, this.customFilter);
      }
      return availableFilters;
    },
    datePickerResolution() {
      if (this.filter === ('resolutionNoWeekly')) return this.value;
      if (this.filter.includes('resolution')) return this.value;
      if (this.datePickerType) return this.datePickerType;
      return this.availableFilters.resolution.default;
    },
    lang() {
      if (!this.filter.includes('timeRange')) return {};
      // Copied from Archive Form
      return { months: this.$t('months'), days: this.$t('daysShort'), formatLocale: { firstDayOfWeek: 1, firstWeekContainsDate: 4 } };
    },
    shortcuts() {
      if (!this.filter.includes('timeRange')) return [];
      const prepare = (shortcut) => ({
        text: this.$t(...shortcut.text),
        onClick: () => { this.valueToInput = shortcut.value; },
      });

      /*
        Shortcut override for archive page because clicking twice in the date picker is too hard
      */
      if (this.$route.name === 'archive') {
        return [
          prepare(timeperiodShortcuts(moment).lastXMonths(12)),
          prepare(timeperiodShortcuts(moment).lastXMonths(6)),
          prepare(timeperiodShortcuts(moment).lastXMonths(3)),
          prepare(timeperiodShortcuts(moment).lastXMonths(2)),
          prepare(timeperiodShortcuts(moment).lastMonth),
          prepare(timeperiodShortcuts(moment).currentMonth),
          prepare(timeperiodShortcuts(moment).lastXWeeks(3)),
          prepare(timeperiodShortcuts(moment).lastXWeeks(2)),
          prepare(timeperiodShortcuts(moment).lastWeek),
          prepare(timeperiodShortcuts(moment).currentWeek),
          prepare(timeperiodShortcuts(moment).lastXDays(7)),
          prepare(timeperiodShortcuts(moment).lastXDays(2)),
          prepare(timeperiodShortcuts(moment).yesterday),
          prepare(timeperiodShortcuts(moment).today),
          prepare(timeperiodShortcuts(moment).lastHour),
          prepare(timeperiodShortcuts(moment).currentHour),
        ];
      }

      switch (this.datePickerResolution) {
        case 'minute':
          return [
            prepare(timeperiodShortcuts(moment).lastXHours(2)),
            prepare(timeperiodShortcuts(moment).lastHour),
            prepare(timeperiodShortcuts(moment).currentHour),
            prepare(timeperiodShortcuts(moment).lastXMinutes(30)),
            prepare(timeperiodShortcuts(moment).lastXMinutes(15)),
            prepare(timeperiodShortcuts(moment).lastXMinutes(10)),
            prepare(timeperiodShortcuts(moment).lastXMinutes(5)),
            prepare(timeperiodShortcuts(moment).lastXMinutes(2)),
            prepare(timeperiodShortcuts(moment).lastMinute),
          ];
        case 'hour':
          return [
            prepare(timeperiodShortcuts(moment).lastXHours(72)),
            prepare(timeperiodShortcuts(moment).lastXHours(24)),
            prepare(timeperiodShortcuts(moment).lastXHours(12)),
            prepare(timeperiodShortcuts(moment).lastXHours(2)),
            prepare(timeperiodShortcuts(moment).lastHour),
            prepare(timeperiodShortcuts(moment).currentHour),
            prepare(timeperiodShortcuts(moment).yesterday),
            prepare(timeperiodShortcuts(moment).today),
          ];
        case 'day':
          return [
            prepare(timeperiodShortcuts(moment).lastMonth),
            prepare(timeperiodShortcuts(moment).currentMonth),
            prepare(timeperiodShortcuts(moment).lastXWeeks(3)),
            prepare(timeperiodShortcuts(moment).lastXWeeks(2)),
            prepare(timeperiodShortcuts(moment).lastWeek),
            prepare(timeperiodShortcuts(moment).currentWeek),
            prepare(timeperiodShortcuts(moment).lastXDays(7)),
            prepare(timeperiodShortcuts(moment).lastXDays(2)),
            prepare(timeperiodShortcuts(moment).yesterday),
            prepare(timeperiodShortcuts(moment).today),
          ];
        case 'week':
          return [
            prepare(timeperiodShortcuts(moment).lastXWeeks(10)),
            prepare(timeperiodShortcuts(moment).lastXWeeks(5)),
            prepare(timeperiodShortcuts(moment).lastXWeeks(3)),
            prepare(timeperiodShortcuts(moment).lastXWeeks(2)),
            prepare(timeperiodShortcuts(moment).lastWeek),
            prepare(timeperiodShortcuts(moment).currentWeek),
          ];
        case 'month':
        default:
          return [
            prepare(timeperiodShortcuts(moment).lastXMonths(24)),
            prepare(timeperiodShortcuts(moment).lastXMonths(12)),
            prepare(timeperiodShortcuts(moment).lastXMonths(6)),
            prepare(timeperiodShortcuts(moment).lastXMonths(3)),
            prepare(timeperiodShortcuts(moment).lastXMonths(2)),
            prepare(timeperiodShortcuts(moment).lastMonth),
            prepare(timeperiodShortcuts(moment).currentMonth),
          ];
      }
    },
    valueToInput: {
      get() { return this.value; },
      set(value) { this.$emit('input', value); },
    },
  },
  watch: {
    value() {
      if (!this.noSessionStorage) this.updateSessionStorage();
    },
  },
  async created() {
    try {
      // Fetch all the required data
      const { fetcher } = this.availableFilters[this.filter];
      if (fetcher) await fetcher();

      await new Promise((resolve) => {
        setTimeout(() => {
          // Fetch from session storage and apply saved selections
          if (!this.noSessionStorage) this.fetchAndApplySessionStorage();

          // Prefill selections if they're empty
          if (!this.noAutoSelect) this.fixEmptySelections();
          resolve();
        }, 250);
      });
    } catch (error) {
      this.$log.error('Filter component failed to fetch');
    }
    this.$emit('ready');
    this.ready = true;
  },
  methods: {
    ...mapActions('agent', {
      ensureAgentTenantsFetched: 'ensureTenantsFetched',
      ensureAgentDepartmentsFetched: 'ensureDepartmentsFetched',
      ensureManagedAgentsFetched: 'ensureManagedAgentsFetched',
    }),
    ...mapActions('tenants', ['ensureSystemTenantsFetched']),
    ...mapActions('systemAgents', ['ensureAgentsFetched']),
    ...mapActions('departments', {
      ensureSystemDepartmentsFetched: 'ensureDepartmentsFetched',
    }),
    toOptions(data, { value, text }) {
      if (value === 'value' && text === 'text') return data;
      return data.map((entry) => ({
        value: entry[value],
        text: entry[text],
      }));
    },
    validateSavedValue(value, filterKey) {
      /*
        To validate the value, we grab the getter i.e. the data
        and then we loop over each value to check if it exists in the getter data
        If any value doesn't exist, we return false

        If the value is not an array, we temporarily turn it into one so we can loop over it
      */
      let dataToValidate = value;
      if (typeof dataToValidate === 'string') dataToValidate = [dataToValidate];
      const { getter: data, dataStructure } = this.availableFilters[filterKey];
      const validValues = data.map((val) => val[dataStructure.value]);
      return dataToValidate.every((val) => validValues.includes(val));
    },
    fetchAndApplySessionStorage() {
      // First check if there's any data in session storage
      const currentStorage = sessionStorage.getItem(sessionStorageKey);
      if (currentStorage) this.sessionStorageState = JSON.parse(currentStorage);

      // If our current route name isn't found as a key, we create it
      if (!this.sessionStorageState[this.$route.name]) {
        this.sessionStorageState[this.$route.name] = {};
      }
      if (!this.sessionStorageState[this.$route.name][this.filter]) {
        const defaultValue = this.availableFilters[this.filter].default;
        this.sessionStorageState[this.$route.name][this.filter] = defaultValue;
      }

      // Then we perform some validation of the saved values
      const savedSelection = this.sessionStorageState[this.$route.name][this.filter];
      if (!this.availableFilters[this.filter]) {
        delete this.sessionStorageState[this.$route.name][this.filter];
        return;
      }
      const { getter, dataStructure } = this.availableFilters[this.filter];
      // If there's no getter, we don't need to validate
      if (!getter) {
        this.valueToInput = savedSelection;
        return;
      }

      // we don't display "filters" where there's only 1 option to simplify the form
      if (getter.length === 1) {
        if (this.availableFilters[this.filter].default === '') {
          this.valueToInput = getter[0][dataStructure.value];
        } else {
          this.valueToInput = [getter[0][dataStructure.value]];
        }
        return;
      }
      // Else, run validation and apply the value
      if (this.validateSavedValue(savedSelection, this.filter)) {
        this.valueToInput = savedSelection;
      }
    },
    updateSessionStorage() {
      this.$set(this.sessionStorageState[this.$route.name], this.filter, this.value);
      sessionStorage.setItem(sessionStorageKey, JSON.stringify(this.sessionStorageState));
    },
    fixEmptySelections() {
      const { getter, dataStructure, default: defaultValue } = this.availableFilters[this.filter];
      if (getter && !this.value.length) {
        if (typeof defaultValue === 'string') {
          if (['resolution', 'resolutionNoWeekly'].includes(this.filter)) {
            this.valueToInput = defaultValue;
          } else {
            const value = getter[0][dataStructure.value];
            this.valueToInput = value;
          }
        } else {
          const allValues = getter.map((option) => option[dataStructure.value]);
          this.valueToInput = allValues;
        }
      }
    },
  },
};
</script>

<style scoped>
.date-picker {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
</style>
