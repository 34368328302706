import { getActorInfo } from 'supwiz/supchat/apiList';
import { chatEvent } from 'supwiz/supchat/constants';
import {
  prepareHistory,
  prepareTranslations,
  prepareTags,
} from 'supwiz/supchat/generalUtils';
import unAckMsgMixin from './unAckMsgMixin';

export default {
  mixins: [unAckMsgMixin],
  computed: {
    history() {
      const data = [];
      // inefficient, should be optimized later
      const status = new Set();
      const senders = {
        visitor: [],
        agent: [],
      };
      let lastQueueInfoIndex = -1;
      for (let i = 0; i < this.rawHistory.length; i++) {
        const msg = { ...this.rawHistory[i] };

        if (msg.sender_role !== 'system' && !senders[msg.sender_role].includes(msg.sender_id)) {
          if (!Object.prototype.hasOwnProperty.call(
            this.sendersInfo[msg.sender_role], msg.sender_id,
          )) {
            this.$set(this.sendersInfo[msg.sender_role], msg.sender_id, {
              displayName: msg.sender_role,
            }); // hack for avoiding the repeating call of fetching sender name

            this.setSenderName(msg.sender_id, msg.sender_role);
          }
          senders[msg.sender_role].push(msg.sender_id);
        }

        if (msg.command === chatEvent.STATUS) {
          const text = msg.text;
          const userID = msg.sender_id;
          if (text === 'start') {
            if (status.has(userID)) {
              continue;
            }
            status.add(userID);
          } else if (text === 'stop' || text === 'close') {
            status.delete(userID);
          }
        }
        if (msg.command === chatEvent.WAITING_INFO) {
          lastQueueInfoIndex = data.length;
        }
        data.push(msg);
      }

      for (let i = 0; i <= lastQueueInfoIndex && i < data.length; i++) {
        const msg = data[i];
        if (msg.command === chatEvent.WAITING_INFO && typeof (msg.text) === 'number') {
          msg.latestQueue = i === lastQueueInfoIndex;
          if (msg.latestQueue) {
            this.latestQueueMsg = msg;
          }
        }
      }

      for (const msg of this.unAckMessages) {
        const aux = { ...msg };
        aux.sender_role = this.userRole;
        aux.sender_id = this.userRole === 'visitor' ? this.visitorId : this.agentId;
        data.push(aux);
      }
      return data;
    },
    isCurrentlyPickedUp() {
      let currentlyPickedUp = false;
      for (const { sender_role: senderRole, command, text } of this.history) {
        if (senderRole !== 'agent') {
          continue;
        }
        if (command === chatEvent.STATUS && text === 'start') {
          currentlyPickedUp = true;
        } else if (command === chatEvent.SAY) {
          currentlyPickedUp = true;
        } else if (command === chatEvent.STATUS && text === 'stop') {
          currentlyPickedUp = false;
        } else if (command === chatEvent.STATUS && text === 'transfer') {
          currentlyPickedUp = false;
        }
      }
      return currentlyPickedUp;
    },
    preparedHistory() {
      const history = this.history;
      return prepareHistory(history);
    },
    translations() {
      const history = this.history;
      return prepareTranslations(history);
    },
    tags() {
      const history = this.history;
      return prepareTags(history);
    },
    historySay() {
      return this.history.filter((msg) => msg.command === chatEvent.SAY && ['visitor', 'agent'].includes(msg.sender_role));
    },
    hasBeenPickedUp() {
      // Trick to avoid showing the waiting information at wrong time given the race condition
      for (const msg of this.history) {
        if (msg.sender_role === 'agent'
          && ((msg.command === chatEvent.STATUS && msg.text === 'start') || msg.command === chatEvent.SAY)) {
          return true;
        }
      }
      return false;
    },
  },
  watch: {
    isCurrentlyPickedUp() {
      /* Waitingqueuemixin isn't available for agents
       * so they won't have the waitingMessage function.
       * They also don't need it because being in a queue
       * is exclusive to visitors.
      */
      if (!this.isCurrentlyPickedUp && typeof this.waitingMessage === 'function') {
        this.waitingMessage();
      }
    },
  },
  methods: {
    async setSenderName(senderId, role) {
      try {
        let baseURL = null;
        if (this.config) {
          baseURL = this.config.supchat_url || null;
        }
        const {
          display_name: displayName,
          is_bot: isBot,
        } = await getActorInfo(senderId, baseURL);
        this.$set(this.sendersInfo[role], senderId, {
          displayName,
          isBot,
        });
      } catch (error) {
        console.error(error);
      }
    },
  },
};
