var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"d-flex flex-column align-items-start"},[_c('div',{staticClass:"d-flex chat-bubble-wrap align-items-center"},[(_vm.senderAvatar)?_c('user-avatar',_vm._b({},'user-avatar',{
        senderAvatar: _vm.senderAvatar,
        borderRadius: _vm.borderRadius,
        msgName: _vm.msgName,
      },false)):_vm._e(),_c('div',{staticClass:"chat-bubble typing-bubble",class:{
        'is-typing': _vm.typingSender.name,
        br: _vm.borderRadius,
        'btlr bblr': _vm.borderRadius && _vm.senderAvatar,
      }},[_c('span'),_c('span'),_c('span'),_c('div',{staticClass:"sr-only"},[_vm._v(" "+_vm._s(_vm.$t('status.userWriting', { name: _vm.typingSender.name }))+" ")])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }