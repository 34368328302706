<template>
  <div>
    <div class="position-relative">
      <CopilotScoreDisplay
        v-if="predictionOfValue"
        class="px-1 py-0 score r-25 mr-2 behind position-absolute right-0"
        color
        small
        :decimal="predictionOfValue"
      />
      <component
        :is="predictionOfValue ? 'b-form-textarea' : 'InputFieldCopyable'"
        v-bind="$attrs"
        v-model="valueToInput"
        class="flex-fill infront"
        input-class="bg-transparent pr-4"
        v-on="$listeners"
        @focus="hasFocus = true"
        @blur="hasFocus = false"
      />
    </div>
    <FadeDown class="position-absolute right-0 w-100 infront">
      <b-list-group v-if="displayMenu && predictionsSorted.length">
        <b-list-group-item
          v-for="option in predictionsSorted"
          :key="option.value"
          :class="['p-2 d-flex position-relative text-break', { 'pr-5': option.score }]"
          button
          @click="setValue(option.value)"
          @focus="hasFocus = true"
          @blur="hasFocus = false"
        >
          <span :class="['flex-grow-1 py-1', { 'font-weight-bold': option.value === valueToInput }]">
            {{ option.value }}
          </span>
          <CopilotScoreDisplay
            v-if="option.score"
            class="flex-shrink-0 position-absolute score r-25 mr-2 right-0"
            color
            small
            :decimal="option.score"
          />
        </b-list-group-item>
      </b-list-group>
    </FadeDown>
  </div>
</template>

<script>
import { sortBy, debounce } from 'lodash';
import CopilotScoreDisplay from '@/components/CopilotScoreDisplay.vue';
import FadeDown from '@/components/Transitions/FadeDown.vue';
import InputFieldCopyable from '@/components/InputFieldCopyable.vue';

export default {
  name: 'MetadataInput',
  components: { CopilotScoreDisplay, FadeDown, InputFieldCopyable },
  inject: ['focusInputField'],
  inheritAttrs: false,
  props: {
    value: {
      validator: (val) => ['string', 'number', 'object', 'undefined'].includes(typeof val),
      required: true,
    },
    predictions: {
      validator: (val) => ['object', 'undefined'].includes(typeof val),
      /* default: () => ([{
        value: 'sales',
        score: 0.8061671257019043,
        target: 'Topic',
      }]), */
      required: true,
    },
  },
  data() {
    return {
      displayMenu: false,
      hasFocus: false,
      menuVisibleDebounce: null,
    };
  },
  computed: {
    valueToInput: {
      get() { return this.value; },
      set(value) { this.$emit('input', value); },
    },
    hasPredictions() {
      if (!this.predictions || !Array.isArray(this.predictions)) return false;

      return !!this.predictions?.length;
    },
    predictionOfValue() {
      if (!this.hasPredictions) return null;
      const prediction = this.predictionsAsOptions.find(({ value }) => value === this.valueToInput);
      return prediction?.score || null;
    },
    searchRegex() {
      return new RegExp(this.valueToInput, 'i');
    },
    predictionsAsOptions() {
      if (!this.hasPredictions) return [];
      const predictionOptions = this.predictions.map(({ value, score }) => ({ value, score }));
      return predictionOptions;
    },
    predictionOptionsFiltered() {
      if (!this.valueToInput) return this.predictionsAsOptions;
      return this.predictionsAsOptions.filter(({ value }) => this.searchRegex.test(value));
    },
    predictionsSorted() {
      if (!this.predictionOptionsFiltered.length) return [];
      const predictionOptions = this.predictionOptionsFiltered;
      return sortBy(predictionOptions, [
        ({ score }) => score * -1,
        ({ value }) => value.toLowerCase(),
      ]);
    },
  },
  watch: {
    hasFocus() {
      this.menuVisibleDebounce();
    },
  },
  created() {
    this.menuVisibleDebounce = debounce(() => {
      this.displayMenu = this.hasFocus;
    }, 100);
  },
  destroyed() {
    this.menuVisibleDebounce = null;
  },
  methods: {
    setValue(value) {
      this.valueToInput = value;
      this.focusInputField();
    },
  },
};
</script>

<style scoped>
.behind {
  z-index: 1;
}
.infront {
  z-index: 2;
}
:deep(.list-group) {
  z-index: 100;
}
.score {
  font-size: 0.75rem!important;
  pointer-events: none;
  top:50%;
  transform: translateY(-50%);
}
</style>
