<template>
  <b-form-select
    v-if="!predictionsEnabled"
    v-bind="$attrs"
    v-model="valueToInput"
    :options="optionsSorted"
  />
  <div v-else>
    <div class="position-relative">
      <CopilotScoreDisplay
        v-if="selectedScore !== null"
        class="px-1 py-0 score r-25 mr-2 behind position-absolute right-0"
        color
        small
        :decimal="selectedScore"
      />
      <b-form-input
        class="flex-fill bg-transparent custom-select infront"
        readonly
        v-bind="$attrs"
        :value="selectedOption?.text || ''"
        v-on="$listeners"
        @focus="hasFocus = true"
        @blur="hasFocus = false"
      />
    </div>
    <FadeDown class="position-absolute right-0 w-100 infront">
      <b-list-group v-if="displayMenu && optionsSorted.length">
        <b-list-group-item
          v-for="option in optionsSorted"
          :key="option.value"
          :class="['p-2 d-flex position-relative text-break', { 'pr-5': option.score }]"
          button
          @click="setValue(option.value)"
          @focus="hasFocus = true"
          @blur="hasFocus = false"
        >
          <span :class="['flex-grow-1 py-1', { 'font-weight-bold': option.value === valueToInput }]">
            {{ option.text }}
          </span>
          <CopilotScoreDisplay
            v-if="option.score"
            class="flex-shrink-0 position-absolute score r-25 mr-2 right-0"
            color
            small
            :decimal="option.score"
          />
        </b-list-group-item>
      </b-list-group>
    </FadeDown>
  </div>
</template>

<script>
import { sortBy, debounce, cloneDeep } from 'lodash';
import FadeDown from '@/components/Transitions/FadeDown.vue';
import CopilotScoreDisplay from '@/components/CopilotScoreDisplay.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'MetadataSelect',
  components: { CopilotScoreDisplay, FadeDown },
  inject: ['focusInputField'],
  inheritAttrs: false,
  props: {
    value: {
      validator: (val) => ['string', 'number', 'object', 'undefined'].includes(typeof val),
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    predictions: {
      validator: (val) => ['object', 'undefined'].includes(typeof val),
      /* default: () => ([{
        value: 'sales',
        score: 0.8061671257019043,
        target: 'Topic',
      }]), */
      required: true,
    },
    fieldName: {
      type: String,
      required: true,
    },
    tenantId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      displayMenu: false,
      hasFocus: false,
      menuVisibleDebounce: null,
    };
  },
  computed: {
    ...mapGetters('tenants/predictions', ['getMetadataFieldsWithRankers']),
    valueToInput: {
      get() { return this.value; },
      set(value) { this.$emit('input', value); },
    },
    optionsSorted() {
      let options = cloneDeep(this.options);
      if (this.predictions) {
        options = options.map((o) => {
          const result = o;
          const predictionScore = this.getPrediction(o.value);
          if (predictionScore) result.score = predictionScore;
          return result;
        });
      }
      const optionsSorted = options;
      return sortBy(optionsSorted, [
        ({ score }) => score * -1,
        ({ value }) => value.toLowerCase(),
      ]);
    },
    selectedOption() {
      const value = this.valueToInput;
      const match = this.optionsSorted.find((o) => o.value === value);
      return match;
    },
    selectedScore() {
      if (!this.selectedOption) return null;
      return this.selectedOption?.score ?? null;
    },
    predictionsEnabled() {
      const tenantId = this.tenantId;
      return this.getMetadataFieldsWithRankers({ tenantId }).includes(this.fieldName);
    },
  },
  watch: {
    hasFocus() {
      this.menuVisibleDebounce();
    },
  },
  created() {
    this.menuVisibleDebounce = debounce(() => {
      this.displayMenu = this.hasFocus;
    }, 100);
  },
  destroyed() {
    this.menuVisibleDebounce = null;
  },
  methods: {
    getPrediction(value) {
      if (!this.predictions || !Array.isArray(this.predictions)) return null;
      const predictionScoreObj = this.predictions.find((p) => p.value === value);
      return predictionScoreObj?.score || null;
    },
    setValue(value) {
      this.valueToInput = value;
      this.$nextTick(() => {
        this.hasFocus = false;
        this.focusInputField();
      });
    },
  },
};
</script>

<style scoped>
.behind {
  z-index: 1;
}
.infront {
  z-index: 2;
}
:deep(.list-group) {
  z-index: 100;
}
.score {
  font-size: 0.75rem!important;
  pointer-events: none;
  top:50%;
  transform: translateY(-50%);
}
</style>
