import Vue from 'vue';
import { isEqual } from 'lodash';

import {
  setAgentStatus,
  getStatusOverview,
  getDepartmentStatusOverview,
  getInactivtyTimeouts,
} from '@/api/apiList';

const statusState = {
  agents: {},
  departments: {},
  agentsInDepartments: {},
};

const getters = {
  myId: (state) => state.agent_id,
  myStatus: (state) => state.agents[state.agent_id],
  getAgentStatus: (state) => (id) => state.agents[id],
  getDepartmentStatus: (state) => (id = '') => (id ? state.departments[id] : state.departments),
  getMinInactivityTimeout: (state) => state.inactivityTimeout,
};

const mutations = {
  SET_AGENT_STATUS(state, { agentId, status }) {
    Vue.set(state.agents, agentId, status);
  },
  SET_DEPARTMENT_ONLINE_AGENTS(state, { departments }) {
    Vue.set(state, 'agentsInDepartments', departments);
  },
  SET_OVERVIEW_STATUS(state, { departments, agents, agentId }) {
    if (departments) {
      Vue.set(state, 'departments', departments);
    }
    if (agents) {
      Vue.set(state, 'agents', agents);
    }
    if (!state.agent_id) {
      Vue.set(state, 'agent_id', agentId);
    }
  },
  SET_INACTIVITY_TIMEOUT(state, inactivityTimeout) {
    state.inactivityTimeout = Math.min(...inactivityTimeout) * 1000;
  },
};

const actions = {
  async getStatusOverview({ commit, state }) {
    const { agents, departments, agent_id: agentId } = await getStatusOverview();
    if (!Object.keys(state.agents).length) commit('SET_OVERVIEW_STATUS', { agents, agentId });
    else {
      Object.entries(agents).forEach(([statusAgentId, status]) => {
        if (state.agents[statusAgentId] !== status) {
          state.agents[statusAgentId] = status;
        }
      });
    }
    if (!isEqual(departments, state.agentsInDepartments)) {
      commit('SET_DEPARTMENT_ONLINE_AGENTS', { departments });
    }
  },
  async fetchInactivityTimeouts({ commit, state }) {
    const inactivityTimeouts = await getInactivtyTimeouts();
    commit('SET_INACTIVITY_TIMEOUT', inactivityTimeouts);
    return state.inactivityTimeouts;
  },
  async getDepartmentStatusOverview({ commit, state }) {
    const departments = await getDepartmentStatusOverview();
    commit('SET_OVERVIEW_STATUS', { departments });
    return state.departments;
  },
  async setAgentStatus({ commit, state }, { agentId, status }) {
    let id = agentId;
    if (agentId === 'me') id = state.agent_id;
    const { status: respStatus } = await setAgentStatus({ agentId, status });
    if (['ON', 'AW', 'IN'].includes(respStatus)) {
      commit('SET_AGENT_STATUS', { agentId: id, status: respStatus });
    } else {
      Vue.$log.error(`Could not set status. Server returned ${respStatus}`);
      return respStatus;
    }
    return state.agents[id];
  },
};

export default {
  namespaced: true,
  state: statusState,
  getters,
  mutations,
  actions,
};
