import 'core-js/stable';
// import * as Sentry from '@sentry/vue';
import Vue from 'vue';
import VueLogger from 'vuejs-logger';
import BootstrapVue from 'bootstrap-vue';
import PortalVue from 'portal-vue';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'vue2-datepicker/index.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faArchive, faAddressCard,
  faBars,
  faChartColumn,
  faClock,
  faCog,
  faComment, faFileAlt,
  faHome,
  faPhoneVolume, faSignInAlt,
  faSignOutAlt,
  faTimes,
  faPlus,
  faTimesCircle,
  faBuilding,
  faUsersCog,
  faMicroscope,
  faCopy,
  faLock,
  faExternalLinkAlt,
  faTrashAlt,
  faEdit,
  faGripLines,
  faCheck,
  faDivide,
  faMinus,
  faExclamation,
  faExclamationCircle,
  faArrowsAltV,
  faArrowUp,
  faArrowDown,
  faSearch,
  faEllipsisH,
  faCaretUp,
  faCaretDown,
  faFileExcel,
  faChartLine,
  faClipboardList,
  faUsers,
  faBell,
  faUserLock,
  faEquals,
  faFileShield,
  faEye,
  faEyeSlash,
  faInfo,
  faUser,
  faAt,
  faTicket,
  faPhone,
  faMobileScreen,
  faGlobe,
  faLink,
  faAlignLeft,
  faComments,
  faUserTie,
  faFloppyDisk,
  faUserMinus,
  faUserXmark,
  faHeart,
  faMoon,
  faChartBar,
  faPlay,
  faStop,
  faWandMagicSparkles,
  faSpinner,
  faNoteSticky,
  faLanguage,
  faStar,
  faThumbTack,
  faThumbsUp,
  faBolt,
  faBook,
  faArrowRotateLeft,
  faPaperclip,
  faShare,
  faWindowMinimize,
} from '@fortawesome/free-solid-svg-icons';
import {
  faSmile,
  faFrown,
} from '@fortawesome/free-regular-svg-icons';
import SmartBButton from '@/components/SmartBButton.vue';
import FilterComponent from '@/components/FilterComponent.vue';

// replacement for FilterComponent. Supports only single value which allow for v-model support
import InputComponent from '@/components/InputComponent.vue';
import FloatingContainer from '@/components/FloatingContainer.vue';

import { i18n } from '@/localization';

import App from './App.vue';
// eslint-disable-next-line import/no-cycle
import router from './router';
import store from './store';
import 'supwiz/styling/supwiz_bootstrap.scss';

library.add(
  faArchive,
  faAddressCard,
  faBars,
  faChartColumn,
  faClock,
  faCog,
  faComment,
  faFileAlt,
  faHome,
  faPlus,
  faPhoneVolume,
  faSignInAlt,
  faSignOutAlt,
  faTimes,
  faTimesCircle,
  faBuilding,
  faUsersCog,
  faMicroscope,
  faCopy,
  faLock,
  faExternalLinkAlt,
  faTrashAlt,
  faEdit,
  faGripLines,
  faCheck,
  faDivide,
  faMinus,
  faExclamation,
  faExclamationCircle,
  faArrowsAltV,
  faArrowUp,
  faArrowDown,
  faSearch,
  faEllipsisH,
  faCaretUp,
  faCaretDown,
  faFileExcel,
  faChartLine,
  faClipboardList,
  faSmile,
  faFrown,
  faUsers,
  faBell,
  faUserLock,
  faEquals,
  faFileShield,
  faEye,
  faEyeSlash,
  faInfo,
  faUser,
  faAt,
  faTicket,
  faPhone,
  faMobileScreen,
  faGlobe,
  faLink,
  faAlignLeft,
  faComments,
  faUserTie,
  faFloppyDisk,
  faUserMinus,
  faUserXmark,
  faHeart,
  faMoon,
  faChartBar,
  faPlay,
  faStop,
  faWandMagicSparkles,
  faSpinner,
  faNoteSticky,
  faLanguage,
  faStar,
  faThumbTack,
  faThumbsUp,
  faBolt,
  faBook,
  faArrowRotateLeft,
  faPaperclip,
  faShare,
  faWindowMinimize,
);

Vue.component('FontAwesomeIcon', FontAwesomeIcon);
Vue.component('SmartBButton', SmartBButton);
Vue.component('FilterComponent', FilterComponent);
Vue.component('InputComponent', InputComponent);
Vue.component('FloatingContainer', FloatingContainer);
Vue.use(BootstrapVue);
Vue.use(PortalVue);
Vue.config.productionTip = false;
const isProduction = process.env.NODE_ENV === 'production';

const options = {
  isEnabled: true,
  logLevel: isProduction ? 'error' : 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true,
};

Vue.use(VueLogger, options);

// this won't work for some reason
// if (process?.env?.NODE_ENV !== 'development') {
/* if (!document.location.href.includes('supchat.local')) {
  Sentry.init({
    Vue,
    dsn: 'https://c3196813fa5f493ea12a4757f03769b0@o1307798.ingest.sentry.io/4503959062904832',
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}
*/

new Vue({
  render: (h) => h(App),
  router,
  store,
  i18n,
}).$mount('#app');
