<template>
  <b-alert
    :show="showAlert"
    class="connection-alert r-50 p-2"
    variant="danger"
  >
    <div class="d-flex flex-column">
      <div class="d-flex align-items-center pb-2 pl-1 pt-1">
        <div class="sync-icon-wrap">
          <font-awesome-icon icon="circle-exclamation" size="xl" />
        </div>
        <div class="d-flex flex-column ml-2">
          <p
            v-if="needsRefresh"
            class="i18n-newlines"
            v-text="$t('connection.needsRefresh')"
          />
          <p
            v-else-if="!isOnline"
            class="i18n-newlines mb-0"
            v-text="$t('connection.offline')"
          />
          <p
            v-else
            class="i18n-newlines mb-0"
            v-text="$t('connection.disconnectedServices')"
          />
        </div>
      </div>
      <b-button
        v-if="needsRefresh"
        size="sm"
        @click="refreshWindow"
      >
        {{ $t('connection.refreshBtn') }}
      </b-button>
    </div>
    <template v-if="isOnline && !needsRefresh">
      <div
        v-for="error in syncErrors"
        :key="error.title"
        class="sync-error d-flex pb-2 align-items-center pl-1"
      >
        <div class="sync-icon-wrap">
          <font-awesome-icon
            :icon="error.icon.icon"
            :spin="error.icon.icon === 'spinner'"
            size="xl"
          />
        </div>
        <div class="d-flex flex-column ml-2">
          <span class="font-weight-bold">{{ $t(error.title) }}</span>
          <span v-if="error.time">
            {{ $t('connection.connectionRetry', { time: error.time }) }}
          </span>
          <span v-else-if="error.gaveUp">
            {{ $t('connection.connectionGiveUp') }}
          </span>
          <span v-else>
            {{ $t('connection.connectionOk') }}
          </span>
        </div>
      </div>
    </template>
  </b-alert>
</template>

<script>
import { showTimeDifference } from '@/utils/generalUtils';
import { mapState, mapGetters } from 'vuex';
import { reconnectIntervals } from 'supwiz/supchat/constants';

export default {
  name: 'ConnectionDisplay',
  iconMap: {
    0: 'spinner',
    1: 'check',
    2: 'spinner',
    3: 'times',
  },
  data() {
    return {
      isOnline: true,
      incomingSyncOk: true,
      ongoingSyncOk: true,
    };
  },
  computed: {
    ...mapState('controlSocket', {
      controlSocketStatus: 'controlSocketStatus',
      controlSocketAttempts: 'reconnectAttempts',
    }),
    ...mapState('chat', {
      chatSocketStatus: 'chatSocketStatus',
      chatSocketAttempts: 'reconnectAttempts',
      incomingSyncAttempts: (state) => state.incomingChats.failedIncomingFetchAttempts,
      ongoingSyncAttempts: (state) => state.ongoingChats.failedOngoingFetchAttempts,
    }),
    ...mapGetters('agent', ['isLoggedIn']),
    controlSocketOk() {
      return this.controlSocketStatus === 1 || this.controlSocketAttempts <= 2;
    },
    chatSocketOk() {
      return this.chatSocketStatus === 1 || this.chatSocketAttempts <= 2;
    },
    showAlert() {
      return [
        this.isOnline,
        this.syncErrors.length === 0,
      ].includes(false);
    },
    syncErrors() {
      return [
        {
          ok: this.chatSocketOk,
          attempts: this.chatSocketAttempts,
          time: this.chatSocketNextReconnect,
          gaveUp: this.chatSocketGaveUp,
          title: 'connection.titles.chatSocket',
          icon: {
            icon: this.chatSocketGaveUp
              ? this.$options.iconMap[this.chatSocketStatus]
              : this.$options.iconMap[2],
          },
        },
        {
          ok: this.controlSocketOk,
          attempts: this.controlSocketAttempts,
          time: this.controlSocketNextReconnect,
          gaveUp: this.controlSocketGaveUp,
          title: 'connection.titles.controlSocket',
          icon: {
            icon: this.controlSocketGaveUp
              ? this.$options.iconMap[this.controlSocketStatus]
              : this.$options.iconMap[2],
          },
        },
        {
          ok: this.incomingSyncOk,
          attempts: this.incomingSyncAttempts,
          time: this.incomingSyncNextReconnect,
          gaveUp: this.incomingSyncGaveUp,
          title: 'connection.titles.incomingSync',
          icon: {
            icon: this.incomingSyncAttempts === 0
              ? this.$options.iconMap[1]
              : this.$options.iconMap[0],
          },
        },
        {
          ok: this.ongoingSyncOk,
          attempts: this.ongoingSyncAttempts,
          time: this.ongoingSyncNextReconnect,
          gaveUp: this.ongoingSyncGaveUp,
          title: 'connection.titles.ongoingSync',
          icon: {
            icon: this.ongoingSyncAttempts === 0
              ? this.$options.iconMap[1]
              : this.$options.iconMap[0],
          },
        },
      ].filter(({ ok, attempts }) => !ok && attempts > 1);
    },
    needsRefresh() {
      return this.syncErrors.some(({ gaveUp }) => gaveUp);
    },
    chatSocketNextReconnect() {
      if (this.chatSocketOk || !this.chatSocketAttempts || this.chatSocketGaveUp) return '';
      const time = this.getReconnectDelay(this.chatSocketAttempts);
      return showTimeDifference(time, this.$i18n.locale);
    },
    chatSocketGaveUp() {
      return this.chatSocketAttempts >= reconnectIntervals.length - 1;
    },
    controlSocketNextReconnect() {
      if (this.controlSocketOk || !this.controlSocketAttempts || this.controlSocketGaveUp) return '';
      const time = this.getReconnectDelay(this.controlSocketAttempts);
      return showTimeDifference(time, this.$i18n.locale);
    },
    controlSocketGaveUp() {
      return this.controlSocketAttempts >= reconnectIntervals.length - 1;
    },
    incomingSyncNextReconnect() {
      if (this.incomingSyncOk || !this.incomingSyncAttempts || this.incomingSyncGaveUp) return '';
      const time = this.getReconnectDelay(this.incomingSyncAttempts);
      return showTimeDifference(time, this.$i18n.locale);
    },
    incomingSyncGaveUp() {
      return this.incomingSyncAttempts >= reconnectIntervals.length - 1;
    },
    ongoingSyncNextReconnect() {
      if (this.ongoingSyncOk || !this.ongoingSyncAttempts || this.ongoingSyncGaveUp) return '';
      const time = this.getReconnectDelay(this.ongoingSyncAttempts);
      return showTimeDifference(time, this.$i18n.locale);
    },
    ongoingSyncGaveUp() {
      return this.ongoingSyncAttempts >= reconnectIntervals.length - 1;
    },
  },
  watch: {
    incomingSyncAttempts: {
      immediate: true,
      handler(attempts) {
        const isOk = attempts === 0;
        setTimeout(() => { this.incomingSyncOk = isOk; }, isOk ? 2500 : 1000);
      },
    },
    ongoingSyncAttempts: {
      immediate: true,
      handler(attempts) {
        const isOk = attempts === 0;
        setTimeout(() => { this.ongoingSyncOk = isOk; }, isOk ? 2500 : 1000);
      },
    },
  },
  mounted() {
    window.addEventListener('online', this.handleOnlineChange);
    window.addEventListener('offline', this.handleOnlineChange);
    this.handleOnlineChange();
  },
  destroyed() {
    window.removeEventListener('online', this.handleOnlineChange);
    window.removeEventListener('offline', this.handleOnlineChange);
  },
  methods: {
    handleOnlineChange() {
      this.isOnline = navigator.onLine;
    },
    getReconnectDelay(attemptCount) {
      return reconnectIntervals[attemptCount] * 1000;
    },
    refreshWindow() {
      window.location.reload();
    },
  },
};
</script>

<style scoped>
.connection-alert {
  min-width: 250px;
  width: 20vw;
  max-width: 450px;
}
.sync-icon-wrap {
  min-width: 25px;
  max-width: 25px;
  text-align: center;
}
.sync-error:not(:last-child) {
  border-bottom: 1px solid rgba(255,255,255,0.25);
  margin-bottom: 0.5rem;
}
</style>
