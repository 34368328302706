<template>
  <b-button
    v-if="display"
    v-b-tooltip="$options.tooltipOptions"
    class="p-0"
    size="sm"
    variant="link"
    :title="tooltipText"
  >
    <font-awesome-icon icon="language" />
    <span class="sr-only" />
  </b-button>
</template>

<script>
import { chatEvent } from 'supwiz/supchat/constants';
import { mapActions, mapGetters } from 'vuex';
import { testAndGetTranslation } from 'supwiz/supchat/generalUtils';

import { tooltipOptions } from '@/utils/constants';

export default {
  name: 'ChatLanguageBadge',
  tooltipOptions: {
    ...tooltipOptions,
    placement: 'top',
    triggers: 'hover focus',
    delay: { show: 50, hide: 250 },
  },
  props: {
    chat: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('tenants/languages', ['getDefaultLanguage']),
    display() {
      if (!this.hasTranslations) return false;
      return this.departmentLanguage !== this.chatLanguage;
    },
    departmentLanguage() {
      const departmentId = this.chat.department_id;
      const tenantId = this.chat.tenant;
      return this.getDefaultLanguage({ departmentId, tenantId });
    },
    hasTranslations() {
      const msgs = this.chat.msgs;
      if (!msgs) return false;
      const hasTranslations = msgs.some(
        (msg) => msg.command === chatEvent.SAY && !!msg.translated_uuid,
      );
      return hasTranslations;
    },
    chatLanguage() {
      return this.chat.visitor_language;
    },
    tooltipText() {
      if (!this.display) return '';
      const languageCode = `${this.chatLanguage}`.toLowerCase();
      const language = testAndGetTranslation(
        this,
        `interaction.translateTo.${languageCode}`,
        this.chatLanguage,
      );
      return this.$t('message.translatedFrom', { language });
    },
  },
  created() {
    this.ensureLanguagesSetup(this.chat.tenant);
  },
  methods: {
    ...mapActions('tenants/languages', ['ensureLanguagesSetup']),
  },
};
</script>
