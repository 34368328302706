<template>
  <div />
</template>

<script>
import ChatUpdateAndNotificationMixin from '@/mixins/chatUpdateAndNotification';

export default {
  name: 'ChatNotification',
  mixins: [ChatUpdateAndNotificationMixin],
};
</script>

<style scoped>

</style>
