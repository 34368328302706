import Vue from 'vue';
import { deepFreeze, waitUntil } from 'supwiz/supchat/generalUtils';

import {
  getWidgetConfigs,
  addWidgetConfig,
  editWidgetConfig,
  deleteWidgetConfig,
} from '@/api/apiList';
import { cloneDeep } from 'lodash';

const fetchingWidgetConfigs = [];

const widgetConfigState = {};

const widgetConfigGetters = {
  getChatWidgetConfigs: (state) => (tenantId) => state[tenantId] || [],
  getChatWidgetConfig: (state, getters) => ({ tenantId, configId }) => getters
    .getChatWidgetConfigs(tenantId).find(({ id }) => id === configId),
};

const mutations = {
  SET_WIDGET_CONFIGS(state, { tenantId, widgetConfigs }) {
    const listOfWidget = deepFreeze(widgetConfigs);
    Vue.set(state, tenantId, listOfWidget);
  },
};
// TODO Is it cleaner to add in tenantId as a separate parameter,
// even though it is included in the widgetConfig object?
const actions = {
  ensureChatWidgetConfigsFetched({ getters, dispatch }, { tenantId }) {
    const chatWidgets = getters.getChatWidgetConfigs(tenantId);
    if (!chatWidgets.length) dispatch('fetchAndSetWidgetConfigs', { tenantId });
  },
  async fetchAndSetWidgetConfigs({ commit, getters }, { tenantId }) {
    if (fetchingWidgetConfigs.includes(tenantId)) {
      await waitUntil(() => !fetchingWidgetConfigs.includes(tenantId));
    } else {
      fetchingWidgetConfigs.push(tenantId);
      const widgetConfigs = await getWidgetConfigs(tenantId);
      commit('SET_WIDGET_CONFIGS', { tenantId, widgetConfigs });
      fetchingWidgetConfigs.splice(fetchingWidgetConfigs.indexOf(tenantId), 1);
    }
    return getters.getChatWidgetConfigs(tenantId);
  },
  async addWidgetConfig({ commit, getters, dispatch }, {
    configName,
    templateConfigId,
    tenantId,
  }) {
    try {
      let newConfig = {};
      if (templateConfigId !== null) {
        const template = getters.getChatWidgetConfig({ tenantId, configId: templateConfigId });
        if (template) {
          newConfig = cloneDeep(template);
        }
        if (newConfig.visitor_info_fields && newConfig.visitor_info_fields?.length) {
          newConfig.visitor_info_fields = newConfig.visitor_info_fields
            .map((field) => ({
              ...field,
              id: undefined,
            }));
        }
      }
      newConfig.id = undefined;
      newConfig.name = configName;
      newConfig.tenant = tenantId;
      const { id: addedConfigId } = await addWidgetConfig(newConfig);
      await dispatch('fetchAndSetWidgetConfigs', { tenantId });
      return addedConfigId;
    } catch (error) {
      commit('errorDisplay/ADD_MSG', {
        type: 'widgetconfig',
        message: 'errors.widgetConfigFailedToCreate',
      }, { root: true });
      throw Vue.$log.error(error);
    }
  },
  async editWidgetConfig({ commit, dispatch }, { widgetConfig, tenantId }) {
    try {
      await editWidgetConfig(widgetConfig);
      commit('errorDisplay/ADD_MSG', {
        type: 'widgetconfigcache',
        variant: 'warning',
        message: 'message.widgetConfig.cacheWarningMessage',
      }, { root: true });
      return dispatch('fetchAndSetWidgetConfigs', { tenantId });
    } catch (error) {
      commit('errorDisplay/ADD_MSG', {
        type: 'widgetconfig',
        message: 'errors.widgetConfigFailedToEdit',
      }, { root: true });
      throw Vue.$log.error(error);
    }
  },
  async deleteWidgetConfig({ commit, dispatch }, { tenantId, id }) {
    try {
      await deleteWidgetConfig(id);
      return dispatch('fetchAndSetWidgetConfigs', { tenantId });
    } catch (error) {
      commit('errorDisplay/ADD_MSG', {
        type: 'widgetconfig',
        message: 'errors.widgetConfigFailedToDelete',
      }, { root: true });
      throw Vue.$log.error(error);
    }
  },
};

export default {
  state: widgetConfigState,
  getters: widgetConfigGetters,
  mutations,
  actions,
};
