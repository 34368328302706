import Vue from 'vue';
import { waitUntil } from 'supwiz/supchat/generalUtils';
import {
  getAnonymizers, addAnonymizer, editAnonymizer, deleteAnonymizer,
} from '@/api/apiList';

const fetchingAnonymizer = new Set();

const anonymState = {
  anonymizers: {}, // tenant name as key and anonymizer list as value
};

const anonymizerGetters = {
  getTenantAnonymizers: (state) => (tenantId) => state.anonymizers[tenantId] || [],
  getTenantAnonymizerIndex:
      (state) => (tenantId, id) => state.anonymizers[tenantId].map((x) => x.id).indexOf(id),
};

const mutations = {
  SET_ANONYMIZERS(state, { tenantId, anonymizers }) {
    Vue.set(state.anonymizers, tenantId, anonymizers);
  },
  ADD_NEW_ANONYMIZER(state, { tenantId, anonymizer }) {
    if (!Object.prototype.hasOwnProperty.call(state.anonymizers, tenantId)) {
      Vue.set(state.anonymizers, tenantId, []);
    }
    state.anonymizers[tenantId].push(anonymizer);
  },
  REMOVE_ANONYMIZER(state, { tenantId, entryIndex }) {
    state.anonymizers[tenantId].splice(entryIndex, 1);
  },
  UPDATE_ANONYMIZER(state, { tenantId, entryIndex, anonymizer }) {
    Vue.set(state.anonymizers[tenantId], entryIndex, anonymizer);
  },
};

const actions = {
  async ensureAnonymizersFetched({ state, dispatch }, { tenantId }) {
    if (!Object.prototype.hasOwnProperty.call(state.anonymizers, tenantId)) {
      return dispatch('fetchAndSetAnonymizer', { tenantId });
    }
    return state.anonymizers[tenantId];
  },
  async fetchAndSetAnonymizer({ commit, getters }, { tenantId }) {
    try {
      if (fetchingAnonymizer.has(tenantId)) {
        await waitUntil(() => !fetchingAnonymizer.has(tenantId));
      } else {
        fetchingAnonymizer.add(tenantId);
        const anonymizers = await getAnonymizers(tenantId);
        commit('SET_ANONYMIZERS', { tenantId, anonymizers });
        fetchingAnonymizer.delete(tenantId);
      }
      return getters.getTenantAnonymizers(tenantId);
    } catch (error) {
      commit('errorDisplay/ADD_MSG', {
        message: 'errors.failedtoFetchAnonymization',
        variant: 'danger',
      }, { root: true });
      Vue.$log.error(error);
      return error;
    }
  },

  async addAnonymizer({ commit }, {
    type, data, replacement, tenantId,
  }) {
    try {
      const addedAnonymizerResp = await addAnonymizer({
        type, data, replacement, tenantId,
      });
      commit('ADD_NEW_ANONYMIZER', { tenantId, anonymizer: addedAnonymizerResp });
    } catch (error) {
      Vue.$log.error(error);
    }
  },

  async editAnonymizer({ getters, commit }, {
    id, type, data, replacement, tenantId,
  }) {
    try {
      const editedAnonymizer = {
        id, type, data, replacement,
      };
      await editAnonymizer(editedAnonymizer);
      const entryIndex = getters.getTenantAnonymizerIndex(tenantId, id);
      commit('UPDATE_ANONYMIZER', { tenantId, entryIndex, anonymizer: editedAnonymizer });
    } catch (error) {
      Vue.$log.error(error);
    }
  },

  async deleteAnonymizer({ getters, commit }, { tenantId, id }) {
    const resp = await deleteAnonymizer(id);

    if (resp.status === 204) {
      const entryIndex = getters.getTenantAnonymizerIndex(tenantId, id);
      commit('REMOVE_ANONYMIZER', { tenantId, entryIndex });
    }
  },

};

export default {
  state: anonymState,
  getters: anonymizerGetters,
  mutations,
  actions,
};
