/* eslint-disable camelcase */
import Vue from 'vue';
import { waitUntil } from 'supwiz/supchat/generalUtils';
import {
  addCannedMessage,
  deleteCannedMessage,
  editCannedMessage,
  getCannedMsgs,
} from '../../../api/apiList';

const fetchingCannedMessages = new Set();

const msgState = {
  tenantsCannedMsgs: {}, // tenant name as key and messages list as value
};

const cannedMsgsGetters = {
  getTenantCannedMsg: (state) => (tenantId) => state.tenantsCannedMsgs[tenantId] || [],
};

const mutations = {
  SET_CANNED_MSGS(state, { tenantId, msgs }) {
    Vue.set(state.tenantsCannedMsgs, tenantId, msgs);
  },
  ADD_NEW_CANNED_MSG(state, { tenant, data }) {
    state.tenantsCannedMsgs[tenant].push(data);
  },
  REMOVE_CANNED_MSG(state, { tenant, id }) {
    const cannedMsgIndex = state.tenantsCannedMsgs[tenant].findIndex((cm) => cm.id === id);
    state.tenantsCannedMsgs[tenant].splice(cannedMsgIndex, 1);
  },
  UPDATE_CANNED_MSG(state, { tenant, id, data }) {
    const cannedMsgIndex = state.tenantsCannedMsgs[tenant].findIndex((cm) => cm.id === id);
    Vue.set(state.tenantsCannedMsgs[tenant], cannedMsgIndex, data);
  },
};

const actions = {
  /* assumption that agent must belong to at least one tenant */
  async ensureCannedMsgsFetched({ state, dispatch }, { tenantId }) {
    if (!Object.prototype.hasOwnProperty.call(state.tenantsCannedMsgs, tenantId)) {
      return dispatch('fetchAndSetCannedMsgs', { tenantId });
    }
    return state.tenantsCannedMsgs[tenantId];
  },
  async fetchAndSetCannedMsgs({ commit, getters }, { tenantId }) {
    if (fetchingCannedMessages.has(tenantId)) {
      await waitUntil(() => !fetchingCannedMessages.has(tenantId));
    } else {
      fetchingCannedMessages.add(tenantId);
      const msgs = await getCannedMsgs(tenantId);
      commit('SET_CANNED_MSGS', { tenantId, msgs });
      fetchingCannedMessages.delete(tenantId);
    }
    return getters.getTenantCannedMsg(tenantId);
  },
  async addCannedMsg({ commit }, {
    tenant, content, subject, eligible_for_prediction,
  }) {
    const data = await addCannedMessage(tenant, content, subject, eligible_for_prediction);
    commit('ADD_NEW_CANNED_MSG', { tenant, data });
  },
  async deleteCannedMsg({ commit }, { tenant, id }) {
    const resp = await deleteCannedMessage(tenant, id);

    if (resp.status === 204) {
      commit('REMOVE_CANNED_MSG', { tenant, id });
    }
  },
  async editCannedMsg({ commit }, {
    tenant, id, content, subject, eligible_for_prediction,
  }) {
    const data = await editCannedMessage(tenant, id, content, subject, eligible_for_prediction);
    commit('UPDATE_CANNED_MSG', { tenant, id, data });
  },
};

export default {
  state: msgState,
  getters: cannedMsgsGetters,
  mutations,
  actions,
};
