<template>
  <div
    v-if="ready && !sidebarCompact"
    class="mx-3 agents-list r-25 d-flex flex-column text-white"
  >
    <div class="d-flex">
      <ListSettings
        v-if="!compact && maxRole > 1"
        v-bind="{
          grouping,
          agentFilter,
          departmentFilter,
          statusFilter,
        }"
        @set-grouping="grouping = $event"
        @set-status-filter="statusFilter = $event"
        @set-agent-filter="agentFilter = $event"
        @set-department-filter="departmentFilter = $event"
        @save="updateBlob"
      />
      <b-button
        size="sm"
        variant="link"
        :class="['text-light py-1 flex-fill', { 'pr-4': !compact }]"
        @click="compact = !compact"
      >
        <FontAwesomeIcon
          :icon="compact ? 'caret-up' : 'caret-down'"
        />
      </b-button>
    </div>
    <FadeUp :class="['list scrollbar-sidebar flex-fill px-2 pb-2 ease-in-out', { compact }]">
      <div
        v-if="compact"
        key="compact"
      >
        <AgentItem
          v-for="{ status, text } in compactStatusList"
          :key="status + text"
          :display-name="text"
          :status="status"
        />
      </div>
      <div
        v-else-if="finalList.length"
        key="fulllist"
      >
        <template v-if="grouping === 'none'">
          <AgentItem
            v-for="agent in finalList"
            :key="agent.id"
            :display-name="agent.display_name"
            :status="agent.status"
          />
        </template>
        <template v-if="grouping === 'department'">
          <ListGroup
            v-for="group in finalList"
            :key="group.id"
          >
            <template #title>
              {{ group.name }}
            </template>
            <AgentItem
              v-for="agent in group.agents"
              :key="agent.id"
              :display-name="agent.display_name"
              :status="agent.status"
            />
          </ListGroup>
        </template>
      </div>
      <AgentItem
        v-else
        :display-name="$t('message.noneOnline')"
        status="IN"
      />
    </FadeUp>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import { sortBy } from 'lodash';
import FadeUp from '@/components/Transitions/FadeUp.vue';
import AgentItem from './AgentItem.vue';
import ListSettings from './ListSettings.vue';
import ListGroup from './ListGroup.vue';

export default {
  name: 'AgentsStatusList',
  statusSort: {
    ON: 1,
    AW: 2,
    IN: 3,
  },
  components: {
    AgentItem,
    FadeUp,
    ListSettings,
    ListGroup,
  },
  data() {
    return {
      ready: false,
      compact: true,
      grouping: 'none',
      statusFilter: [],
      agentFilter: [],
      departmentFilter: [],
    };
  },
  computed: {
    ...mapGetters('systemAgents', ['systemAgents']),
    ...mapGetters('agent', ['departments', 'maxRole']),
    ...mapGetters('status', ['getAgentStatus']),
    ...mapState('agent/settings', ['blob']),
    ...mapState('status', ['agentsInDepartments']),
    ...mapState('templateStore', ['sidebarCompact']),
    agentsWithStatus() {
      const agents = this.systemAgents;
      let agentsWithStatus = agents.map((agent) => ({
        ...agent,
        status: this.getAgentStatus(agent.id),
      }));
      if (this.agentFilter.length) {
        agentsWithStatus = agentsWithStatus.filter(({ id }) => this.agentFilter.includes(id));
      }
      return this.sortAgentArray(agentsWithStatus);
    },
    finalList() {
      let agentsFiltered = this.agentsWithStatus;
      if (this.statusFilter.length) {
        agentsFiltered = agentsFiltered.filter(({ status }) => this.statusFilter.includes(status));
      }
      if (this.grouping === 'none') return agentsFiltered;
      const departmentAgents = this.agentsInDepartments;
      let departmentGroups = Object.entries(departmentAgents).map(([id, agentIds]) => ({
        id,
        name: this.departments.find((dep) => dep.id === id)?.fullName || id,
        // resort
        agents: this.sortAgentArray(agentIds
          .map((agentId) => agentsFiltered.find((agent) => agent.id === agentId))
          .filter((agent) => !!agent)),
      }));
      if (this.departmentFilter.length) {
        departmentGroups = departmentGroups.filter(({ id }) => this.departmentFilter.includes(id));
      }
      const sortedByDepartmentName = sortBy(
        departmentGroups.filter((group) => group.agents.length),
        [
          (group) => group.name.toLowerCase(),
        ],
      );
      return sortedByDepartmentName;
    },

    compactStatusList() {
      const agentsList = this.agentsWithStatus;
      const statusList = agentsList.map(({ status }) => status);
      const countOfOnline = statusList.filter((x) => x === 'ON').length;
      const onlineSummary = {
        status: 'ON',
        text: this.$t('message.summaryOnline', {
          x: countOfOnline,
          agent: this.$tc('vocabulary.agent', countOfOnline).toLowerCase(),
        }),
      };
      const countOfAway = statusList.filter((x) => x === 'AW').length;
      const awaySummary = {
        status: 'AW',
        text: this.$t('message.summaryAway', {
          x: countOfAway,
          agent: this.$tc('vocabulary.agent', countOfAway).toLowerCase(),
        }),
      };
      if (!countOfOnline && !countOfAway) {
        return [{
          status: 'IN',
          text: this.$t('message.noneOnline'),
        }];
      }
      return [onlineSummary, awaySummary];
    },
    statusOptions() {
      return [
        {
          value: 'ON',
          text: this.$t('vocabulary.online'),
        },
        {
          value: 'AW',
          text: this.$t('vocabulary.away'),
        },
        {
          value: 'IN',
          text: this.$t('vocabulary.invisible'),
        },
      ];
    },
  },
  async created() {
    await this.ensureAgentsFetched();
    const {
      grouping,
      statusFilter,
      agentFilter,
      departmentFilter,
    } = this.blob.statusList;
    if (grouping) this.grouping = grouping;
    if (Array.isArray(statusFilter)) this.statusFilter = statusFilter;
    if (Array.isArray(agentFilter)) this.agentFilter = agentFilter;
    if (Array.isArray(departmentFilter)) this.departmentFilter = departmentFilter;
    this.ready = true;
  },
  methods: {
    ...mapActions('agent/settings', ['writeToAgentBlob']),
    ...mapActions('systemAgents', ['ensureAgentsFetched']),
    updateBlob() {
      this.writeToAgentBlob({
        statusList: {
          grouping: this.grouping,
          statusFilter: this.statusFilter,
          agentFilter: this.agentFilter,
          departmentFilter: this.departmentFilter,
        },
      });
    },
    sortAgentArray(agents) {
      return sortBy(
        agents.filter((agent) => agent.status !== undefined),
        [(a) => this.$options.statusSort[a.status],
          (a) => a.display_name.toLowerCase()],
      );
    },
  },
};
</script>

<style>
.agents-list {
  background-color: var(--sidebar-lighten) !important;
  font-size: .85rem;
  overflow: hidden;
}
.agents-list .list {
  max-height: 20vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.agents-list .list.compact {
  max-height: 80px;
  overflow-y: hidden;
}
</style>
