import { cloneDeep, isEmpty, isEqual } from 'lodash';
import Vue from 'vue';

import { deepFreeze } from 'supwiz/supchat/generalUtils';

const dataStorageMap = {
  charts: 'chartData',
  table: 'tableData',
};

function dataDefaultStructure() {
  return {
    tenant: {},
    agent: {},
    department: {},
  };
}

const analyticsState = {
  isFetching: {
    charts: false,
    table: false,
  },
  chartData: dataDefaultStructure(),
  tableData: dataDefaultStructure(),
  currentParams: {},
  currentView: 'charts',
};

const analyticsGetters = {
  results: (state) => state[dataStorageMap[state.currentView]],
};

const mutations = {
  RESET_DATA(state) {
    state.chartData = dataDefaultStructure();
    state.tableData = dataDefaultStructure();
  },
  SET_CURRENT_PARAMS(state, params) {
    Vue.set(state, 'currentParams', params);
  },
  SET_FETCHING(state, { view, status }) {
    state.isFetching[view] = status;
  },
  SET_VIEW(state, view) {
    state.currentView = view;
  },
  SET_ANALYTICS_DATA(state, { data, groupBy }) {
    const ids = Object.keys(data);
    const storageKey = dataStorageMap[state.currentView];

    // clear any existing data
    if (Object.keys(state[storageKey][groupBy]).length) {
      Vue.set(state[storageKey], groupBy, {});
    }
    ids.forEach((id) => {
      Vue.set(state[storageKey][groupBy], id, data[id]);
    });
  },
  ADD_ANALYTICS_DATA(state, { data, groupBy, metric }) {
    const ids = Object.keys(data);

    ids.forEach((id) => {
      const values = data[id][metric];
      Vue.set(state[dataStorageMap[state.currentView]][groupBy][id], metric, values);
    });
  },
};

const actions = {
  async fetchAnalyticsData({
    state, commit, dispatch, rootGetters,
  }, { params = {}, singleMetric = '' }) {
    let preparedParams = {};
    const currentView = state.currentView;
    // prepare the param object
    if (singleMetric) {
      preparedParams = {
        ...cloneDeep(state.currentParams),
        metrics: [singleMetric],
      };
    } else {
      commit('SET_FETCHING', { view: currentView, status: true });
      const filters = {};

      // parse selected filters to structure supported by backend
      for (const [key, { value, enabled, comparison }] of Object.entries(params.filters)) {
        if (enabled) {
          let keyName = key;
          if (keyName.startsWith('average_')) keyName = keyName.replace('average_', '');
          filters[comparison ? keyName + comparison : keyName] = keyName === 'tenant'
            ? [value]
            : value;
        }
      }
      const heavyMetrics = ['concurrent_chats'];

      preparedParams = deepFreeze(cloneDeep({
        ...params,
        filters,
        metrics: rootGetters['analytics/enabledMetrics']
          .filter((metric) => !heavyMetrics.includes(metric)),
        // if getting stats for the table, we want a single datapoint rather than multiple
        // When we give it null, it will generate timestamps instead using interval settings
        intervalEndpoints: currentView !== 'table'
          ? null
          : [Math.floor(params.startTime / 1000), Math.floor(params.endTime / 1000)],
      }));
      if (!isEqual(preparedParams, state.currentParams)) {
        commit('RESET_DATA');
      }
    }
    dispatch('analytics/addFetchToQueue', {
      params: preparedParams,
      callback: (data) => {
        if (isEmpty(data)) {
          commit('errorDisplay/ADD_MSG', {
            message: 'analytics.errors.noData',
            variant: 'info',
          }, { root: true });
        } else if (singleMetric) {
          commit('ADD_ANALYTICS_DATA', { data, groupBy: preparedParams.groupBy, metric: singleMetric });
        } else {
          commit('SET_ANALYTICS_DATA', { data, groupBy: preparedParams.groupBy });
          // save the param object for later use
          commit('SET_CURRENT_PARAMS', preparedParams);
        }
        commit('SET_FETCHING', { view: currentView, status: false });
      },
      errorCallback: () => {
        commit('SET_FETCHING', { view: currentView, status: false });
      },
    }, { root: true });
  },
  async fetchAnalyticsChatIds({
    state, dispatch,
  }, {
    filters, dataIndex, id, showAllChatIds,
  }) {
    const metric = 'chat_ids';
    const data = await dispatch(
      'analytics/fetchAnalyticsData',
      {
        ...state.currentParams,
        filters,
        metrics: [metric],
      },
      { root: true });
    if (showAllChatIds) {
      return data?.[id]?.[metric]?.reduce((a, b) => a.concat(b), []) || [];
    }
    return data?.[id]?.[metric]?.[dataIndex] || [];
  },
};

export default {
  namespaced: true,
  state: analyticsState,
  getters: analyticsGetters,
  mutations,
  actions,
};
