import { waitUntil } from 'supwiz/supchat/generalUtils';

import {
  getDepartmentOpeningHourRules,
  updateDepartmentOpeningHourRules,
  getOpeningHourRules,
  createOpeningHourRules,
  updateOpeningHourRules,
  deleteOpeningHourRules,
  getOpeningHoursPreview,
} from '@/api/apiList';
import Vue from 'vue';
import moment from 'moment';
import { sortBy } from 'lodash';

function isoStringToDate(str) {
  return new Date(moment(str));
}

function getFromRRuleString(rruleString) {
  const result = {};
  const dtstartAndRrule = rruleString.split(/rrule:/i);
  if (dtstartAndRrule[0] !== '') {
    const [, dtstartVal] = dtstartAndRrule[0].split(':');
    result.DTSTART = isoStringToDate(dtstartVal.trim());
  }
  const entries = dtstartAndRrule[1]
    .split(';')
    .filter((entry) => !!entry);
  entries.forEach((entry) => {
    const [key, val] = entry.toUpperCase().split('=');
    let preparedVal = val;
    if (val.includes(',')) preparedVal = val.split(',');
    if (key === 'UNTIL') {
      result[key] = isoStringToDate(val);
    } else {
      result[key] = preparedVal;
    }
  });
  return result;
}
function parseToRRuleString(rruleObject) {
  if (!Object.keys(rruleObject).length) return '';
  let result = '';
  Object.entries(rruleObject).forEach(([key, val]) => {
    let preparedVal = '';
    if (['UNTIL', 'DTSTART'].includes(key)) {
      // Nothing to see here. Please disperse.
      // preparedVal = `${(new Date(val).toISOString().split('.')[0])}Z`.replace(/-|:|\./g, '');
      preparedVal = `${(new Date(val).toISOString().split('T')[0])}T120000Z`.replace(/-/g, '');
    } else {
      preparedVal = Array.isArray(val) ? val.join(',') : `${val}`;
    }
    if (key === 'DTSTART') {
      result = `${key}:${preparedVal} RRULE:${result}`;
    } else {
      result += `${key}=${preparedVal};`;
    }
  });
  if (result.endsWith(';')) result = result.slice(0, -1);
  return `${result}`;
}

const openingHoursState = {
  fetchingState: {},
  rules: {},
  ruleAssignments: {},
  currentOpeningHours: {},
  startDate: new Date(),
};

const openingHoursGetters = {
  rulesAsList: (state) => Object.values(state.rules),
  getAssignedRules: (state) => (id) => state.ruleAssignments[id] || [],
};

const mutations = {
  SET_RULES(state, rules) {
    for (const rule of rules) {
      Vue.set(state.rules, rule.id, {
        ...rule,
        rrule: getFromRRuleString(rule.rrule),
      });
    }
  },
  DELETE_RULE(state, ruleId) {
    Vue.delete(state.rules, ruleId);
  },
  SET_RULE_ASSIGNMENT(state, { departmentId, rules }) {
    if (!rules.length) return;
    if (!(departmentId in state.ruleAssignments)) Vue.set(state.ruleAssignments, departmentId, []);
    // make sure rules are sorted by their order
    const sortedRuleIds = sortBy(rules, 'order').map(({ rule }) => rule.id);
    Vue.set(state.ruleAssignments, departmentId, sortedRuleIds);
  },
  UPDATE_RULE_ASSIGNMENT(state, { departmentId, rules }) {
    if (!(departmentId in state.ruleAssignments)) Vue.set(state.ruleAssignments, departmentId, []);
    Vue.set(state.ruleAssignments, departmentId, rules);
  },
  SET_FETCHING(state, { taskId, isFetching }) {
    Vue.set(state.fetchingState, taskId, isFetching);
  },
  SET_CURRENT_OPENING_HOURS(state, { tenantId, openingHours }) {
    Vue.set(state.currentOpeningHours, tenantId, openingHours);
  },
  SET_START_DATE(state, date) {
    Vue.set(state, 'startDate', date);
  },
};
const actions = {
  async fetchDepartmentRules({ state, commit }, { departmentId }) {
    const taskId = `fetch${departmentId}rules`;
    if (state.fetchingState[taskId]) {
      await waitUntil(() => !state.fetchingState[taskId]);
    } else {
      try {
        commit('SET_FETCHING', { taskId, isFetching: true });
        const { rules } = await getDepartmentOpeningHourRules(departmentId);
        commit('SET_RULE_ASSIGNMENT', { departmentId, rules });
        commit('SET_FETCHING', { taskId, isFetching: false });
      } catch (error) {
        commit('errorDisplay/ADD_MSG', {
          message: 'errors.unknownError',
          variant: 'danger',
        }, { root: true });
        throw error;
      }
    }
  },
  async updateDepartmentRules(
    { state, commit },
    { departmentId, ruleOrder }) {
    const taskId = `update${departmentId}rules`;
    if (state.fetchingState[taskId]) {
      await waitUntil(() => !state.fetchingState[taskId]);
    } else {
      try {
        commit('SET_FETCHING', { taskId, isFetching: true });
        await updateDepartmentOpeningHourRules(departmentId, { rule_ids: ruleOrder });
        commit('UPDATE_RULE_ASSIGNMENT', { departmentId, rules: ruleOrder });
        commit('SET_FETCHING', { taskId, isFetching: false });
      } catch (error) {
        commit('errorDisplay/ADD_MSG', {
          message: 'errors.unknownError',
          variant: 'danger',
        }, { root: true });
        throw error;
      }
    }
  },
  async fetchAllRules({ state, commit }) {
    const taskId = 'allrules';
    if (state.fetchingState[taskId]) {
      await waitUntil(() => !state.fetchingState[taskId]);
    } else {
      const fetchRules = async (nextUrl = '') => {
        const { results, next } = await getOpeningHourRules(nextUrl);
        commit('SET_RULES', results);
        if (next) await fetchRules(next);
      };
      commit('SET_FETCHING', { taskId, isFetching: true });
      await fetchRules();
      commit('SET_FETCHING', { taskId, isFetching: false });
    }
  },
  ensureAllRules({ state, dispatch }) {
    if (!Object.keys(state.rules).length) dispatch('fetchAllRules');
  },
  async ensureDepartmentRules({ state, dispatch }, departmentId) {
    if (!state.ruleAssignments[departmentId]?.length) {
      return dispatch('fetchDepartmentRules', { departmentId });
    }
    return true;
  },
  async getOpeningHoursPreview({ state, commit }, { tenantId }) {
    const taskId = `${tenantId}openinghourspreview`;
    if (state.fetchingState[taskId]) {
      await waitUntil(() => !state.fetchingState[taskId]);
    } else {
      commit('SET_FETCHING', { taskId, isFetching: true });
      const openingHours = await getOpeningHoursPreview(
        tenantId,
        (moment(state.startDate).startOf('isoWeek').valueOf()) / 1000,
      );
      commit('SET_CURRENT_OPENING_HOURS', { tenantId, openingHours });
      commit('SET_FETCHING', { taskId, isFetching: false });
    }
  },
  updateStartTime({ commit, dispatch }, { startDate, tenantId }) {
    commit('SET_START_DATE', startDate);
    dispatch('getOpeningHoursPreview', { tenantId });
  },
  async ruleHandler({ commit }, { action, ruleObj }) {
    try {
      const preparedRuleObj = {
        end_time: ruleObj.end_time,
        start_time: ruleObj.start_time,
        name: ruleObj.name,
        open: ruleObj.open,
        rrule: parseToRRuleString(ruleObj.rrule),
      };
      if (ruleObj.id !== undefined) preparedRuleObj.id = ruleObj.id;
      switch (action) {
        case 'update':
          await updateOpeningHourRules(ruleObj.id, preparedRuleObj);
          break;
        case 'delete':
          await deleteOpeningHourRules(ruleObj.id);
          break;
        case 'create':
        default: {
          const { id } = await createOpeningHourRules(preparedRuleObj);
          preparedRuleObj.id = id;
          break;
        }
      }
      if (action === 'delete') {
        commit('DELETE_RULE', ruleObj.id);
      } else commit('SET_RULES', [preparedRuleObj]);
    } catch (error) {
      commit('errorDisplay/ADD_MSG', {
        message: 'errors.unknownError',
        variant: 'danger',
      }, { root: true });
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state: openingHoursState,
  getters: openingHoursGetters,
  mutations,
  actions,
};
