import Vue from 'vue';

import { isEqual } from 'lodash';

import { reconnectIntervals, chatEvent } from 'supwiz/supchat/constants';
import { deepFreeze } from 'supwiz/supchat/generalUtils';
import { getMyQueue, getHistory } from '@/api/apiList';
import { ongoingChatFetchIntervals } from '@/utils/constants';
import { currentTime } from '@/composables/currentTime';

const ongoingChatsState = {
  ongoingChats: [],
  lastOngoingChatsRefresh: 0,
  failedOngoingFetchAttempts: 0,
  ongoingChatsInterval: null,
  awaitingOngoingChatsRefresh: false,
};

/*
  used in updateSingleOngoingChat's update flow to avoid spam
  if a chat id exists in the Set, it means that we are about to update it
  so we do nothing. Once it's fetched shortly after, it's removed from the Set
  allowing future calls to trigger another fetch.
*/
const individualChatsAwaitingUpdate = new Set();

let fetchingOngoingChats = false;

const ongoingChatsGetters = {
  visibleOngoingChats: (state, getters, rootState, rootGetters) => state.ongoingChats
    .filter((chat) => {
      if (Array.isArray(chat.msgs)) {
        const isClosed = chat.msgs.some(
          (msg) => msg.command === chatEvent.STATUS && msg.text === 'close',
        );
        if (isClosed) return false;
      }
      const agentId = rootGetters['agent/id'];
      const departmentsFilter = rootState.agent.settings.blob.hiddenDepartmentsOngoing;
      // if we are not able to get our filter or if the chat doesn't have a department
      // then we display it regardless
      if (!Array.isArray(departmentsFilter) || !chat?.department_id) return true;
      return !departmentsFilter.includes(chat.department_id) || chat.assigned_to_agent === agentId;
    }),
  visibleOngoingChatIDs: (state, getters) => getters.visibleOngoingChats.map(({ id }) => id),
  getOngoingChatFromID: (state) => (chatId) => state.ongoingChats
    .find(({ id }) => id === chatId || {}),
  myOngoingChats: (state, getters, rootState, rootGetters) => getters
    .visibleOngoingChats.filter((chat) => {
      // stringify because we will compare to an object key
      const agentIdString = String(rootGetters['agent/id']);
      let activeAgents = [];
      if (typeof chat.active_agents === 'object') {
        activeAgents = Object.keys(chat.active_agents);
      }
      return activeAgents.includes(agentIdString);
    }),
  myOngoingChatIDs: (state, getters) => getters.myOngoingChats.map(({ id }) => id)?.sort() || [],
  agentOngoingChats: (state) => (agentId) => state
    .ongoingChats.filter((chat) => {
      // stringify because we will compare to an object key
      const agentIdString = String(agentId);
      let activeAgents = [];
      if (typeof chat.active_agents === 'object') {
        activeAgents = Object.keys(chat.active_agents);
      }
      return activeAgents.includes(agentIdString);
    }),
  agentOngoingChatIDs: (state, getters) => (agentId) => getters
    .agentOngoingChats(agentId).map(({ id }) => id)?.sort() || [],
};

const mutations = {
  UPDATE_LAST_ONGOING_REFRESH_TIME(state, lastUpdate) {
    state.lastOngoingChatsRefresh = lastUpdate;
  },
  SET_ONGOING_CHATS(state, chats) {
    state.ongoingChats = [...chats.map((chat) => deepFreeze(chat))];
  },
  ADD_ONGOING_CHAT(state, chat) {
    state.ongoingChats.push(deepFreeze(chat));
  },
  UPDATE_ONGOING_CHAT(state, { chatIndex, updatedChatObj }) {
    Vue.set(state.ongoingChats, chatIndex, deepFreeze(updatedChatObj));
  },
  DELETE_ONGOING_CHAT(state, { chatIndex }) {
    Vue.delete(state.ongoingChats, chatIndex);
  },
  SET_ONGOING_POLLING_INTERVAL(state, pollingFunction) {
    state.ongoingChatsInterval = setInterval(
      pollingFunction,
      ongoingChatFetchIntervals.interval,
    );
  },
  SET_ONGOING_RECONNECT_ATTEMPTS(state, attempt) {
    state.failedOngoingFetchAttempts = attempt;
  },
  CLEAR_ONGOING_POLLING_INTERVAL(state) {
    clearInterval(state.ongoingChatsInterval);
    state.ongoingChatsInterval = null;
  },
  SET_AWAITING_ONGOING_CHATS_REFRESH(state, isAwaiting) {
    state.awaitingOngoingChatsRefresh = isAwaiting;
  },
};

const actions = {
  beginPollingOngoingChats({ state, commit, dispatch }) {
    /*
      If controlSocket or the Ongoing Chats page forces a refresh,
      we clear the interval to prevent spam.
    */

    // Start polling interval if not started or cleared
    if (state.ongoingChatsInterval === null) {
      dispatch('refreshAllOngoingChats');
      commit('SET_ONGOING_POLLING_INTERVAL', () => dispatch('refreshAllOngoingChats'));
    }
  },
  /**
   * Fetches ALL ongoing chats if at least (ongoingRefreshInterval)
   * seconds has passed since the last time
   * this function was called. Individual chats can be updated in between refreshes.
   */
  async refreshAllOngoingChats({
    commit, state, dispatch,
  }, payload) {
    if (fetchingOngoingChats) return;
    try {
      const now = currentTime.value;

      if (!payload?.bypassTimeCheck) {
        const lastRefresh = state.lastOngoingChatsRefresh;

        /*
          The control socket will inform us of new chats and other updates
          It does this by setting awaitingOngoingChatsRefresh. If it's true
          then we use our min interval, otherwise we use the max
        */
        const refreshTime = state.awaitingOngoingChatsRefresh
          ? ongoingChatFetchIntervals.min
          : ongoingChatFetchIntervals.max;
        // check if enough time has passed since last
        if ((now - lastRefresh) < refreshTime) return;
      }

      // set our fetching status to true to prevent spam
      fetchingOngoingChats = true;

      // hacky, there is a dependency cycle issue if I import router
      const isOnOngoingPage = document.location.pathname.includes('/visitors');
      commit('SET_AWAITING_ONGOING_CHATS_REFRESH', false);
      const { chats } = await getMyQueue({ filter: 'active', includeMessages: isOnOngoingPage });
      if (!isEqual(state.ongoingChats, chats)) commit('SET_ONGOING_CHATS', chats);
      commit('UPDATE_LAST_ONGOING_REFRESH_TIME', now);

      /*
          If a previous fetch has failed and we are not in the catch block yet
          that means it didn't fail and we can reset the failed attempts.
        */
      if (state.failedOngoingFetchAttempts !== 0) {
        commit('SET_ONGOING_RECONNECT_ATTEMPTS', 0);
      }
    } catch (error) {
      if (error?.response?.status === 0) document.location.reload();
      Vue.$log.error(error);
      commit('CLEAR_ONGOING_POLLING_INTERVAL');

      const delay = reconnectIntervals[state.failedOngoingFetchAttempts];

      if (state.failedOngoingFetchAttempts < reconnectIntervals.length) {
        setTimeout(() => {
          commit('SET_ONGOING_RECONNECT_ATTEMPTS', state.failedOngoingFetchAttempts + 1);
          dispatch('beginPollingOngoingChats');
        }, delay * 1000);
      }
    } finally {
      fetchingOngoingChats = false;
    }
  },
  /**
   * Update the state of a single ongoing chat. This can be updating chatlog or
   * removing (deleting) it from the ongoing state if it has ended.
   * @param {Object} vuex Vuex
   * @param {Object} object object containing chat ID and task ID
   * @param {string} object.chatId chat ID of the chat we want to update or delete
   * @param {('add'|'delete'|'update')} object.task specify if we want to delete or update the chat.
   */
  async updateSingleOngoingChat({ commit, state, getters }, { chatId, task, chatObj = null }) {
    if (task === 'add' && chatObj) {
      // check if chat already exists because then we don't want to add it again
      if (getters.getOngoingChatFromID(chatId)) return;
      commit('ADD_ONGOING_CHAT', chatObj);
      return;
    }

    const indexOfChat = state.ongoingChats.findIndex(({ id }) => id === chatId);
    if (indexOfChat === -1) {
      Vue.$log.error(`tried to ${task} "${chatId}" but doesn't exist in state`);
      return;
    }
    if (task === 'delete') {
      commit('DELETE_ONGOING_CHAT', { chatIndex: indexOfChat });
      return;
    }
    if (task === 'update') {
      /**
       *  no need to update the logs if we cant see them
       *  hacky, there is a dependency cycle issue if I import router
       *
       * also check if we are already about to update the chat history
       * if so, do nothing and await the update instead.
      */
      const isOnOngoingPage = document.location.pathname.includes('/visitors');
      if (!isOnOngoingPage || individualChatsAwaitingUpdate.has(chatId)) return;
      individualChatsAwaitingUpdate.add(chatId);
      setTimeout(async () => {
        const chat = await getHistory(chatId);
        const newMsgs = chat.history;
        const currentChatObj = state.ongoingChats[indexOfChat];
        commit('UPDATE_ONGOING_CHAT', {
          chatIndex: indexOfChat,
          updatedChatObj: { ...currentChatObj, msgs: newMsgs },
        });
        individualChatsAwaitingUpdate.delete(chatId);
      }, ongoingChatFetchIntervals.interval);
    }
  },
};

export default {
  state: ongoingChatsState,
  getters: ongoingChatsGetters,
  mutations,
  actions,
};
