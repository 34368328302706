import moment from 'moment';
import {
  getMetricData,
} from '@/api/apiList';

import {
  availableMetrics,
} from '@/utils/analytics';

import report from './report';
import analytics from './analytics';

function prepareTimeStamps({ startTime, endTime, interval }) {
  const timestamps = [startTime];
  let currentTimestamp = startTime;
  while (currentTimestamp <= endTime) {
    const nextTimestamp = moment(currentTimestamp).add(interval.amount, interval.unit);
    timestamps.push(nextTimestamp);
    currentTimestamp = nextTimestamp;
  }
  return timestamps.map((timestamp) => Math.floor(timestamp / 1000));
}

const analyticsState = {
  fetching: false,
  fetchQueue: [],
  fetchQueueFails: 0,
  fetchQueueWorking: false,
};

const analyticsGetters = {
  enabledMetrics: (state, getters, rootState) => {
    const allMetrics = availableMetrics;
    const unavailableMetrics = [];
    const {
      AI_COPILOT_ENABLED,
      CHAT_ABANDONMENT_METRIC_ENABLED,
      TRANSLATION_ENABLED,
    } = rootState.featureFlags;
    if (!AI_COPILOT_ENABLED) unavailableMetrics.push('chat_sentiments');
    if (!CHAT_ABANDONMENT_METRIC_ENABLED) unavailableMetrics.push('chat_abandonment');
    if (!TRANSLATION_ENABLED) unavailableMetrics.push('chat_translated');

    return allMetrics.filter((metric) => !unavailableMetrics.includes(metric));
  },
};

const mutations = {
  SET_FETCHING_STATUS(state, status) {
    state.fetching = status;
  },
  SET_QUEUE_FAILS(state, count) {
    state.fetchQueueFails = count;
  },
  SET_QUEUE_STATUS(state, status) {
    state.fetchQueueWorking = status;
  },
  ADD_TO_QUEUE(state, task) {
    state.fetchQueue.push(task);
  },
  REMOVE_FIRST_FROM_QUEUE(state) {
    state.fetchQueue.shift();
  },
};

const actions = {
  /**
   * Add analytics fetch to queue
   * @param {Object} vuex - bound by dispatch
   * @param {Object} task - task object containing params object and callback functions
   * @param {Object} task.params - params object for fetchAnalyticsData
   * @param {Function} task.callback - callback function for when data is fetched and returned
   * @param {Function} task.errorCallback - callback function for when fetched failed
   */
  addFetchToQueue({ state, commit, dispatch }, task) {
    commit('ADD_TO_QUEUE', task);
    if (state.fetchQueueWorking || !state.fetchQueue.length) return;
    dispatch('fetchNextQueueItem');
  },
  async fetchNextQueueItem({ state, commit, dispatch }) {
    if (!state.fetchQueue.length) return;
    // get the first task
    const { params, callback, errorCallback } = state.fetchQueue[0];

    try {
      commit('SET_QUEUE_STATUS', true);

      // fetch the data
      const result = await dispatch('fetchAnalyticsData', params);

      // run the task callback
      callback(result);

      // success, remove task and reset fails
      commit('REMOVE_FIRST_FROM_QUEUE');
      commit('SET_QUEUE_FAILS', 0);
    } catch (error) {
      const errorResp = error.json;
      let message = '';
      if (error?.json?.interval_endpoints?.[0].includes('more than 1001 elements')) {
        message = 'analytics.errors.tooManyPoints';
      } else if (error?.response?.status === 504) {
        message = 'analytics.errors.timeout';
      } else if (error?.response?.status === 503) {
        // another concurrent fetch is running
        // wait 5 seconds and retry (this will happen in the finally clause)
        await new Promise((res) => { setTimeout(() => res(), 5_000); });
      } else if (errorResp.code) {
        switch (errorResp.code) {
          case 'tooManyResults':
            message = 'analytics.errors.tooManyResults';
            break;
          default:
            break;
        }
      }
      // this task failed to fetch so we retry twice, then skip
      // if there's a message like timeout or too many results
      // then there's no reason to try again
      if (state.fetchQueueFails >= 1 || message) {
        // if there's an errorCallback then we call that
        if (typeof errorCallback === 'function') errorCallback(error);
        commit('errorDisplay/ADD_MSG', {
          message: message || errorResp.error || errorResp.message || 'errors.unknownError',
          variant: 'danger',
        }, { root: true });
        commit('REMOVE_FIRST_FROM_QUEUE');
        commit('SET_QUEUE_FAILS', 0);
      } else {
        // the failed task should still be at index 0 so we let the finally clause handle the rest
        commit('SET_QUEUE_FAILS', state.fetchQueueFails + 1);
      }
    } finally {
      // the queue is now either empty, or a new task is first in line
      if (!state.fetchQueue.length) {
        commit('SET_QUEUE_STATUS', false);
      } else {
        dispatch('fetchNextQueueItem');
      }
    }
  },
  // don't call this actions directly, use "addFetchToQueue" instead
  async fetchAnalyticsData({ commit }, {
    groupBy,
    startTime,
    endTime,
    interval,
    filters,
    metrics,
    intervalEndpoints = null,
  }) {
    commit('SET_FETCHING_STATUS', true);
    try {
      const intervalEndpointsPrepared = intervalEndpoints
        || prepareTimeStamps({
          startTime,
          endTime,
          interval,
        });
      const result = await getMetricData({
        interval_endpoints: intervalEndpointsPrepared,
        group_by: groupBy,
        filters,
        metrics,
      });
      return result;
    } finally {
      commit('SET_FETCHING_STATUS', false);
    }
  },
};

export default {
  namespaced: true,
  state: analyticsState,
  getters: analyticsGetters,
  mutations,
  actions,
  modules: {
    analytics,
    report,
  },
};
