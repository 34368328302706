<template>
  <span
    class="d-flex align-items-center r-25 score-wrap"
    :style="{ backgroundColor }"
  >
    <font-awesome-icon v-if="!small" icon="wand-magic-sparkles" class="small" />
    <span v-bind="$attrs" class="text-center flex-grow-1 r-25">
      {{ percentage }}
    </span>
  </span>
</template>

<script>
import { formatNumber } from 'supwiz/util/formatters';
import { mapDecimalToColor } from '@/utils/generalUtils';

export default {
  name: 'CopilotScoreDisplay',
  inheritAttrs: false,
  props: {
    decimal: {
      type: [Number, String, null, undefined],
      required: true,
    },
    color: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isNumber() {
      if ([null, undefined].includes(this.decimal)) return false;
      if (typeof this.decimal === 'string') return !Number.isNaN(Number(this.decimal));
      return !Number.isNaN(this.decimal);
    },
    percentage() {
      if (!this.isNumber) return '-';
      return `${formatNumber(this.decimal * 100, 1)}%`;
    },
    backgroundColor() {
      if (!this.color) return 'transparent';
      if (!this.isNumber) return '#ccc';
      return mapDecimalToColor(this.decimal, {
        saturation: 90,
        lightness: 85,
      });
    },
  },
};
</script>
<style scoped>
.score-wrap > .fa-wand-magic-sparkles,
.score-wrap > span {
  padding: 0 0.2rem;

}
.score-wrap > span {
  background: rgba(255,255,255,0.5);
}
</style>
