<template>
  <b-button
    ref="button"
    v-bind="{ ...$attrs }"
    :disabled="$attrs.disabled || busy"
    class="position-relative"
    v-on="{ ...$listeners }"
  >
    <span
      v-show="busy"
      class="spinner-wrap"
    >
      <b-spinner small />
    </span>
    <span :style="{ visibility: busy ? 'hidden' : 'visible' }">
      <slot />
    </span>
  </b-button>
</template>

<script>
export default {
  name: 'SmartBButton',
  props: {
    busy: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.spinner-wrap {
  color: inherit;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
