import { mapActions, mapGetters } from 'vuex';
import {
  notify,
} from 'supwiz/supchat/generalUtils';

export default {
  name: 'IncomingUpdateAndNotification',
  data() {
    return {
      tabTitleText: '',
      tabTitleTimeout: null,
    };
  },

  computed: {
    ...mapGetters('agent/settings', ['notificationEnabled']),
    ...mapGetters('tenants', ['getConfig']),
    ...mapGetters('chat', ['visibleIncomingChats', 'kpiApproachChatIDs', 'kpiBreachChatIDs']),
  },

  watch: {
    visibleIncomingChats(newVal, oldVal) {
      const notificationId = 'new_chat';
      if (!this.notificationEnabled(notificationId)) return;
      if (this.triggerNewEvent(newVal, oldVal)) {
        const headerText = this.$t(`userSettings.notifications.${notificationId}`);
        const bodyText = this.$t(`userSettings.notifications.${notificationId}Body`);
        const notification = notify(headerText, { body: bodyText });
        if (notification) {
          notification.onclick = () => {
            window.focus();
            if (this.$route.name !== 'incoming') {
              this.$router.push({ name: 'incoming' });
            }
          };
        }
      }
      this.setChatCountInTabTitle(newVal.length);
    },
    kpiApproachChatIDs(newVal, oldVal) {
      const notificationId = 'about_to_expire';
      if (!this.notificationEnabled(notificationId)) return;
      if (this.triggerNewEvent(newVal, oldVal)) {
        const headerText = this.$t(`userSettings.notifications.${notificationId}`);
        const bodyText = this.$t(`userSettings.notifications.${notificationId}Body`);
        const notification = notify(headerText, { body: bodyText });
        if (notification) {
          notification.onclick = () => {
            window.focus();
            if (this.$route.name !== 'incoming') {
              this.$router.push({ name: 'incoming' });
            }
          };
        }
      }
    },
    kpiBreachChatIDs(newVal, oldVal) {
      const notificationId = 'expired';
      if (!this.notificationEnabled(notificationId)) return;
      if (this.triggerNewEvent(newVal, oldVal)) {
        const headerText = this.$t(`userSettings.notifications.${notificationId}`);
        const bodyText = this.$t(`userSettings.notifications.${notificationId}Body`);
        const notification = notify(headerText, { body: bodyText });
        if (notification) {
          notification.onclick = () => {
            window.focus();
            if (this.$route.name !== 'incoming') {
              this.$router.push({ name: 'incoming' });
            }
          };
        }
      }
    },
  },
  async created() {
    const tenantIds = this.$store.getters['agent/tenantsAsAgent'].map((tenant) => tenant.id);
    await Promise.all([
      this.ensureDepartmentsFetched(),
      ...tenantIds.map((tenantId) => this.ensureConfigFetched({ tenantId, tag: 'kpi' })),
    ]);
    this.setChatCountInTabTitle(this.visibleIncomingChats.length);
  },

  beforeDestroy() {
    // deregister the outside event listener(like setInterval, addEventListener, etc)
    clearTimeout(this.tabTitleTimeout);
  },

  methods: {
    ...mapActions('tenants', ['ensureConfigFetched']),
    triggerNewEvent(newVal = [], oldVal = []) {
      return newVal.length > oldVal.length;
    },
    setChatCountInTabTitle(count) {
      if (this.tabTitleTimeout) clearTimeout(this.tabTitleTimeout);
      if (this.tabTitleText !== '' && document.title.includes(this.tabTitleText)) {
        document.title = document.title.substring(this.tabTitleText.length);
      } else {
        if (count) {
          this.tabTitleText = `(${count}) `;
        } else {
          this.tabTitleText = '';
        }
        document.title = `${this.tabTitleText}${document.title}`;
      }
      this.tabTitleTimeout = setTimeout(() => {
        this.setChatCountInTabTitle(count);
      }, 1000);
    },
  },
};
