<template>
  <floating-container
    no-body
    class="d-inline-block p-2"
  >
    <b-input-group :prepend="$t('message.hiddenDepartments')">
      <InputComponent
        :id="blobKey"
        v-model="inputToValue"
        filter="agentDepartments"
        no-auto-select
        no-session-storage
      />
    </b-input-group>
    <b-popover
      :target="blobKey"
      :show.sync="popoverShown"
      triggers="manual"
      placement="righttop"
      boundary="viewport"
    >
      <template #title>
        <div class="d-flex justify-content-between align-items-center">
          <span>{{ $t('message.hiddenDepartments') }}</span>
          <b-button
            variant="muted"
            class="ml-auto text-muted"
            :title="$t('vocabulary.close')"
            size="sm"
            @click="popoverShown = false"
          >
            <font-awesome-icon icon="times" />
          </b-button>
        </div>
      </template>
      {{ $t('message.hiddenDepartmentsMsg') }}
    </b-popover>
  </floating-container>
</template>

<script>
import InputComponent from '@/components/InputComponent.vue';

export default {
  name: 'HiddenDepartmentsSelector',
  components: { InputComponent },
  props: {
    blobKey: {
      required: true,
      validator: (val) => ['hiddenDepartmentsOngoing',
        'hiddenDepartmentsIncoming'].includes(val),
    },
  },
  data() {
    return {
      popoverShown: false,
    };
  },
  computed: {
    inputToValue: {
      get() {
        return this.$store.state.agent.settings.blob?.[this.blobKey] || [];
      },
      set(arr) {
        this.$store.dispatch('agent/settings/writeToAgentBlob', { [this.blobKey]: arr });
      },
    },
  },
  created() {
    setTimeout(() => {
      if (this.inputToValue.length) {
        this.popoverShown = true;
      }
      setTimeout(() => {
        if (this.popoverShown) this.popoverShown = false;
      }, 5000);
    }, 1000);
  },
};
</script>
