<template>
  <b-button
    v-b-tooltip="$options.tooltipOptions"
    :variant="btnVariant"
    class="mr-1"
    :title="btnStatus.status"
    @click="$emit('open-modal', chatId)"
  >
    <font-awesome-icon
      :icon="btnStatus.icon"
      class="status-icon"
    />
    <span>
      {{ trimmedName }}
    </span>
    <span>
      ({{ unreadMsgsCount }})
    </span>
  </b-button>
</template>

<script>
import { tooltipOptions } from '@/utils/constants';
import { trimLength } from 'supwiz/supchat/generalUtils';

export default {
  name: 'ChatTab',
  tooltipOptions: {
    ...tooltipOptions,
    placement: 'top',
  },
  props: {
    chatDetails: {
      type: Object,
      required: true,
    },
    isLongestWaiting: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    btnStatus() {
      let icon = 'user';
      const unreadMsgs = this.chatDetails.msgsUnseen;
      let status = this.$t(
        unreadMsgs === 1 ? 'status.userUnreadMsg' : 'status.userUnreadMsgs',
        { number: unreadMsgs },
      );

      /*
        these icons might not be good enough
        but it was the best I could come up with that was somewhat consistent looking
      */
      if (this.isChatEnded) {
        icon = 'user-xmark';
        status = this.$t('status.userClosed', { name: this.chatDetails.display_name });
      } else if (this.hasVisitorLeftPage) {
        icon = 'user-minus';
        status = this.$t('status.userLeftPage', { name: this.chatDetails.display_name });
      }
      return {
        icon,
        status,
      };
    },
    chatId() {
      return this.chatDetails.id;
    },
    btnVariant() {
      if (this.isLongestWaiting) return 'primary';
      if (this.chatDetails.msgsUnseen > 0) return 'warning';
      return 'secondary';
    },
    isChatEnded() {
      return this.$store.getters['chat/hasChatBeenClosed'](this.chatId);
    },
    hasVisitorLeftPage() {
      return this.$store.getters['chat/hasVisitorLeftPage'](this.chatId);
    },
    trimmedName() {
      return trimLength(this.chatDetails.display_name);
    },
    unreadMsgsCount() {
      return this.chatDetails.msgsUnseen;
    },
  },
  methods: {
    trimLength,
  },
};
</script>

<style>
.status-icon {
  opacity: .65;
}
</style>
