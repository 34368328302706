/*
  How to use:
  Import this composable like so
  import { useCurrentTime } from '@/composables/currentTime';

  In components:
    Because our components are using api, we must declare currentTime in the setup function
    setup() {
      const currentTime = useCurrentTime('componentName');
      return { currentTime };
    },
    Now this.currentTime can be used throughout the component
    'componentName' is used to keep track of where the composable is used so we can
    safely clear the interval when it's not needed

  In stores:
    This isn't really supported as far as I can tell.
    But an unconventional way is to import currentTime directly which is why it's exported.
    Be careful as this will not trigger the interval if isn't running already!
    import { currentTime } from '@/composables/currentTime';

  Everywhere else:
    Declare currentTime
    const currentTime = useCurrentTime('identifierOfSomeKind');

    currentTime is a ref so the actual value is stored on the "value" key e.g.,
    const reactiveValue = currentTime.value;

*/
import { ref, onMounted, onUnmounted } from 'vue';

let interval = null; // holds the interval

export const currentTime = ref(Date.now()); // holds current time, exported for use in stores
const composableUsage = ref(new Set()); // usage overview

function updateTime() { currentTime.value = Date.now(); }

// recommended composable pattern
// eslint-disable-next-line import/prefer-default-export
export function useCurrentTime(componentName = '') {
  if (!componentName) throw new Error('Missing argument for useCurrentTime');
  // add componentName to usage Set
  // then start interval if not started already
  onMounted(() => {
    composableUsage.value.add(componentName);
    if (interval !== null) return;
    interval = setInterval(updateTime, 1_000);
  });

  // stop interval when file is unmounted, i.e. clean up
  onUnmounted(() => {
    composableUsage.value.delete(componentName);
    if (interval === null) return;

    // still in use elsewhere, do nothing
    if (composableUsage.value.size > 0) return;
    clearInterval(interval);
    interval = null;
  });

  return currentTime;
}
