<template>
  <div class="position-relative overflow-hidden ease-in-out">
    <b-textarea
      v-if="displayTextarea"
      v-bind="$attrs"
      ref="input"
      :class="[{
        'input-padding': displayBtn,
      }, 'scrollbar-slim', inputClass]"
      rows="1"
      max-rows="2"
      v-on="$listeners"
      @focus="inputInFocus = true"
      @blur="inputInFocus = false"
      @mouseover="inputUnderMouse = true"
      @mouseleave="inputUnderMouse = false"
    >
      {{ $attrs.value }}
    </b-textarea>
    <b-form-input
      v-else
      v-bind="$attrs"
      ref="input"
      :class="[{ 'input-padding': displayBtn }, inputClass]"
      v-on="$listeners"
      @focus="inputInFocus = true"
      @blur="inputInFocus = false"
      @mouseover="inputUnderMouse = true"
      @mouseleave="inputUnderMouse = false"
    />
    <smart-b-button
      class="copy-btn position-absolute mr-1"
      :class="{ display: displayBtn }"
      :variant="buttonObj.variant"
      size="sm"
      :busy="busy"
      @focus="buttonInFocus = true"
      @blur="buttonInFocus = false"
      @mouseover="buttonUnderMouse = true"
      @mouseleave="buttonUnderMouse = false"
      @click="copyValue"
    >
      {{ buttonObj.text }}
    </smart-b-button>
  </div>
</template>
<script>
import copy from 'clipboard-copy';

export default {
  name: 'InputFieldCopyable',
  inheritAttrs: false,
  props: {
    inputClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      busy: false,
      inputInFocus: false,
      inputUnderMouse: false,
      buttonInFocus: false,
      buttonUnderMouse: false,
      copySuccess: null,
    };
  },
  computed: {
    displayTextarea() {
      return this.$attrs.value?.length >= 30;
    },
    displayBtn() {
      return [
        this.inputInFocus,
        this.inputUnderMouse,
        this.buttonInFocus,
        this.buttonUnderMouse,
      ].includes(true);
    },
    buttonObj() {
      let text = '';
      let variant = 'outline-';
      if (this.copySuccess === false) {
        text = this.$t('vocabulary.error');
        variant += 'danger';
      } else if (this.copySuccess === true) {
        text = this.$t('vocabulary.copied');
        variant += 'success';
      } else {
        text = this.$t('vocabulary.copy');
        variant += 'primary';
      }
      return {
        text,
        variant,
      };
    },
  },
  watch: {
    displayTextarea() {
      this.$nextTick(() => {
        this.$refs.input?.focus();
      });
    },
  },
  methods: {
    async copyValue() {
      const value = this.$attrs.value;
      try {
        this.busy = true;
        await copy(value);
        this.copySuccess = true;
      } catch (error) {
        this.$log.error(error);
        this.copySuccess = false;
      } finally {
        this.busy = false;
        setTimeout(() => { this.copySuccess = null; }, 2500);
      }
    },
  },
};
</script>

<style scoped>
.input-padding {
  /* adds padding at the end of the input so the copy btn does not overlap value */
  padding-right: 3.85rem;
}
.copy-btn {
  right: 0;
  top: 50%;
  transform: translate(5%, -50%);
  opacity:0;
  transition: all .3s ease;
}
.copy-btn.display {
  opacity:1;
  transform: translate(0%, -50%);
}
</style>
