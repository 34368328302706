import Vue from 'vue';
import { sortBy } from 'lodash';
import { waitUntil } from 'supwiz/supchat/generalUtils';
import {
  getIntegrationSecrets,
  addIntegrationSecret,
  updateIntegrationSecret,
  deleteIntegrationSecret,
} from '@/api/apiList';

const secretsState = {
  secrets: {},
  fetchingState: {},
};

const secretsGetters = {
  getSecrets: (state) => (tenantId) => sortBy(
    Object.values(state.secrets?.[tenantId] || {}),
    [({ name }) => `${name}`.toLowerCase()],
  ),
  secretsReady: (state) => !state.fetchingState.secret,
  secretTypes: () => [
    { value: 'simple', text: 'Simple' },
    { value: 'basicauth', text: 'Basicauth' },
    { value: 'customprefix', text: 'Customprefix' },
  ],
};

const mutations = {
  SET_FETCHING_STATE(state, { id, isFetching }) {
    Vue.set(state.fetchingState, id, isFetching);
  },
  ADD_SECRET(state, secret) {
    if (!(secret.tenant_id in state.secrets)) Vue.set(state.secrets, secret.tenant_id, {});
    Vue.set(state.secrets[secret.tenant_id], secret.id, Object.freeze(secret));
  },
  DELETE_SECRET(state, { tenantId, secretId }) {
    Vue.delete(state.secrets[tenantId], secretId);
  },
};

const actions = {
  async fetchSecrets({ state, commit }, secretId = '') {
    const taskId = `secret${secretId}`;
    if (state.fetchingState[taskId]) {
      await waitUntil(() => !state.fetchingState[taskId]);
    } else {
      try {
        commit('SET_FETCHING_STATE', { id: taskId, isFetching: true });
        const secrets = await getIntegrationSecrets(secretId);
        secrets.forEach((secret) => {
          commit('ADD_SECRET', secret);
        });
        commit('SET_FETCHING_STATE', { id: taskId, isFetching: false });
      } catch (error) {
        Vue.$log.error(error);
        throw error;
      }
    }
  },
  async addSecret({ state, commit }, {
    name, type, secret, prefix, tenantId, username, password,
  }) {
    const taskId = `secret${tenantId}${name}add`;
    if (state.fetchingState[taskId]) {
      await waitUntil(() => !state.fetchingState[taskId]);
    } else {
      try {
        commit('SET_FETCHING_STATE', { id: taskId, isFetching: true });
        const secretPayload = {
          name,
          type,
          tenant_id: tenantId,
        };
        if (type === 'basicauth') {
          secretPayload.username = username;
          secretPayload.password = password;
        } else {
          secretPayload.secret = secret;
        }
        if (type === 'customprefix') secretPayload.prefix = prefix;
        const addedSecret = await addIntegrationSecret(secretPayload);
        commit('ADD_SECRET', addedSecret);
        commit('SET_FETCHING_STATE', { id: taskId, isFetching: false });
      } catch (error) {
        Vue.$log.error(error);
        throw error;
      }
    }
  },
  async updateSecret({ state, commit }, {
    name, type, secret, prefix, id, username, password,
  }) {
    const taskId = `secret${id}update`;
    if (state.fetchingState[taskId]) {
      await waitUntil(() => !state.fetchingState[taskId]);
    } else {
      try {
        commit('SET_FETCHING_STATE', { id: taskId, isFetching: true });
        const secretPayload = {
          name,
          type,
        };
        if (type === 'basicauth') {
          secretPayload.username = username;
          secretPayload.password = password;
        } else {
          secretPayload.secret = secret;
        }
        if (type === 'customprefix') secretPayload.prefix = prefix;
        const updatedSecret = await updateIntegrationSecret(id, secretPayload);
        commit('ADD_SECRET', updatedSecret);
        commit('SET_FETCHING_STATE', { id: taskId, isFetching: false });
      } catch (error) {
        Vue.$log.error(error);
        throw error;
      }
    }
  },
  async deleteSecret({ state, commit }, { tenant_id: tenantId, id: secretId }) {
    const taskId = `secret${secretId}delete`;
    if (state.fetchingState[taskId]) {
      await waitUntil(() => !state.fetchingState[taskId]);
    } else {
      try {
        commit('SET_FETCHING_STATE', { id: taskId, isFetching: true });
        await deleteIntegrationSecret(secretId);
        commit('DELETE_SECRET', { tenantId, secretId });
        commit('SET_FETCHING_STATE', { id: taskId, isFetching: false });
      } catch (error) {
        Vue.$log.error(error);
        throw error;
      }
    }
  },
};

export default {
  namespaced: true,
  state: secretsState,
  getters: secretsGetters,
  mutations,
  actions,
};
