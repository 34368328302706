<template>
  <div>
    <template v-for="(value, key) in metaData">
      <b-form-group
        v-if="![
          'site_location',
          'device',
          'ms_teams_context',
          'tenant_id',
          'tenant_name'].includes(key)"
        :key="key"
        :label="MetaDataNameFormatter(key)"
        :label-for="`${key}_label`"
        label-class="mb-0 pb-0"
        label-size="sm"
        class="mb-2"
      >
        <InputFieldCopyable
          :id="`${key}_label`"
          :value="(typeof value === 'object') ? JSON.stringify(value) : value"
          type="text"
          readonly
        />
      </b-form-group>
    </template>
    <b-form-group
      v-for="(value, key) in customMetaData"
      :key="key"
      :label="MetaDataNameFormatter(key)"
      :label-for="`${key}_label`"
      label-class="mb-0 pb-0"
      label-size="sm"
      class="mb-2"
    >
      <InputText
        v-if="customFields[key].type === 'text'"
        :id="`${key}_label`"
        :value="customMetaData[key]"
        :disabled="customFields[key].disabled"
        :predictions="predictedMetaData[key]"
        :state="nullOrFalse(!$v.customMetaData[key].$invalid)"
        class="flex-fill"
        type="text"
        @input="updateCustomMetaData(key, $event)"
      />
      <InputSelect
        v-else-if="customFields[key].type === 'select'"
        :id="`${key}_label`"
        class="flex-fill"
        :field-name="key"
        :tenant-id="tenantId"
        :value="customMetaData[key]"
        :options="customFields[key].options"
        :predictions="predictedMetaData[key]"
        :state="nullOrFalse(!$v.customMetaData[key].$invalid)"
        :disabled="customFields[key].disabled"
        @input="updateCustomMetaData(key, $event)"
      />
      <b-form-checkbox
        v-else-if="customFields[key].type === 'checkbox'"
        :checked="customMetaData[key]"
        :disabled="customFields[key].disabled"
        @input="updateCustomMetaData(key, $event)"
      />
      <div
        v-if="customFields[key].type === 'link'"
        :id="`${key}_label`"
        class="shadow-none form-control text-truncate"
      >
        <a
          :href="customMetaData[key]"
          target="_blank"
          rel="noopener noreferrer"
        >{{ customMetaData[key] }}</a>
      </div>
      <b-form-invalid-feedback>{{ $t('message.chatModal.fieldRequired') }}</b-form-invalid-feedback>
    </b-form-group>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { requiredIf } from 'vuelidate/lib/validators';
import { mapActions, mapGetters } from 'vuex';

import { waitUntil } from 'supwiz/supchat/generalUtils';
import { cloneDeep, debounce } from 'lodash';
import { getVisitorInfoOfChat } from '@/api/apiList';
import { tooltipOptions } from '@/utils/constants';

import InputFieldCopyable from '@/components/InputFieldCopyable.vue';
import InputSelect from './Metadata/InputSelect.vue';
import InputText from './Metadata/InputText.vue';

export default {
  name: 'VisitorMetaData',
  components: { InputFieldCopyable, InputSelect, InputText },
  mixins: [validationMixin],
  tooltipOptions: {
    ...tooltipOptions,
    placement: 'left',
  },
  props: {
    tenantId: {
      required: true,
      type: String,
    },
    chatId: {
      required: true,
      type: String,
    },
    config: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      metaData: {},
      customMetaData: {},
      fetchingVisitorInfo: false,
      updateCustomMetaDebounce: null,
    };
  },
  computed: {
    ...mapGetters('controlSocket', ['isVisitorMetaDataRefresh']),
    ...mapGetters('agent', ['maxRole']),
    ...mapGetters('chat/chatModals', ['getPredictedMetadata']),
    customFields() {
      const fields = cloneDeep(this.config.custom_visitor_info);
      for (const field of fields) {
        field.disabled = !this.isAdmin && field.protected;
        if (field.type !== 'select') {
          continue;
        }
        for (const option of field.options) {
          if (option.label === null) {
            option.label = option.value;
          }
        }
      }
      return this.array2dict(fields, 'name');
    },
    refreshMetaDataRequired() {
      const value = this.isVisitorMetaDataRefresh(this.chatId);
      return value;
    },
    isAdmin() {
      return this.maxRole === 3;
    },
    readyState() {
      return !this.$v.$invalid;
    },
    predictedMetaData() {
      return this.getPredictedMetadata(this.chatId);
    },
  },
  watch: {
    metaData(val) {
      this.$emit('updateMetaData', val);
    },
    readyState: {
      immediate: true,
      handler(val) {
        this.$emit('ready', val);
      },
    },
    refreshMetaDataRequired(shouldRefresh) {
      if (shouldRefresh) this.fetchVisitorInfo();
    },
    chatId(newVal, oldVal) {
      this.removeRefreshStatus({ key: 'visitorMetaData', chatId: oldVal });
      this.updateRefreshStatus({ key: 'visitorMetaData', value: false, chatId: this.chatId });
    },
  },
  async created() {
    this.updateCustomMetaDebounce = debounce(() => {
      this.$emit('updateCustomMetaData', this.customMetaData);
    }, 1000);
    await this.ensureControlSocketSet();
    await this.fetchVisitorInfo();
    this.updateCustomMetaDebounce();
    this.$root.$on(`${this.chatId}-metaP-selected`, this.updateCustomMetaData);
  },
  beforeDestroy() {
    this.removeRefreshStatus({ key: 'visitorMetaData', chatId: this.chatId });
  },
  methods: {
    ...mapActions('controlSocket',
      ['ensureControlSocketSet', 'updateRefreshStatus', 'removeRefreshStatus']),
    async fetchVisitorInfo() {
      if (this.fetchingVisitorInfo) {
        await waitUntil(() => !this.fetchingVisitorInfo);
      } else {
        try {
          this.fetchingVisitorInfo = true;
          const visitorInfo = await getVisitorInfoOfChat(this.chatId);
          this.updateRefreshStatus({ key: 'visitorMetaData', value: false, chatId: this.chatId });
          /* hard-code for skip the question field and separate meta data and custom meta data */
          for (const [key, value] of Object.entries(visitorInfo)) {
            if (this.config.custom_visitor_info
              && this.config.custom_visitor_info.findIndex((x) => x.name === key) !== -1) {
              this.$set(this.customMetaData, key, value);
            } else if (key === 'question') {
              continue;
            } else {
              this.$set(this.metaData, key, value);
            }
          }
          this.prepareRestCustomVisitorInfo();
        } catch (error) {
          this.$log.error(error);
        }
        this.fetchingVisitorInfo = false;
      }
    },
    array2dict(array, key) {
      return array.reduce((acc, cur) => {
        // eslint-disable-next-line no-param-reassign
        acc[cur[key]] = cur;
        return acc;
      }, {});
    },
    MetaDataNameFormatter(s) {
      const t = s.replace('_', ' ');
      return t.charAt(0).toUpperCase() + t.slice(1);
    },
    prepareRestCustomVisitorInfo() {
      if (this.config.custom_visitor_info) {
        for (let i = 0; i < this.config.custom_visitor_info.length; i++) {
          const key = this.config.custom_visitor_info[i];
          if (!Object.prototype.hasOwnProperty.call(this.customMetaData, key.name)) {
            if (key.type === 'checkbox') {
              this.$set(this.customMetaData, key.name, false);
            } else {
              this.$set(this.customMetaData, key.name, '');
            }
          }
        }
      }
    },
    nullOrFalse(value) {
      return value ? null : false;
    },
    updateCustomMetaData(key, value) {
      if (this.customMetaData?.[key] === value) return;
      this.$set(this.customMetaData, key, value);
      this.updateCustomMetaDebounce();
    },
  },
  validations() {
    const fields = Object.keys(this.customMetaData);
    const fieldValids = {};
    for (const field of fields) {
      fieldValids[field] = {
        required: requiredIf(() => this.customFields[field].required),
      };
    }

    return {
      customMetaData: fieldValids,
    };
  },
};
</script>

<style scoped>
:deep(.input-group-prepend .btn-primary svg path),
:deep(.input-group-prepend .btn-success svg path) {
  fill: #fff!important;
}
</style>
