import Vue from 'vue';
import { SUPCHAT_API } from 'supwiz/supchat/httpRequest';

import {
  reconnectIntervals,
  pingFrequency,
  pingMaxDiff,
} from 'supwiz/supchat/constants';

const EVENT = {
  AUTO_ASSIGN: 'auto_assign',
  END_CHAT: 'end_chat',
  NEW_AGENT_STATUSES: 'new_agent_statuses',
  NEW_MESSAGE: 'new_message',
  NEW_VISITOR_META: 'new_visitor_meta',
  PICKUP_CHAT: 'pickup_chat',
  PING: 'ping',
  PONG: 'pong',
  REQUEST_CHAT: 'request_chat',
  TRANSFER_CHAT: 'transfer_chat',
};

// ping pong
let pingInterval = null;
let pingLastSent = Date.now() / 1000;
let pingLastConfirmed = Date.now() / 1000;

const controlSocketState = {
  controlSocket: null,
  /*
   controlSocketStatus
   0 = Connecting
   1 = Open
   2 = Closing
   3 = Closed
  */
  controlSocketStatus: null,
  refreshStatus: {
    incoming: false,
    chatModal: false,
    visitorMetaData: {},
  }, // flag to decide whether to refresh queue or refresh my chats on the given page
  reconnectAttempts: 0,
};

const getters = {
  isIncomingPageQueueRefresh: (state) => state.refreshStatus.incoming,
  isChatModalChatRefresh: (state) => state.refreshStatus.chatModal,
  isVisitorMetaDataRefresh: (state) => (chatId) => state.refreshStatus.visitorMetaData[chatId],
};

const mutations = {
  SET_CONTROL_SOCKET(state, payload) {
    state.controlSocket = payload;
  },
  SET_CONTROL_SOCKET_STATUS(state, status) {
    state.controlSocketStatus = status;
  },
  REMOVE_REFRESH_STATUS(state, { key, chatId }) {
    if (Object.prototype.hasOwnProperty.call(state.refreshStatus[key],
      chatId)) {
      Vue.delete(state.refreshStatus[key], chatId);
    }
  },

  UPDATE_REFRESH_STATUS(state, { key, value, chatId }) {
    let stateVar = state.refreshStatus;
    let stateKey = key;
    if (Object.prototype.hasOwnProperty.call(state.refreshStatus, key)
      && typeof value === 'boolean') {
      if (chatId !== undefined && typeof state.refreshStatus[key] === 'object') {
        // Chat-specific state
        stateVar = state.refreshStatus[key];
        stateKey = chatId;
      }
      if (value && !Object.prototype.hasOwnProperty.call(stateVar, stateKey)) {
        // Only accept updates on states that are already defined
        return;
      }
      Vue.set(stateVar, stateKey, value);
    }
  },
  CLOSE_WEBSOCKET(state) {
    if (state.controlSocket && [0, 1].includes(state.controlSocket.readyState)) {
      state.controlSocket.close();
    }
  },
  RECONNECT_ATTEMPT_CHANGE(state, { reset }) {
    if (reset) state.reconnectAttempts = 0;
    else state.reconnectAttempts += 1;
  },
  START_PINGING(state) {
    // check if interval is already running
    if (pingInterval !== null) return;

    // set new starting point for last pings
    pingLastSent = Date.now() / 1000;
    pingLastConfirmed = Date.now() / 1000;

    if (pingInterval !== null) return;
    pingInterval = setInterval(() => {
      const socket = state.controlSocket;
      // socket is not open so do nothing
      if (socket?.readyState !== 1) return;

      // check time between now and last confirmed ping
      // if greater than X seconds then force close websocket which will begin reconnect flow
      const time = Date.now() / 1000;
      if (pingLastSent - pingLastConfirmed >= pingMaxDiff) {
        // possibly disconnected, try to close socket
        socket?.close();
        return;
      }

      // everything is in order, send new ping message
      pingLastSent = time;
      const msgString = JSON.stringify({
        type: EVENT.PING,
        time,
      });
      socket.send(msgString);
    }, pingFrequency);
  },
  STOP_PINGING() {
    if (pingInterval === null) return;
    clearInterval(pingInterval);
    pingInterval = null;
  },
};

const actions = {
  setupControlSocket({
    commit, state, dispatch, rootState,
  }) {
    /* Websocket initialization */
    const socketProtocol = window.location.protocol === 'https:' ? 'wss://' : 'ws://';
    const controlSocket = new WebSocket(`${socketProtocol + SUPCHAT_API}/ws/control/`);

    /* Setup event functions, onopen, onclose, onmessage */
    controlSocket.onopen = () => {
      commit('SET_CONTROL_SOCKET_STATUS', controlSocket.readyState);
      commit('RECONNECT_ATTEMPT_CHANGE', { reset: true });
      commit('START_PINGING');
    };
    controlSocket.onmessage = (e) => {
      commit('SET_CONTROL_SOCKET_STATUS', controlSocket.readyState);
      dispatch('onMessage', e);
    };
    controlSocket.onerror = (e) => {
      commit('SET_CONTROL_SOCKET_STATUS', controlSocket.readyState);
      Vue.$log.error(e);
    };
    controlSocket.onclose = () => {
      commit('SET_CONTROL_SOCKET_STATUS', controlSocket.readyState);
      commit('STOP_PINGING');
      if (!rootState.agent.isLoggedIn) return;
      if (state.reconnectAttempts >= reconnectIntervals.length - 1) {
        return;
      }
      const delay = reconnectIntervals[state.reconnectAttempts];
      setTimeout(() => dispatch('setupControlSocket'), delay * 1000);
      commit('RECONNECT_ATTEMPT_CHANGE', { reset: false });
    };

    commit('SET_CONTROL_SOCKET', controlSocket);
    return controlSocket;
  },
  onMessage({ commit, dispatch, rootGetters }, e) {
    const event = JSON.parse(e.data);
    const type = event.type;
    const chatId = event?.chat_id;
    Vue.$log.info(event);
    switch (type) {
      case EVENT.END_CHAT:
        // remove chat from incoming or ongoing
        dispatch('chat/handleChatUpdate', { chatId, task: 'delete' }, { root: true });
        break;
      case EVENT.TRANSFER_CHAT:
        // a TRANSFER_CHAT event will occur for ongoing chats. We delete it from ongoing
        // and ask for an incoming chats update asap
        dispatch('chat/handleChatUpdate', { chatId, task: 'delete' }, { root: true });
        commit('chat/SET_AWAITING_INCOMING_CHATS_REFRESH', true, { root: true });
        break;
      case EVENT.PICKUP_CHAT:
        // move chat from incoming or ongoing
        dispatch('chat/handleChatUpdate', { chatId, task: 'move' }, { root: true });
        commit('chat/SET_AWAITING_ONGOING_CHATS_REFRESH', true, { root: true });
        commit('UPDATE_REFRESH_STATUS', { key: 'chatModal', value: true });
        break;
      case EVENT.REQUEST_CHAT:
        // New incoming chat. Let incoming chats store know that we want an update asap
        commit('chat/SET_AWAITING_INCOMING_CHATS_REFRESH', true, { root: true });
        break;
      case EVENT.NEW_MESSAGE: {
        // update logs for single chat
        dispatch('chat/handleChatUpdate', { chatId, task: 'update' }, { root: true });
        // update chatmodal if the chat is ours
        const myChatIds = rootGetters['chat/chatModals/chatModalIds'];
        if (myChatIds?.includes(chatId)) {
          commit('UPDATE_REFRESH_STATUS', { key: 'chatModal', value: true });
        }
        break;
      }
      case EVENT.NEW_AGENT_STATUSES:
        dispatch('status/getStatusOverview', true, { root: true });
        break;
      case EVENT.NEW_VISITOR_META:
        commit('UPDATE_REFRESH_STATUS', { key: 'visitorMetaData', value: true, chatId });
        break;
      case EVENT.AUTO_ASSIGN:
        // auto assign
        dispatch('chat/handleAutoAssign', event, { root: true });
        break;
      case EVENT.PONG:
        pingLastConfirmed = parseFloat(event.time);
        break;
      default:
        break;
    }
  },

  async ensureControlSocketSet({ state, dispatch }) {
    if (state.controlSocket === null || [2, 3].includes(state.controlSocket.readyState)) {
      return dispatch('setupControlSocket');
    }
    return state.controlSocket;
  },

  removeRefreshStatus({ commit }, { key, chatId }) {
    commit('REMOVE_REFRESH_STATUS', { key, chatId });
  },

  updateRefreshStatus({ commit }, { key, value, chatId }) {
    commit('UPDATE_REFRESH_STATUS', { key, value, chatId });
  },
  closeWebSocket({ commit }) {
    commit('CLOSE_WEBSOCKET');
  },
};

export default {
  namespaced: true,
  state: controlSocketState,
  getters,
  mutations,
  actions,
};
