<template>
  <div>
    <chat-modal
      v-for="chat in chats"
      ref="chatModal"
      :key="chat.id"
      :auto-open="autoOpenChatId === chat.id"
      :chat="chat"
      @msgsUnseenChanged="onMsgsUnseenChanged"
      @answered-chat="removeChatFromAnswerQueue"
      @show-me="showModal"
      @remove-chat="removeChat"
    />

    <b-button-group
      ref="buttonGroup"
      :style="{ position: 'fixed', bottom: 0, zIndex: 2000 }"
    >
      <draggable v-model="activeChatIds">
        <ChatTab
          v-for="chatId in activeChatIds"
          :key="chatId"
          :chat-details="chats[chatId]"
          :is-longest-waiting="chatsAwaitingAnswer[0] === chatId && activeChatIds.length > 1"
          @open-modal="showModal"
        />
      </draggable>
    </b-button-group>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { mapActions, mapGetters } from 'vuex';
import ChatModal from '@/components/chatModal/ChatModal.vue';
import ChatTab from '@/components/chatModal/ChatTab.vue';
import { getMyChats } from '@/api/apiList';
import { myChatsFetchDelay, myChatsFetchRetries } from '@/utils/constants';
import { useCurrentTime } from '@/composables/currentTime';

export default {
  name: 'ChatInterface',
  components: {
    ChatModal,
    ChatTab,
    draggable,
  },
  setup() {
    // we don't need this here, but our incoming and ongoingChats stores do and
    // they'll need it whenever the chat component is available. So we import here
    // to ensure that currentTime is kept up-to-date.
    const currentTime = useCurrentTime('ChatInterface');
    return { currentTime };
  },
  data() {
    return {
      chats: {},
      activeChatIds: [],
      autoOpenChatId: null,
      chatsAwaitingAnswer: [],
      myChatsFetchTimeout: null,
      failedFetches: 0,
    };
  },
  computed: {
    ...mapGetters('controlSocket', { refreshMyChats: 'isChatModalChatRefresh' }),
    ...mapGetters('chat', ['myOngoingChatIDs']),
    myChatIds() {
      return Object.keys(this.chats);
    },
  },
  watch: {
    myChatIds() {
      /*
        Check if the chat ids are already in our chatList
      */
      const newChatIds = this.myChatIds
        .filter((chatId) => (!this.activeChatIds.includes(chatId)));

      // If any are left, that means we've got a new chat and we should add it to activeChatIds
      newChatIds.forEach((chatId) => {
        this.activeChatIds.push(chatId);
      });
    },
    refreshMyChats(newVal) {
      if (newVal) {
        // immediately set back to false to allow new refreshes
        this.updateRefreshStatus({ key: 'chatModal', value: false });
        // now fetch my chats
        this.fetchMyChats();
      }
    },
    myOngoingChatIDs(value) {
      if (!value?.length) return;
      // check if component myChatIds matches store myOngoingChatIDs
      if (value.every((id) => this.myChatIds.includes(id))) return;
      // otherwise, ask for a refresh
      this.updateRefreshStatus({ key: 'chatModal', value: true });
    },
  },
  created() {
    this.ensureControlSocketSet();

    this.updateRefreshStatus({ key: 'chatModal', value: false }); // initialize the refresh flag
    this.fetchMyChats(true);
  },
  methods: {
    ...mapActions('controlSocket', ['ensureControlSocketSet', 'updateRefreshStatus']),
    ...mapActions('chat/chatModals', ['deleteModalState']),

    getModal(chatId) {
      return this.$refs.chatModal.find((el) => el.chatId === chatId);
    },

    fetchMyChats(skipAutoOpenModal = false) {
      if (this.myChatsFetchTimeout !== null) return;
      this.myChatsFetchTimeout = setTimeout(() => {
        // `getMyChats` returns chats that the agent is a member of in addition to the ones we
        // explicitly request in the `activeChatIds` array. If we do not explicitly request
        // additional chats with `activeChatIds` AND one of the chats with an open modal is
        // closed due to inactivity that chat modal will be closed when we get a response from
        // `getMyChats` because it is no longer in redis. This approach to keeping chat modals open
        // for chats closed due to inactivity can probably be improved. For instance, the current
        // approach does not protect against the agent refreshing the dashboard. So if an agent has
        // a chat modal open with a chat that is closed due to inactivity that chat modal will close
        // when the agent refreshes the dashboard because the chat will no longer be present in
        // `this.chats` upon reloading the page.
        const chatsString = this.activeChatIds.join();
        getMyChats(chatsString).then((data) => {
          this.$log.debug('Found active chats:', data.chats);
          const fetchedChats = data.chats;

          // update the information about how many msg has seen for a chat
          const fetchedChatIds = Object.keys(fetchedChats);
          for (const chatId of fetchedChatIds) {
            if (this.myChatIds.includes(chatId)) {
              fetchedChats[chatId].msgsUnseen = this.chats[chatId].msgsUnseen;
            } else {
              fetchedChats[chatId].msgsUnseen = 0;
            }
          }
          // check for new chat. We can only open 1 modal at a time so we just grab the first id
          if (!skipAutoOpenModal) {
            const newChatIds = fetchedChatIds.filter((x) => !this.myChatIds.includes(x));
            this.autoOpenChatId = newChatIds[0];
          }
          // update chat data
          this.chats = fetchedChats;
          this.failedFetches = 0;
          this.myChatsFetchTimeout = null; // re-allows future fetches once this fetch is done
        }).catch((err) => {
          this.$log.error(err);
          // if it fails, we need to try again unless we have already tried too many times
          if (this.failedFetches < myChatsFetchRetries) {
            this.updateRefreshStatus({ key: 'chatModal', value: true });
            this.failedFetches++;
          } else {
            // we tried too many times so we display an error to the user
            // the long timer makes the error remain on the screen so the agent won't miss it
            this.$store.commit('errorDisplay/ADD_MSG', {
              message: this.$t('errors.failedToFetchMyChats'),
              timer: 9999,
            });
          }
        });
      }, myChatsFetchDelay);
    },
    async showModal(chatId) {
      const modal = this.getModal(chatId);
      modal.$refs.modal.show();
    },
    onMsgsUnseenChanged(msgsUnseen, chatId) {
      this.chats[chatId].msgsUnseen = msgsUnseen;
      if (msgsUnseen > 0) {
        this.setLongestWaiting(chatId);
      }
    },
    setLongestWaiting(chatId) {
      const chatIndex = this.chatsAwaitingAnswer.indexOf(chatId);
      if (chatId === this.chatsAwaitingAnswer[0]) {
        this.chatsAwaitingAnswer.splice(0, 1);
      } else {
        this.chatsAwaitingAnswer.splice(chatIndex, chatIndex + 1);
      }
      this.chatsAwaitingAnswer.push(chatId);
    },
    removeChatFromAnswerQueue(chatId) {
      this.chatsAwaitingAnswer = this.chatsAwaitingAnswer
        .filter((chat) => !chatId.includes(chat));
    },
    removeChat(chatId) {
      this.removeChatFromAnswerQueue(chatId);
      const indexInActiveChatIds = this.activeChatIds.indexOf(chatId);
      if (indexInActiveChatIds >= 0) this.$delete(this.activeChatIds, indexInActiveChatIds);
      if (this.autoOpenChatId === chatId) this.autoOpenChatId = null;
      this.$delete(this.chats, chatId);
      this.deleteModalState(chatId);
    },
  },
};
</script>

<style scoped>
.bg-longestwaiting {
  background-color: rgb(255, 100, 100) !important;
}
</style>
