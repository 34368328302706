import { i18n } from '@/localization';
import { agentStatusOverviewColumns } from '@/utils/constants';
import { waitUntil } from 'supwiz/supchat/generalUtils';
import {
  getAgentOverviews,
  createAgentOverviews,
  updateAgentOverviews,
  deleteAgentOverviews,
} from '@/api/apiList';

function toSnakeCase({
  id = undefined, title, tenantId, agentIds,
  startClock, endClock, useLocalTime, columns, sortBy, sortByOrder,
}) {
  return {
    id,
    title,
    tenant_id: tenantId,
    agent_ids: agentIds,
    start_clock: startClock,
    end_clock: endClock,
    use_local_time: useLocalTime,
    columns,
    sort_by: sortBy,
    sort_by_order: sortByOrder,
  };
}

function toCamelCase({
  id = undefined,
  title,
  tenant_id: tenantId,
  agent_ids: agentIds,
  start_clock: startClock,
  end_clock: endClock,
  use_local_time: useLocalTime,
  columns,
  sort_by: sortBy,
  sort_by_order: sortByOrder,
}) {
  return {
    id,
    title,
    tenantId,
    agentIds,
    startClock,
    endClock,
    useLocalTime,
    columns,
    sortBy,
    sortByOrder,
  };
}

const overviewState = {
  overviews: [],
  // simple queue for data fetches as analytics endpoint only allows 1 fetch at a time
  fetchingQueue: [],
};

const overviewGetters = {
  getOverview: (state) => (overviewId) => state.overviews
    .find((overview) => overview.id === overviewId),
  agentStatusOverviewColumns: () => agentStatusOverviewColumns
    .map(({ key, label }) => ({
      key,
      label: i18n.t(label),
      sortable: true,
    })),
  agentStatusOverviewColumnsAsOptions: (state, getters) => getters.agentStatusOverviewColumns
    .map(({ key, label }) => ({
      value: key,
      text: label,
    })),

};
const mutations = {
  SET_OVERVIEW(state, overview) {
    const index = state.overviews.findIndex(({ id }) => overview.id === id);
    if (index !== -1) {
      state.overviews.splice(index, 1, toCamelCase(overview));
    } else {
      state.overviews.push(toCamelCase(overview));
    }
  },
  DELETE_OVERVIEW(state, overviewId) {
    const index = state.overviews.findIndex(({ id }) => id === overviewId);
    if (index !== -1) {
      state.overviews.splice(index, 1);
    }
  },
  ADD_FETCHING_QUEUE(state, overviewId) {
    state.fetchingQueue.push(overviewId);
  },
  SHIFT_FETCHING_QUEUE(state) {
    state.fetchingQueue.shift();
  },
};
const actions = {
  async fetchOverviews({ state, commit }, id = '') {
    if (state.overviews.length) return;
    const overviews = await getAgentOverviews(id);

    if (id) commit('SET_OVERVIEW', overviews);
    else overviews.forEach((overview) => commit('SET_OVERVIEW', overview));
  },
  async addOverview({ commit }, overview) {
    const addedOverview = await createAgentOverviews(toSnakeCase(overview));
    commit('SET_OVERVIEW', addedOverview);
  },
  async updateOverview({ commit }, overview) {
    const updatedOverview = await updateAgentOverviews(toSnakeCase(overview));
    commit('SET_OVERVIEW', updatedOverview);
  },
  async deleteOverview({ commit }, overviewId) {
    await deleteAgentOverviews(overviewId);
    commit('DELETE_OVERVIEW', overviewId);
  },
  async fetchAnalyticsData({ state, dispatch, commit }, { id, params }) {
    // push fetch to queue
    commit('ADD_FETCHING_QUEUE', id);
    // wait until we are first in queue
    if (state.fetchingQueue.indexOf(id) > 0) {
      await waitUntil(() => state.fetchingQueue.indexOf(id) === 0);
    }
    const result = await dispatch('analytics/fetchAnalyticsData', params, { root: true });
    // shift queue to allow next fetch
    commit('SHIFT_FETCHING_QUEUE');
    return result;
  },
};

export default {
  namespaced: true,
  state: overviewState,
  getters: overviewGetters,
  mutations,
  actions,
};
