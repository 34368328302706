import Vue from 'vue';

/* Error Template
{
  message: String (message to display to user, preferably translationKey.
    Will inject 'timer' if set),
  [type: String] (can be used to prevent spam.
    Example usage: SupChat has 2 websockets which will likely error at the same time
    so we can set type to websocket and then only display errors from
    one of the websockets),
  [variant: String] (bootstrap color variant),
  [timer: Number (seconds)],
}
*/

const errorsState = {
  errors: [],
};

const getters = {
  getErrors: (state) => state.errors,
};

const mutations = {
  ADD_MSG(state, error) {
    if (!error.type || !state.errors.some((e) => e.type === error.type)) {
      state.errors.push({ ...error, id: new Date().getMilliseconds() });
    }
  },
  REMOVE_MSG(state, { id }) {
    const index = state.errors.findIndex((error) => error.id === id);
    if (index !== -1) {
      state.errors.splice(index, 1);
    } else {
      Vue.$log.debug('Attempted to remove error that doesn\'t exist');
    }
  },
};

export default {
  namespaced: true,
  state: errorsState,
  getters,
  mutations,
};
