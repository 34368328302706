var render = function render(){var _vm=this,_c=_vm._self._c;return (!(
    (_vm.msg.command === _vm.chatEvent.WAITING_INFO
      || (_vm.msg.command === _vm.chatEvent.INACTIVITY_MESSAGE && _vm.msg.is_queue))
    && _vm.isCurrentlyPickedUp
  ))?_c('li',{staticClass:"d-flex flex-column align-items-center"},[(!(
      _vm.msg.command === _vm.chatEvent.WAITING_INFO
      && typeof _vm.msg.text === 'string'
      && _vm.hasBeenPickedUp
    ))?_c('span',{staticClass:"timestamp",attrs:{"aria-hidden":"true","title":new Date(_vm.msg.timestamp * 1000).toLocaleString()}},[_vm._v(" "+_vm._s(new Date(_vm.msg.timestamp * 1000).toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit', }))+" ")]):_vm._e(),(![
      _vm.msg.command === _vm.chatEvent.LEAVE,
      _vm.msg.sender_role === 'visitor',
      _vm.userRole === 'agent',
    ].includes(false))?[_c('span',{staticClass:"system-bubble",class:{ br: _vm.borderRadius }},[_vm._v(" "+_vm._s(_vm.$t('status.userLeftPage', { name: _vm.senderName }))+" ")])]:_vm._e(),(_vm.msg.command === _vm.chatEvent.INTERACTION)?[_c('span',{staticClass:"system-bubble",class:{ br: _vm.borderRadius },attrs:{"title":_vm.msg.value}},[_vm._v(" "+_vm._s(_vm.$t('status.userInteracted', { name: _vm.senderName, value: _vm.trimLength(_vm.msg.value, 50), }))+" ")])]:_vm._e(),(_vm.msg.text === 'start')?[_c('span',{staticClass:"system-bubble",class:{ br: _vm.borderRadius }},[_vm._v(" "+_vm._s(_vm.$t('status.userJoin', { name: _vm.senderName }))+" ")])]:_vm._e(),(_vm.msg.text === 'close')?[_c('span',{staticClass:"system-bubble",class:{ br: _vm.borderRadius }},[_vm._v(" "+_vm._s(_vm.msg.sender_role === 'system' ? _vm.$t('status.inactivityClosed') : _vm.$t('status.userClosed', { name: _vm.senderName }))+" ")])]:_vm._e(),(_vm.msg.text === 'stop')?[_c('span',{staticClass:"system-bubble",class:{ br: _vm.borderRadius }},[_vm._v(_vm._s(_vm.$t('status.userLeft', { name: _vm.senderName })))])]:_vm._e(),(_vm.msg.text === 'transfer')?[_c('span',{staticClass:"system-bubble",class:{ br: _vm.borderRadius }},[_vm._v(_vm._s(_vm.$tc('status.chatTransfer', _vm.userRole === 'agent' ? 1 : 2)))])]:_vm._e(),(_vm.msg.command === _vm.chatEvent.WAITING_INFO)?[(typeof (_vm.msg.text) === 'number')?_c('span',{staticClass:"system-bubble",class:{ br: _vm.borderRadius },domProps:{"innerHTML":_vm._s(_vm.$t('status.userQueuePos', { number: _vm.msg.text }))}}):_vm._e(),(typeof (_vm.msg.text) === 'string' && !_vm.hasBeenPickedUp)?_c('span',{staticClass:"system-bubble",class:{ br: _vm.borderRadius }},[_vm._v(" "+_vm._s(_vm.$t('status.findingAgent'))+" ")]):_vm._e()]:_vm._e(),(_vm.msg.command === _vm.chatEvent.INACTIVITY_MESSAGE)?[_c('span',{staticClass:"system-bubble",class:{ br: _vm.borderRadius }},[_vm._v(" "+_vm._s(_vm.msg.text)+" ")])]:_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }