import Vue from 'vue';
import { listIntegrations, deleteIntegration } from '@/api/apiList';

import secrets from './secrets';

const integrationsState = {
  integrationsReady: false,
  integrationIds: [],
  integrations: {},
  hasFetched: {},
};

const integrationGetters = {
  areIntegrationsReady: (state) => state.integrationsReady,
  allIntegrationObjects: (state) => state.integrationIds.map((id) => state.integrations[id]),
  integrationObjectsByTenant: (state) => (tenantId) => state
    .integrationIds.map((id) => state.integrations[id])
    .filter(({ tenant }) => tenant === tenantId),
  integrationById: (state) => (id) => state.integrations[id],
  hasFetchedTenant: (state) => (tenantId) => state.hasFetched[tenantId] === true,
};

const mutations = {
  SET_READY_STATE(state, { ready }) {
    state.integrationsReady = ready;
  },

  SET_INTEGRATIONS(state, { integrations, tenantId }) {
    if (!tenantId) {
      state.integrationIds = [];
      Vue.set(state, 'integrations', {});
    } else {
      const removedIntegrationIds = state.integrationIds
        .filter((id) => state.integrations[id].tenant === tenantId);
      state.integrationIds = state.integrationIds
        .filter((id) => state.integrations[id].tenant !== tenantId);
      for (const removedIntegrationId of removedIntegrationIds) {
        Vue.delete(state.integrations, removedIntegrationId);
      }
    }
    for (const integration of integrations) {
      state.integrationIds.push(integration.id);
      Vue.set(state.integrations, integration.id, {
        ...integration,
        requestType: integration.request_type,
      });
    }
  },

  DELETE_INTEGRATION(state, { integrationId }) {
    state.integrationIds = state.integrationIds.filter((id) => id !== integrationId);
    Vue.delete(state.integrations, integrationId);
  },

  SET_HAS_FETCHED(state, { tenantId }) {
    Vue.set(state.hasFetched, tenantId, true);
  },
};

const actions = {
  async fetchIntegrations({ commit }, { tenantId }) {
    commit('SET_READY_STATE', { ready: false });
    const fetchedIntegrations = await listIntegrations(tenantId);
    commit('SET_INTEGRATIONS', { integrations: fetchedIntegrations, tenantId });
    commit('SET_READY_STATE', { ready: true });
    if (tenantId) {
      commit('SET_HAS_FETCHED', { tenantId });
    }
    return true;
  },

  async ensureTenantIntegrationsFetched({ getters, dispatch }, { tenantId }) {
    if (!getters.hasFetchedTenant(tenantId)) {
      dispatch('fetchIntegrations', { tenantId });
    }
  },

  async deleteIntegration({ commit }, { integrationId }) {
    commit('SET_READY_STATE', { ready: false });
    const response = await deleteIntegration(integrationId);
    if (response.status && response.status >= 300) {
      commit('SET_READY_STATE', { ready: true });
      return false;
    }
    commit('DELETE_INTEGRATION', { integrationId });
    commit('SET_READY_STATE', { ready: true });
    return true;
  },
};

export default {
  namespaced: true,
  state: integrationsState,
  getters: integrationGetters,
  mutations,
  actions,
  modules: {
    secrets,
  },
};
