import { render, staticRenderFns } from "./SmartBButton.vue?vue&type=template&id=609e1938&scoped=true"
import script from "./SmartBButton.vue?vue&type=script&lang=js"
export * from "./SmartBButton.vue?vue&type=script&lang=js"
import style0 from "./SmartBButton.vue?vue&type=style&index=0&id=609e1938&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "609e1938",
  null
  
)

export default component.exports