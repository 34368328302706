import Vue from 'vue';

import { SUPCHAT_API } from 'supwiz/supchat/httpRequest';
import { notify, sendCommand } from 'supwiz/supchat/generalUtils';
import {
  chatEvent,
  reconnectIntervals,
  pingFrequency,
  pingMaxDiff,
} from 'supwiz/supchat/constants';

import { pickupChat } from '@/api/apiList';
import { i18n } from '../../../localization';

import incomingChats from './incomingChat';
import ongoingChats from './ongoingChats';
import chatModals from './chatModals';

// ping pong
let pingInterval = null;
let pingLastSent = Date.now() / 1000;
let pingLastConfirmed = Date.now() / 1000;

const chatState = {
  chatSocket: null,
  /*
   chatSocketStatus
   0 = Connecting
   1 = Open
   2 = Closing
   3 = Closed
  */
  chatSocketStatus: null,
  chatsHistory: {}, // chat id as key and history as value
  unAckMessages: {}, // chat id to not acknowledged message
  notificationFlag: {}, // chat id : integer (update of integer will trigger the notification)
  toNotifyMsg: {}, // chat id : List of msg
  reconnectAttempts: 0,
};

const chatGetters = {
  isChatCurrentlyJoined: (state, getters, rootState, rootGetters) => (chatId) => {
    const chatHistory = state.chatsHistory[chatId];
    if (chatHistory) {
      // Let's grab our ID
      const agentId = rootGetters['agent/id'];
      // Loop in reverse over the chat history
      for (let i = chatHistory.length - 1; i >= 0; i--) {
        const msg = chatHistory[i];
        if ([
          chatEvent.STATUS,
          chatEvent.JOIN,
        ].includes(msg.command) && msg.sender_id === `${agentId}`) {
          const isStatus = msg.command === chatEvent.STATUS;
          /*
            if we find a stop, close, or transfer message before we find a start message
            that means that we have not sent a start message
          */
          if (isStatus && ['stop', 'close', 'transfer'].includes(msg.text)) return false;
          /*
            if we find a join before (in reverse) we find a stop, close, or transfer
            then we have sent a join.
            NOTE: this does not mean that we are currently subscribed to websocket messages.
            as we may have lost connection in the meantime. Therefore the chatmodal
            will send multiple join messages (not at once) to ensure the subscription.
            There's currently no way to tell for the frontend.
          */
          if (!isStatus) return true;
        }
      }
    }
    return false;
  },
  isChatCurrentlyStarted: (state, getters, rootState, rootGetters) => (chatId) => {
    const chatHistory = state.chatsHistory[chatId];
    if (chatHistory) {
      // Let's grab our ID
      const agentId = rootGetters['agent/id'];
      // Loop in reverse over the chat history
      for (let i = chatHistory.length - 1; i >= 0; i--) {
        const msg = chatHistory[i];
        if (msg.command === chatEvent.STATUS && msg.sender_id === `${agentId}`) {
          /*
            if we find a stop, close, or transfer message before we find a start message
            that means that we have not sent a start message
          */
          if (['stop', 'close', 'transfer'].includes(msg.text)) return false;
          // if we find a start before (in reverse) we find a stop or close
          // then we are currently in a started chat
          if (msg.text === 'start') return true;
        }
      }
    }
    return false;
  },
  hasChatBeenClosed: (state, getters) => (chatId) => {
    const history = getters.getChatHistory(chatId);
    if (!Array.isArray(history) || !history?.length) return true;
    return history.some((msg) => msg.command === chatEvent.STATUS && msg.text === 'close');
  },
  hasVisitorLeftPage: (state) => (chatId) => {
    const chatHistory = state.chatsHistory[chatId];
    if (chatHistory) {
      // Loop in reverse over the chat history
      for (let i = chatHistory.length - 1; i >= 0; i--) {
        const msg = chatHistory[i];
        // if our first match is a leave, then the visitor has left
        if (msg.command === chatEvent.LEAVE && msg.sender_role === 'visitor') return true;
        // if our first match is a join, then the visitor has not left
        if (msg.command === chatEvent.JOIN && msg.sender_role === 'visitor') return false;
      }
    }
    // fallback if we find neither match
    return false;
  },
  getChatHistory: (state) => (chatId) => state.chatsHistory[chatId] || [],
  getUnAckMsgs: (state) => (chatId) => state.unAckMessages[chatId] || [],
  getAgentStartTalkTime: (state, getters, rootState, rootGetters) => (chatId) => {
    const chatHistory = state.chatsHistory[chatId];
    if (!chatHistory) return null;
    const agentId = rootGetters['agent/id'];
    const startMessage = chatHistory.find((msg) => (![
      msg.command === chatEvent.STATUS,
      msg.sender_id === `${agentId}`,
      msg.text === 'start',
    ].includes(false)));
    return startMessage ? startMessage.timestamp * 1000 : null;
  },
  getAgentEndTalkTime: (state, getters, rootState, rootGetters) => (chatId) => {
    const chatHistory = state.chatsHistory[chatId];
    if (!chatHistory) return null;
    const agentId = rootGetters['agent/id'];
    let stopMessage = chatHistory.find((msg) => (![
      msg.command === chatEvent.STATUS,
      msg.sender_id === `${agentId}`,
      msg.text === 'stop',
    ].includes(false)));
    if (!stopMessage) {
      stopMessage = chatHistory.find((msg) => (![
        msg.command === chatEvent.STATUS,
        msg.text === 'close',
      ].includes(false)));
    }
    return stopMessage ? stopMessage.timestamp * 1000 : null;
  },
  getNotificationFlag: (state) => (chatId) => {
    if (Object.prototype.hasOwnProperty.call(state.notificationFlag, chatId)) {
      return state.notificationFlag[chatId];
    }
    return -1;
  },
  lastVisitorSayMessage: (state, getters) => (chatId) => {
    const history = getters.getChatHistory(chatId);
    return history.findLast(
      (msg) => msg.sender_role === 'visitor' && msg.command === chatEvent.SAY,
    );
  },
};

const mutations = {
  RECONNECT_ATTEMPT_CHANGE(state, { reset }) {
    if (reset) state.reconnectAttempts = 0;
    else state.reconnectAttempts += 1;
  },
  SET_CHAT_SOCKET(state, payload) {
    state.chatSocket = payload;
  },
  SET_CHAT_SOCKET_STATUS(state, status) {
    state.chatSocketStatus = status;
  },
  SET_CHAT_HISTORY(state, { chatId, history }) {
    if (history.constructor === Array) {
      Vue.set(state.chatsHistory, chatId, history);
    }
  },
  APPEND_CHAT_HISTORY(state, { chatId, msg }) {
    if (!Object.prototype.hasOwnProperty.call(state.chatsHistory, chatId)) {
      Vue.set(state.chatsHistory, chatId, []);
    }
    state.chatsHistory[chatId].push(msg);
  },
  APPEND_UNACK_MSG(state, { chatId, text }) {
    if (!Object.prototype.hasOwnProperty.call(state.unAckMessages, chatId)) {
      Vue.set(state.unAckMessages, chatId, []);
    }
    const msg = {
      command: chatEvent.SAY, chat_id: chatId, text, timestamp: new Date().getTime() / 1000,
    };
    state.unAckMessages[chatId].push(msg);
  },
  FILTER_UNACK_MSG(state, { chatId, text }) {
    state.unAckMessages[chatId] = state.unAckMessages[chatId].filter(
      (x) => text !== x.text,
    );
  },
  CLOSE_WEBSOCKET(state) {
    if (state.chatSocket && [0, 1].includes(state.chatSocket.readyState)) {
      state.chatSocket.close();
    }
  },
  APPEND_NOTIFY_MSG(state, { chatId, msg }) {
    if (!Object.prototype.hasOwnProperty.call(state.toNotifyMsg, chatId)) {
      Vue.set(state.toNotifyMsg, chatId, []);
    }

    state.toNotifyMsg[chatId].push(msg);
  },
  UPDATE_NOTIFICATION_FLAG(state, { chatId }) {
    if (!Object.prototype.hasOwnProperty.call(state.notificationFlag, chatId)) {
      Vue.set(state.notificationFlag, chatId, 0);
    } else {
      Vue.set(state.notificationFlag, chatId, state.notificationFlag[chatId] + 1);
    }
  },
  REMOVE_FIRST_NOTIFY_MSG(state, { chatId }) {
    if (Object.prototype.hasOwnProperty.call(state.toNotifyMsg, chatId)) {
      state.toNotifyMsg[chatId].shift();
    }
  },
  START_PINGING(state) {
    // check if interval is already running
    if (pingInterval !== null) return;

    // set new starting point for last pings
    pingLastSent = Date.now() / 1000;
    pingLastConfirmed = Date.now() / 1000;

    pingInterval = setInterval(() => {
      const socket = state.chatSocket;
      // socket is not open so do nothing
      if (socket?.readyState !== 1) return;

      // check time between now and last confirmed ping
      // if greater than X seconds then force close websocket which will begin reconnect flow
      const time = Date.now() / 1000;
      if (pingLastSent - pingLastConfirmed >= pingMaxDiff) {
        // possibly disconnected, try to close socket
        socket?.close();
        return;
      }

      // everything is in order, send new ping message
      pingLastSent = time;
      const msgString = JSON.stringify({
        command: chatEvent.PING,
        time,
      });
      socket.send(msgString);
    }, pingFrequency);
  },
  STOP_PINGING() {
    if (pingInterval === null) return;
    clearInterval(pingInterval);
    pingInterval = null;
  },
};

const actions = {
  async setupChatSocket({
    commit, dispatch, state, rootState,
  }) {
    /* Websocket initialization */
    const socketProtocol = window.location.protocol === 'https:' ? 'wss://' : 'ws://';
    const chatSocket = new WebSocket(`${socketProtocol + SUPCHAT_API}/ws/agent/`);

    chatSocket.onopen = () => {
      commit('SET_CHAT_SOCKET_STATUS', chatSocket.readyState);
      Vue.$log.debug('Opening websocket connection. Reconnect attempt:', state.reconnectAttempts);
      dispatch('rejoinAfterDisconnect');
      commit('RECONNECT_ATTEMPT_CHANGE', { reset: true });
      commit('START_PINGING');
    };
    chatSocket.onmessage = (e) => {
      commit('SET_CHAT_SOCKET_STATUS', chatSocket.readyState);
      dispatch('onChatMessage', e);
    };
    chatSocket.onerror = (e) => {
      commit('SET_CHAT_SOCKET_STATUS', chatSocket.readyState);
      Vue.$log.error(e);
    };
    chatSocket.onclose = () => {
      commit('SET_CHAT_SOCKET_STATUS', chatSocket.readyState);
      commit('STOP_PINGING');
      if (!rootState.agent.isLoggedIn) return;
      Vue.$log.debug('Closing websocket connection. Reconnect attempt:', state.reconnectAttempts);
      if (state.reconnectAttempts >= reconnectIntervals.length - 1) {
        return;
      }
      const delay = reconnectIntervals[state.reconnectAttempts];
      setTimeout(() => dispatch('setupChatSocket'), delay * 1000);
      commit('RECONNECT_ATTEMPT_CHANGE', { reset: false });
    };
    commit('SET_CHAT_SOCKET', chatSocket);
    return chatSocket;
  },

  onChatMessage({ commit, dispatch }, e) {
    const msg = JSON.parse(e.data);
    const chatId = msg.chat_id;
    Vue.$log.info(msg);
    if (msg.command === chatEvent.PONG) {
      pingLastConfirmed = parseFloat(msg.time);
      return;
    }
    if (msg.command === chatEvent.SAY) {
      dispatch('handleSayMsg', msg);
      return;
    }
    if (msg.command === chatEvent.STATUS && msg.text === 'transfer') {
      dispatch(
        'chat/chatModals/setModalAwaitingRemoval',
        { chatId, value: true },
        { root: true },
      );
      return;
    }
    if (msg.command === chatEvent.PREDICTION) {
      if (msg.type === 'metadata') {
        dispatch('chat/chatModals/setMetadataPredictions',
          { chatId, value: msg },
          { root: true });
      } else if (msg.type === 'canned_message') {
        dispatch('chat/chatModals/setCMPredictions',
          { chatId, value: msg.uuid },
          { root: true });
      }
    } else if (msg.command === chatEvent.ARTICLE_PREDICTION) {
      dispatch('chat/chatModals/setArticlePredictionUUID',
        { chatId, value: msg.uuid },
        { root: true });
    }
    commit('APPEND_CHAT_HISTORY', { chatId, msg });
  },
  handleSayMsg({
    commit, dispatch, rootState, getters,
  }, msg) {
    const chatId = msg.chat_id;
    const userId = parseInt(msg.sender_id, 10);
    const text = msg.text;
    if (msg.sender_role !== 'system') {
      if (getters.isChatCurrentlyStarted(chatId)) dispatch('triggerNotification', msg);
      if (userId === parseInt(rootState.agent.info.id, 10)) {
        commit('FILTER_UNACK_MSG', { chatId, text });
      }
    }
    commit('APPEND_CHAT_HISTORY', { chatId, msg });
  },

  triggerNotification({ commit, getters }, msg) {
    const chatId = msg.chat_id;
    if (
      !getters['chatModals/isModalVisible'](chatId)
      || !document.hasFocus()
    ) {
      commit('APPEND_NOTIFY_MSG', { chatId, msg });
      commit('UPDATE_NOTIFICATION_FLAG', { chatId });
    }
  },

  async ensureChatSocketSet({ state, dispatch }) {
    if (state.chatSocket === null || [2, 3].includes(state.chatSocket.readyState)) {
      return dispatch('setupChatSocket');
    }
    return state.chatSocket;
  },

  async setChatHistory({ commit }, { chatId, history }) {
    commit('SET_CHAT_HISTORY', { chatId, history });
  },

  async appendUnAckMsg({ commit }, { chatId, text }) {
    commit('APPEND_UNACK_MSG', { chatId, text });
  },

  closeWebSocket({ commit }) {
    commit('CLOSE_WEBSOCKET');
  },
  extractToNotifyMsg({ state, commit }, chatId) {
    let msg = null;
    if (Object.prototype.hasOwnProperty.call(state.toNotifyMsg, chatId)
      && state.toNotifyMsg[chatId].length > 0) {
      msg = state.toNotifyMsg[chatId][0];
    }
    commit('REMOVE_FIRST_NOTIFY_MSG', { chatId });

    return Promise.resolve(msg);
  },
  rejoinAfterDisconnect({ state, getters }) {
    /*
      We grab ids from chatModals/chatModalIds as that is probably the
      most reliable list of chats we have right now.
    */
    const myChatIds = getters['chatModals/chatModalIds'];
    myChatIds.forEach((chatId) => {
      if (!getters.hasChatBeenClosed(chatId)) {
        sendCommand(state.chatSocket, chatId, { command: chatEvent.JOIN });
      }
    });
  },
  async handleAutoAssign({ commit, rootState, rootGetters }, socketMsg) {
    /*
      A message will look like this:
      {
        type: 'auto_assign',
        chats_to_agents: { chatid: agentid }
      }
    */
    // Check if we are currently online, otherwise stop
    const myStatus = rootGetters['status/myStatus'];
    if (myStatus !== 'ON') return;

    const agentId = rootState.agent?.info?.id;
    const myAssignedChats = [];
    const autoAssignObj = socketMsg.chats_to_agents;
    // check if any chats were assigned to us
    Object.entries(autoAssignObj).forEach(([chatId, assignedAgentId]) => {
      if (assignedAgentId === agentId) {
        myAssignedChats.push(chatId);
      }
    });

    // Pick up all the chats that were assigned to us
    if (myAssignedChats.length) {
      for await (const chatId of myAssignedChats) {
        await pickupChat({ chat_id: chatId, is_auto_assign: true });
      }
      // make sure we ask for an update of our chats
      commit(
        'controlSocket/UPDATE_REFRESH_STATUS',
        { key: 'chatModal', value: true },
        { root: true },
      );
      const notificationId = 'chat_assigned';
      const notificationEnabled = rootGetters['agent/settings/notificationEnabled'];
      if (!notificationEnabled(notificationId)) return;
      const headerText = i18n.t(`userSettings.notifications.${notificationId}`);
      const bodyText = i18n.t(`userSettings.notifications.${notificationId}Body`);
      if (!document.hasFocus()) {
        const notification = notify(headerText, { body: bodyText });
        if (notification) {
          notification.onclick = () => {
            window.focus();
          };
        }
      }
    }
  },
  /**
   * Update the state of a single incoming chat. This can be updating chatlog or
   * removing (deleting) it from the incoming state if it has ended.
   * @param {Object} vuex Vuex
   * @param {Object} object object containing chat ID and task ID
   * @param {string} object.chatId chat ID of the chat we want to update.
   * @param {('move'|'delete'|'update')} object.task specify what needs to happen to said chat
   */
  handleChatUpdate({ getters, dispatch }, { chatId, task }) {
    // determine if chat is incoming or ongoing
    let chatStatus = '';
    if (getters.visibleIncomingChatIDs.includes(chatId)) chatStatus = 'incoming';
    else if (getters.visibleOngoingChatIDs.includes(chatId)) chatStatus = 'ongoing';

    // if we cant find it then it's been handled by the chat pollers
    if (!chatStatus) return;

    if (chatStatus === 'incoming') {
      if (task === 'move') {
        // const chatObj = getters.getIncomingChatFromID(chatId);
        // dispatch('updateSingleOngoingChat', { chatId, task: 'add', chatObj });
        dispatch('updateSingleIncomingChat', { chatId, task: 'delete' });
      } else {
        // this handles delete and updates e.g. new messages
        dispatch('updateSingleIncomingChat', { chatId, task });
      }
    } else if (chatStatus === 'ongoing') {
      if (task === 'move') {
        // const chatObj = getters.getOngoingChatFromID(chatId);
        // dispatch('updateSingleIncomingChat', { chatId, task: 'add', chatObj });
        dispatch('updateSingleOngoingChat', { chatId, task: 'delete' });
      } else {
        // this handles delete and updates e.g. new messages
        dispatch('updateSingleOngoingChat', { chatId, task });
      }
    }
  },
};

export default {
  namespaced: true,
  state: chatState,
  getters: chatGetters,
  mutations,
  actions,
  modules: {
    incomingChats,
    ongoingChats,
    chatModals,
  },
};
