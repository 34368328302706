// NOTE: interval is ignored if fetching fails. Reconnect intervals take over until fetch succeeds
export const ongoingChatFetchIntervals = {
  interval: 1_000, // how often the interval runs and update rate of individual chat updates
  min: 2_000, // at least 2 seconds must pass between fetches
  max: 25_000, // at most 25 seconds can pass between fetches
};

export const incomingChatFetchIntervals = {
  interval: 1_000, // how often the interval runs and update rate of individual chat updates
  min: 2_000, // at least 2 seconds must pass between fetches
  max: 15_000, // at most 15 seconds can pass between fetches
};

export const myChatsFetchDelay = 500;
export const myChatsFetchRetries = 10; // how many times can we retry fetches if it fails

export const dayCodeTranslationMap = {
  MO: 'monday',
  TU: 'tuesday',
  WE: 'wednesday',
  TH: 'thursday',
  FR: 'friday',
  SA: 'saturday',
  SU: 'sunday',
};

export const FREQTranslationMap = {
  DAILY: 'vocabulary.day',
  WEEKLY: 'vocabulary.week',
  MONTHLY: 'vocabulary.month',
  YEARLY: 'vocabulary.year',
};

export const tooltipOptions = {
  delay: {
    show: 1000,
    hide: 50,
  },
  triggers: 'hover',
  interactive: false,
  placement: 'right',
  boundary: 'window',
};

// ------ Filtering related ------
export function timeperiodShortcuts(moment) {
  return {
    lastMonth: {
      text: ['message.lastMonth'],
      value: [
        moment().subtract(1, 'months').startOf('month').valueOf(),
        moment().subtract(1, 'months').endOf('month').valueOf(),
      ],
    },
    lastWeek: {
      text: ['message.lastWeek'],
      value: [
        moment().subtract(1, 'weeks').startOf('isoWeek').valueOf(),
        moment().subtract(1, 'weeks').endOf('isoWeek').valueOf(),
      ],
    },
    lastXMonths: (months) => ({
      text: ['message.lastMonths', { number: months }],
      value: [
        moment().subtract(months, 'months').startOf('month').valueOf(),
        moment().endOf('month').valueOf(),
      ],
    }),
    lastXWeeks: (weeks) => ({
      text: ['message.lastWeeks', { number: weeks }],
      value: [
        moment().subtract(weeks, 'weeks').startOf('isoWeek').valueOf(),
        moment().endOf('isoWeek').valueOf(),
      ],
    }),
    lastXDays: (days) => ({
      text: ['message.lastDays', { number: days }],
      value: [
        moment().subtract(days, 'days').startOf('day').valueOf(),
        moment().endOf('day').valueOf(),
      ],
    }),
    lastXHours: (hours) => ({
      text: ['message.lastHours', { number: hours }],
      value: [
        moment().subtract(hours, 'hours').startOf('hour').valueOf(),
        moment().endOf('hour').valueOf(),
      ],
    }),
    lastXMinutes: (minutes) => ({
      text: ['message.lastMinutes', { number: minutes }],
      value: [
        moment().subtract(minutes, 'minutes').startOf('minute').valueOf(),
        moment().endOf('minutes').valueOf(),
      ],
    }),
    lastHour: {
      text: ['message.lastHour'],
      value: [
        moment().subtract(1, 'hours').startOf('hour').valueOf(),
        moment().endOf('hour').valueOf(),
      ],
    },
    lastMinute: {
      text: ['message.lastMinute'],
      value: [
        moment().subtract(1, 'minutes').startOf('minute').valueOf(),
        moment().endOf('minute').valueOf(),
      ],
    },
    currentMonth: {
      text: ['message.curMonth'],
      value: [
        moment().startOf('month').valueOf(),
        moment().endOf('month').valueOf(),
      ],
    },
    currentWeek: {
      text: ['message.curWeek'],
      value: [
        moment().startOf('isoWeek').valueOf(),
        moment().endOf('isoWeek').valueOf(),
      ],
    },
    currentHour: {
      text: ['message.curHour'],
      value: [
        moment().startOf('hour').valueOf(),
        moment().endOf('hour').valueOf(),
      ],
    },
    yesterday: {
      text: ['vocabulary.yesterday'],
      value: [
        moment().subtract(1, 'days').startOf('day').valueOf(),
        moment().subtract(1, 'days').endOf('day').valueOf(),
      ],
    },
    today: {
      text: ['vocabulary.today'],
      value: [
        moment().startOf('day').valueOf(),
        moment().endOf('day').valueOf(),
      ],
    },
  };
}

export const exportSheetDelimiters = [
  { value: '\t', text: '\\t' },
  { value: ',', text: ',' },
];

export const exportSheetFormats = [
  { value: 'xlsx', text: '.xlsx' },
  { value: 'csv', text: '.csv' },
];

export const agentStatusOverviewColumns = [
  {
    key: 'agentId',
    label: 'message.agentName',
  },
  {
    key: 'ongoingChats',
    label: 'message.pageOngoing',
  },
  {
    key: 'chat_count',
    label: 'message.analytics.metrics.chat_count',
  },
  {
    key: 'offlineTime',
    label: 'message.agentOverview.offlineTime',
  },
  {
    key: 'onlineTime',
    label: 'message.agentOverview.onlineTime',
  },
  {
    key: 'onlineTimePerChat',
    label: 'message.agentOverview.onlineTimePerChat',
  },
  {
    key: 'awayTime',
    label: 'message.agentOverview.awayTime',
  },
  {
    key: 'average_handling_time',
    label: 'message.agentOverview.handlingChatsTime',
  },
];

export const pastelColors = [
  '#ffffff',
  '#eae4e9',
  '#fff1e6',
  '#fde2e4',
  '#fad2e1',
  '#e2ece9',
  '#bee1e6',
  '#f0efeb',
  '#dfe7fd',
  '#cddafd',
];
