import { chatEvent } from 'supwiz/supchat/constants';

// number formatting, use language from locale selector or use fallback
const languageCode = localStorage.getItem('language');
const numberFormatClass = new Intl.NumberFormat(languageCode || undefined);

export function extractTransferInfo(chat) {
  // places where this function is used expect at least 1 element in the returned array
  // the empty string means there was no transfer info i.e. the start just started
  if (!Array.isArray(chat.msgs)) return [''];
  const msgs = chat.msgs.slice(0).reverse();
  for (const msg of msgs) {
    if (msg.command === chatEvent.STATUS
      && msg.text === 'transfer'
      && msg.sender_role !== 'system') {
      let typeOfTransfer = msg?.dst_type;
      if (!typeOfTransfer) {
        if (msg.target_agent_id !== undefined) typeOfTransfer = 'agent';
        else typeOfTransfer = 'department';
      }
      return [msg.sender_id, typeOfTransfer];
    }
  }
  return [''];
}

/* request from NNIT as they do not want to show the initials to the visitor */
/* TODO in the backend have a separate field where allow the agent to define its shown name */
export function filterSenderName(name, role) {
  if (!name) return '';
  if (role === 'visitor') return name;

  if (name.includes('(') && name.includes(')')) {
    const regExp = /\(([^)]+)\)/;
    const matches = regExp.exec(name);
    const fullName = matches[1];
    return fullName.split(' ')[0];
  }
  return name;
}

export function getSenderName(sendersInfo, senderID, role) {
  if (typeof sendersInfo !== 'object'
    || typeof senderID !== 'string'
    || typeof role !== 'string') return '';
  let senderName;
  const roleNames = { visitor: 'Visitor', agent: 'Agent' };
  if (Object.prototype.hasOwnProperty.call(sendersInfo[role], senderID)) {
    senderName = sendersInfo[role][senderID].displayName;
    senderName = filterSenderName(senderName, role);
  }
  if (senderName === '' || senderName === undefined) {
    senderName = roleNames[role];
  }
  return senderName;
}

export function deepFreeze(object) {
  if (typeof object !== 'object') throw new Error('deepFreeze was not given an object');
  // Retrieve the property names defined on object
  const propNames = Object.keys(object);

  // Freeze properties before freezing self

  for (const name of propNames) {
    const value = object[name];

    if (value && typeof value === 'object') {
      deepFreeze(value);
    }
  }

  return Object.freeze(object);
}

export function isObjectEmpty(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export function msgsToPreview(msgs, senderNames = {}, newLine = ' \u21B5 ') {
  let s = '';
  if (!msgs) return s;
  const translations = {};
  for (const { command, translated_uuid: translatedUuid, text } of msgs) {
    if (command === chatEvent.SAY && !!translatedUuid) {
      translations[translatedUuid] = text;
    }
  }
  const texts = [];
  for (const msg of msgs) {
    if (!msg) continue;
    const {
      command,
      uuid,
      text,
      sender_role: senderRole,
      sender_id: senderId,
    } = msg;
    if (command === chatEvent.SAY && ['agent', 'visitor'].includes(senderRole)) {
      let sender = '';
      if (senderRole === 'visitor' && senderNames.visitor) {
        sender = `${senderNames.visitor}: `;
      } else if (senderNames[senderId]) {
        sender = `${senderNames[senderId]}: `;
      }
      if (senderRole === 'visitor' && translations[uuid]) {
        texts.push(sender + translations[uuid]);
      } else {
        texts.push(sender + text);
      }
    }
  }
  s = texts.join(newLine);
  return s;
}

export function previewVisitorMessages(msgs) {
  if (typeof msgs !== 'object') return '';
  const visitorMessages = msgs.filter(
    (msg) => [chatEvent.SAY]
      .includes(msg.command) && ['visitor', 'system'].includes(msg.sender_role),
  );
  return visitorMessages ? msgsToPreview(visitorMessages) : '';
}

export function notify(title, options = {}) {
  if (!Notification || Notification.permission !== 'granted') return null;
  return new Notification(title, options);
}

export function prepareGetParameters(rawParameterObject) {
  const pv = [];
  for (const key of Object.keys(rawParameterObject)) {
    const val = rawParameterObject[key];
    pv.push(`${encodeURIComponent(key)}=${encodeURIComponent(val)}`);
  }
  return pv.join('&');
}

export function printDuration(d) {
  // We print the duration in seconds (rounded up).
  // At 1:59.1 we would rather show 2:00 than 1:59.
  const roundedD = Math.ceil(d / 1000.0);
  const seconds = roundedD % 60.0;
  const minutes = Math.floor(roundedD / 60);
  let s = seconds.toString().padStart(2, '0');
  s = `${minutes.toString().padStart(2, '0')}:${s}`;
  return s;
}

export function setDiff(a, b) {
  return new Set([...a].filter((x) => !b.has(x)));
}

export function setIntersection(a, b) {
  return new Set([...a].filter((x) => b.has(x)));
}

export function sendCommand(chatSocket, chatId, cmd) {
  const aux = { ...cmd };
  aux.chat_id = chatId;
  chatSocket.send(JSON.stringify(aux));
}

export function sendMessage(chatSocket, text, chatId, language) {
  if (!chatSocket || !text) {
    return;
  }
  chatSocket.send(JSON.stringify({
    command: chatEvent.SAY,
    chat_id: chatId,
    text,
    language,
  }));
}

export function trimLength(s, val = 23) {
  if (s.length > val) {
    const sliced = s.slice(0, val);
    return `${sliced.trim()}...`;
  }
  return s.trim();
}

/**
 * Test a translation key and use it if it exists. Otherwise, return the 'fallback'.
 * @param {object} instance - The Vue Instance (this), required for accessing Vue i18n.
 * @param {string} translationKey  - Path to the translation we want to test for.
 * @param {string} fallback - fallback string if translationKey wasn't found.
 * @returns {string} - Returns translation if found or 'fallback' if not.
 */
export function testAndGetTranslation(instance, translationKey, fallback) {
  if (typeof instance !== 'object') {
    throw new Error('"this" was not passed as 1st parameter to testAndGetTranslation');
  }
  if ([translationKey, fallback].some((param) => typeof param !== 'string')) {
    console.error('translationKey and/or fallback were not passed to testAndGetTranslation');
    return fallback || translationKey || 'MISSING_TRANSLATION';
  }
  return `${instance.$te(translationKey) ? instance.$t(translationKey) : fallback}`;
}

export function arrayIncludesOrFallback(target, array, fallback) {
  return array.includes(target) ? target : fallback;
}

export function msgsToString(vue, msgs, agents, language = '') {
  let s = '';
  const finalTags = [];
  const metadata = msgs.find((msg) => msg.command === chatEvent.METADATA);
  const translations = msgs.filter(
    (msg) => msg.command === chatEvent.SAY && !!msg.translated_uuid);

  // find all tag messages, then figure out which are still relevant in finalTags
  const tagging = msgs
    .filter((msg) => [chatEvent.ADD_TAG, chatEvent.REMOVE_TAG].includes(msg.command));
  tagging.forEach((tag) => {
    if (tag.command === chatEvent.ADD_TAG) {
      finalTags.push(tag);
    } else {
      const existsIndex = finalTags.findIndex((fTag) => fTag.tagged_msg === tag.tagged_msg);
      if (existsIndex !== -1) finalTags.splice(existsIndex, 1);
      else vue.$log.error(`tried to remove a tag for message ${tag.tagged_msg} but the tag was not added yet`);
    }
  });

  const getAgentName = (id) => {
    const match = agents.find((a) => Number(a.id) === Number(id));
    return match ? filterSenderName(match.display_name, 'agent') : vue.$t('interaction.agent');
  };
  const visitorName = metadata?.data?.displayName || vue.$t('interaction.visitor');

  for (const msg of msgs.filter((m) => [
    chatEvent.FILE_UPLOAD,
    chatEvent.FORM_RESPONSE,
    chatEvent.INACTIVITY_MESSAGE,
    chatEvent.INTERACTION,
    chatEvent.NOTE,
    chatEvent.SAY,
    chatEvent.STATUS,
    chatEvent.VISITOR_FILE_UPLOAD,
  ].includes(m.command))) {
    // skip if say msg with system as senderRole (this is a translation message)
    if (msg.command === chatEvent.SAY && msg.sender_role === 'system') continue;
    const msgTranslations = translations.filter((tmsg) => tmsg.translated_uuid === msg.uuid);
    let translation = null;

    // no language set as parameter
    if ((!language || !msg.language) && msgTranslations.length) {
      translation = msgTranslations[0];
    } else {
      translation = msgTranslations.find((tMsg) => tMsg.language === language);
    }
    const tag = finalTags.find((tmsg) => tmsg.tagged_msg === msg.uuid);
    let senderName = visitorName;
    if (msg.sender_role === 'agent') senderName = getAgentName(msg.sender_id);
    else if (msg.sender_role === 'system') senderName = 'System';
    const timestamp = new Date(msg.timestamp * 1000).toLocaleString();
    const msgTranslated = translation ? ` [Translated to: ${translation.text}]` : '';
    const msgTagged = tag ? ` **Tagged: ${tag.text}**` : '';
    switch (msg.command) {
      case chatEvent.INACTIVITY_MESSAGE:
        s += `[${timestamp}]**${msg.text}**\n`;
        break;
      case chatEvent.FORM_RESPONSE: {
        s += `[${timestamp}]**${vue.$t('status.userAnsweredForm', { name: senderName })}**\n`;
        const { fields } = msg;
        const fieldsAsString = Object.keys(fields).reduce((result, fieldKey) => {
          let value = fields[fieldKey]?.value;
          if (typeof value === 'object' && value !== null) {
            value = JSON.stringify(value);
          }
          return `${result}\t${fieldKey}: ${value}\n`;
        }, '');
        s += `${fieldsAsString}\n`;
        break;
      }
      case chatEvent.STATUS:
        switch (msg.text) {
          case 'start':
            s += `[${timestamp}]**${vue.$t('status.userJoin', { name: senderName })}**\n`;
            break;
          case 'stop':
            s += `[${timestamp}]**${vue.$t('status.userLeft', { name: senderName })}**\n`;
            break;
          case 'transfer':
            s += `\n\n[${timestamp}]**${vue.$tc('status.chatTransfer', 1)}**\n`;
            break;
          case 'close':
            s += `[${timestamp}]**${vue.$t('status.userClosed', { name: senderName })}**\n`;
            break;
          default:
            break;
        }
        break;
      case chatEvent.FILE_UPLOAD:
      case chatEvent.VISITOR_FILE_UPLOAD: {
        let file = msg.path || msg.filename || msg.uuid;
        if (file.includes('?')) file = file.split('?')[0];
        s += `[${timestamp}]**${vue.$t(
          'status.userUpload',
          { name: senderName, url: file },
        )}**\n`;
        break;
      }
      case chatEvent.INTERACTION:
        s += `[${timestamp}]**${vue.$t(
          'status.userInteracted',
          { name: senderName, value: msg.value },
        )}**\n`;
        break;
      case chatEvent.NOTE: {
        const note = vue.$t('vocabulary.note');
        s += `[${timestamp}]--<${senderName}> (${note}): ${msg.text}\n`;
        break;
      }
      default:
        s += `[${timestamp}]-<${senderName}>: ${msg.text}${msgTranslated}${msgTagged}\n`;
        break;
    }
  }
  return s.trim();
}

export function metaDataObjectToString(vue, dataObject, indent = 0) {
  if (!dataObject || !Object.keys(dataObject).length) return '';
  let s = '';
  for (const [key, value] of Object.entries(dataObject)) {
    s += '\t'.repeat(indent);
    s += `${testAndGetTranslation(
      vue,
      `vocabulary.${key}`,
      key,
    )}: ${typeof value === 'object'
      ? `\n${metaDataObjectToString(vue, value, indent + 1)}`
      : `${`"${value}"` || vue.$t('message.fieldNotDefined')}\n`}`;
  }
  return s;
}

// This is a smart "wait until" function. We use this to avoid spamming endpoints
// https://stackoverflow.com/a/52657929
export function waitUntil(conditionFunction) {
  const poll = (resolve) => {
    if (conditionFunction()) resolve();
    else setTimeout(() => poll(resolve), 400);
  };
  return new Promise(poll);
}

export function formatNumber(number) {
  return numberFormatClass.format(Number(number).toFixed(2));
}

export function isHandlebarsTemplate(string) {
  return /{{\S+}}/.test(string);
}

export function rgbToRgba(colorCode, opacity) {
  return colorCode
    .replace('rgb', 'rgba')
    .replace(')', `, ${opacity})`);
}

export function lightenRgb(colorCode, lightenBy) {
  // https://stackoverflow.com/questions/9585973/javascript-regular-expression-for-rgb-values
  const [, r, g, b] = colorCode.match(/^rgb\((\d+),(\d+),(\d+)\)$/);
  const lighten = (c) => Number(c) + lightenBy;
  return `rgb(${lighten(r)}, ${lighten(g)}, ${lighten(b)})`;
}

export function eventDispatcher(eventName, detailObj) {
  // skip in unsupported browsers
  if (typeof window.CustomEvent !== 'function') return;

  const event = new CustomEvent(eventName, { detail: detailObj });

  document.dispatchEvent(event);
}

export function analyticsEventEmitter({ command, text, sender }) {
  /*
    command = detail.msg.command
    text = detail.msg.text
    possible commands are:
      option_click -> text will be the clicked option
      url_click -> text will be the url
      image_click -> text will be the url to the image
      say -> text will be the said text
      status -> text can be:
        chat_end
        chat_transfer
        agent_join
        agent_leave
        chat_start
  */
  const detailObj = {
    msg: {
      command,
      text,
    },
    timestamp: Date.now(),
    sender,
  };
  eventDispatcher('supchatevent', detailObj);
}

export function prepareHistory(history) {
  let result = [];
  let lastVisitorTimestamp = 0;

  // pass the first join message of visitor
  let firstVisitorJoinSkipped = false;
  for (const msg of history) {
    const currentSenderRole = msg.sender_role;
    const isVisitorMsg = currentSenderRole === 'visitor';

    if (isVisitorMsg && msg.command === chatEvent.LEAVE && lastVisitorTimestamp === 0) {
      lastVisitorTimestamp = msg.timestamp;
      result.push(msg);
    } else if (![
      isVisitorMsg,
      msg.timestamp >= lastVisitorTimestamp,
      lastVisitorTimestamp > 0,
    ].includes(false)) {
      result = result
        .filter((resultMsg) => !(isVisitorMsg && resultMsg.command === chatEvent.LEAVE));
      lastVisitorTimestamp = 0;
    } else if (msg.command === chatEvent.STATUS && msg.sender_role !== 'system') {
      if (!firstVisitorJoinSkipped
        && msg.text === 'start'
        && msg.sender_role === 'visitor') {
        firstVisitorJoinSkipped = true;
        continue;
      }
      result.push(msg);
    } else if ([
      chatEvent.FILE_UPLOAD,
      chatEvent.FORM_REQUEST,
      chatEvent.FORM_RESPONSE,
      chatEvent.NOTE,
      chatEvent.SAY,
      chatEvent.VISITOR_FILE_UPLOAD,
      chatEvent.WAITING_INFO,
      chatEvent.INACTIVITY_MESSAGE,
      chatEvent.INTERACTION,
    ].includes(msg.command)) {
      if (msg.command === chatEvent.SAY && msg.sender_role === 'system') continue;
      result.push(msg);
    }
  }
  return result;
}

export function prepareTranslations(history) {
  const result = {};
  for (const msg of history) {
    if (msg.command === chatEvent.SAY
      && !!msg.translated_uuid
      && msg.sender_role === 'system') {
      if (!result[msg.translated_uuid]) result[msg.translated_uuid] = [];
      result[msg.translated_uuid].push(msg);
    }
  }
  return result;
}
export function prepareTags(history) {
  const result = {};
  for (const msg of history) {
    const { command, tagged_msg: taggedMsg } = msg;
    if (command === chatEvent.ADD_TAG) {
      if (!result[taggedMsg]) {
        result[taggedMsg] = [];
      }
      result[taggedMsg].push(msg);
    } else if (command === chatEvent.REMOVE_TAG) {
      delete result[taggedMsg];
    }
  }
  return result;
}
