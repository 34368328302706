<template>
  <div>
    <b-button
      v-b-modal.agent-status-list-settings-modal
      :aria-label="$t('vocabulary.close')"
      size="sm"
      variant="link"
      class="text-light py-1"
    >
      <FontAwesomeIcon icon="cog" />
    </b-button>
    <b-modal
      id="agent-status-list-settings-modal"
      centered
      ok-only
      :ok-title="$t('vocabulary.close')"
      :title="$t('message.statusListSettings')"
      size="sm"
      @hidden="$emit('save')"
    >
      <b-form-group :label="$t('vocabulary.status')">
        <MultiSelect
          v-model="statusFilterToInput"
          class="w-100"
          :options="statusOptions"
        />
      </b-form-group>
      <hr>
      <b-form-group :label="$t('vocabulary.grouping')">
        <b-form-select
          v-model="groupToInput"
          :options="groupOptions"
        />
      </b-form-group>
      <FadeDown>
        <b-form-group
          v-if="grouping === 'department'"
          :label="$t('vocabulary.departmentMultiple')"
        >
          <InputComponent
            v-model="departmentFilterToInput"
            filter="agentDepartments"
            no-auto-select
            no-session-storage
          />
        </b-form-group>
      </FadeDown>
      <hr>
      <b-form-group :label="$t('vocabulary.agentMultiple')">
        <InputComponent
          v-model="agentFilterToInput"
          filter="systemAgents"
          no-auto-select
          no-session-storage
        />
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import MultiSelect from '@/components/MultiSelect.vue';
import FadeDown from '@/components/Transitions/FadeDown.vue';

const propArrayRequired = { type: Array, required: true };

export default {
  name: 'AgentStatusListSettings',
  components: { MultiSelect, FadeDown },
  props: {
    grouping: { type: String, required: true },
    statusFilter: propArrayRequired,
    agentFilter: propArrayRequired,
    departmentFilter: propArrayRequired,
  },
  emits: ['set-grouping', 'set-status-filter', 'set-agent-filter', 'set-department-filter', 'save'],
  computed: {
    groupToInput: {
      get() {
        return this.grouping;
      },
      set(value) {
        this.$emit('set-grouping', value);
      },
    },
    statusFilterToInput: {
      get() {
        return this.statusFilter;
      },
      set(value) {
        this.$emit('set-status-filter', value);
      },
    },
    agentFilterToInput: {
      get() {
        return this.agentFilter;
      },
      set(value) {
        this.$emit('set-agent-filter', value);
      },
    },
    departmentFilterToInput: {
      get() {
        return this.departmentFilter;
      },
      set(value) {
        this.$emit('set-department-filter', value);
      },
    },
    groupOptions() {
      return [
        {
          text: this.$t('vocabulary.departmentSingle'),
          value: 'department',
        },
        {
          text: this.$t('vocabulary.none'),
          value: 'none',
        },
      ];
    },
    statusOptions() {
      return [
        {
          text: this.$t('vocabulary.online'),
          value: 'ON',
        },
        {
          text: this.$t('vocabulary.away'),
          value: 'AW',
        },
        {
          text: this.$t('vocabulary.invisible'),
          value: 'IN',
        },
      ];
    },
  },
};
</script>
