import Vue from 'vue';
import {
  getLanguages,
  getTenantLanguagesSetup,
  getTenantTranslationsSetup,
  updateTenantTranslationsSetup,
  updateTenantLanguagesSetup,
  getDepartmentLanguagesSetup,
  getDepartmentTranslationsSetup,
  updateDepartmentLanguagesSetup,
  updateDepartmentTranslationsSetup,
} from '@/api/apiList';
import { deepFreeze } from 'supwiz/supchat/generalUtils';

const languagesState = {
  languages: [],
  departmentLanguages: {},
  departmentTranslations: {},
  tenantLanguages: {},
  tenantTranslations: {},
  translationPairs: [],
  fetchingStatus: {},
};

const languageGetters = {
  getDepartmentOverrides: (state) => ({ departmentId }) => {
    const languageSettings = state.departmentLanguages[departmentId] || {};
    const translationSettings = state.departmentTranslations?.[departmentId] || {};
    return {
      ...languageSettings,
      ...translationSettings,
    };
  },
  departmentHasAnyOverrides: (state) => ({ departmentId }) => [
    Object.values(state.departmentLanguages?.[departmentId] ?? {}).some((value) => value !== null),
    Object.values(state.departmentTranslations?.[departmentId] ?? {})
      .some((value) => value !== null),
  ].includes(true),
  getAgentLanguages: (state) => ({ tenantId, departmentId }) => {
    const tenantSetting = state.tenantLanguages[tenantId]?.agent_languages || [];
    return state.departmentLanguages?.[departmentId]?.agent_languages_override || tenantSetting;
  },
  getDefaultLanguage: (state) => ({ tenantId, departmentId }) => {
    const tenantSetting = state.tenantLanguages[tenantId]?.default_department_language;
    return state.departmentLanguages?.[departmentId]
      ?.default_department_language_override || tenantSetting;
  },
  getVisitorLanguages: (state) => ({ tenantId, departmentId }) => {
    const tenantSetting = state.tenantLanguages[tenantId]?.visitor_languages || [];
    return state.departmentLanguages?.[departmentId]?.visitor_languages_override || tenantSetting;
  },
};

const mutations = {
  SET_LANGUAGES(state, languages) {
    Vue.set(state, 'languages', deepFreeze(languages));
  },
  SET_TRANSLATION_PAIRS(state, pairs) {
    Vue.set(state, 'translationPairs', deepFreeze(pairs));
  },
  SET_TENANT_LANGUAGE_SETUP(state, {
    tenantId,
    settings,
  }) {
    Vue.set(state.tenantLanguages, tenantId, deepFreeze(settings));
  },
  SET_DEPARTMENT_LANGUAGE_SETUP(state, {
    departmentId,
    settings,
  }) {
    Vue.set(state.departmentLanguages, departmentId, deepFreeze({
      visitor_languages_override: settings.visitor_languages_override,
      agent_languages_override: settings.agent_languages_override,
      default_department_language_override: settings.default_department_language_override,
    }));
  },
  SET_TENANT_TRANSLATION_SETUP(state, {
    tenantId,
    settings,
  }) {
    Vue.set(state.tenantTranslations, tenantId, deepFreeze(settings));
  },
  SET_DEPARTMENT_TRANSLATION_SETUP(state, {
    departmentId,
    settings,
  }) {
    Vue.set(state.departmentTranslations, departmentId, deepFreeze({
      auto_translation_enabled_override: settings.auto_translation_enabled_override,
    }));
  },
  SET_FETCHING_STATUS(state, {
    task,
    status,
  }) {
    Vue.set(state.fetchingStatus, task, status);
  },
};

const actions = {
  async ensureLanguagesSetup({ state, dispatch }, tenantId = '') {
    if (!state.languages?.length) await dispatch('fetchLanguagesSetup');
    if (!tenantId) return;
    if (!(tenantId in state.tenantLanguages)) {
      await dispatch('fetchTenantSetup', tenantId);
    }
    if (!Object.keys(state.departmentLanguages).length) {
      await dispatch('fetchDepartmentSetup', { tenantId });
    }
  },
  async ensureTenantSetup({ state, dispatch, rootState }, tenantId) {
    // base language setup
    if (!state.languages?.length) await dispatch('fetchLanguagesSetup');

    // check if translation is enabled
    if (!rootState.featureFlags.TRANSLATION_ENABLED) return;

    // if it is, continue getting translation settings
    if (!(tenantId in state.tenantLanguages)) {
      await dispatch('fetchTenantSetup', tenantId);
    }
  },
  async fetchLanguagesSetup({ state, commit }) {
    const task = 'fetchLanguagesSetup';
    if (state.fetchingStatus[task]) return;
    commit('SET_FETCHING_STATUS', { task, status: true });
    try {
      const { languages, translations } = await getLanguages();
      commit('SET_LANGUAGES', languages);
      if (typeof translations === 'object') commit('SET_TRANSLATION_PAIRS', translations);
    } catch (error) {
      // TODO: play catch 🫴⚾
    } finally {
      commit('SET_FETCHING_STATUS', { task, status: false });
    }
  },
  async fetchTenantSetup({
    state, commit, rootState, rootGetters,
  }, tenantId) {
    const task = `fetchTenantSetup${tenantId}`;
    if (state.fetchingStatus[task]) return;
    commit('SET_FETCHING_STATUS', { task, status: true });
    try {
      const isAgent = !rootGetters['agent/isManagerOfTenant'](tenantId);
      const languageSettings = await getTenantLanguagesSetup({ isAgent, tenantId });
      commit('SET_TENANT_LANGUAGE_SETUP', { settings: languageSettings, tenantId });
      if (rootState.featureFlags.TRANSLATION_ENABLED) {
        const translationSettings = await getTenantTranslationsSetup({ isAgent, tenantId });
        commit('SET_TENANT_TRANSLATION_SETUP', { settings: translationSettings, tenantId });
      }
    } catch (error) {
      // TODO: play catch 🫴⚾
    } finally {
      commit('SET_FETCHING_STATUS', { task, status: false });
    }
  },
  async fetchDepartmentSetup({
    state, commit, rootGetters, rootState,
  }, { tenantId, departmentId }) {
    const task = `fetchDepartmentSetup${departmentId || tenantId}`;
    if (state.fetchingStatus[task]) return;
    commit('SET_FETCHING_STATUS', { task, status: true });
    try {
      let isAgent = true;
      if (!departmentId) isAgent = !rootGetters['agent/isManagerOfTenant'](tenantId);
      else isAgent = !rootGetters['agent/isManagerOfDep'](departmentId);

      const languageSettings = await getDepartmentLanguagesSetup({ isAgent, departmentId: departmentId || '' });
      if (!departmentId) {
        // if no departmentId, then results will be arrays, 1 settings object for each department
        languageSettings.forEach(({ id, ...settings }) => {
          commit('SET_DEPARTMENT_LANGUAGE_SETUP', { settings, departmentId: id });
        });
      } else {
        commit('SET_DEPARTMENT_LANGUAGE_SETUP', { settings: languageSettings, departmentId });
      }

      // check if translation is enabled
      if (!rootState.featureFlags.TRANSLATION_ENABLED) return;

      const translationSettings = await getDepartmentTranslationsSetup({ isAgent, departmentId: departmentId || '' });
      if (!departmentId) {
        // if no departmentId, then results will be arrays, 1 settings object for each department
        translationSettings.forEach(({ id, ...settings }) => {
          commit('SET_DEPARTMENT_TRANSLATION_SETUP', { settings, departmentId: id });
        });
      } else {
        commit('SET_DEPARTMENT_TRANSLATION_SETUP', { settings: translationSettings, departmentId });
      }
    } catch (error) {
      // TODO: play catch 🫴⚾
    } finally {
      commit('SET_FETCHING_STATUS', { task, status: false });
    }
  },
  async updateTenantSetup({ commit, rootState }, {
    tenantId,
    visitorLanguages,
    agentLanguages,
    defaultDepartmentLanguage,
    translationEnabled,
    autoTranslationEnabled,
  }) {
    try {
      await updateTenantLanguagesSetup({
        tenantId,
        visitorLanguages,
        agentLanguages,
        defaultDepartmentLanguage,
      });
      commit('SET_TENANT_LANGUAGE_SETUP', {
        settings: {
          visitor_languages: visitorLanguages,
          agent_languages: agentLanguages,
          default_department_language: defaultDepartmentLanguage,
        },
        tenantId,
      });
      if (rootState.featureFlags.TRANSLATION_ENABLED) {
        await updateTenantTranslationsSetup({
          tenantId,
          translationEnabled,
          autoTranslationEnabled,
        });
        commit('SET_TENANT_TRANSLATION_SETUP', {
          settings: {
            translation_enabled: translationEnabled,
            auto_translation_enabled: autoTranslationEnabled,
          },
          tenantId,
        });
      }
    } catch (error) {
      // TODO: play catch 🫴⚾
    }
  },
  async updateDepartmentSetup({ commit, rootState }, {
    departmentId,
    visitorLanguagesOverride,
    agentLanguagesOverride,
    defaultDepartmentLanguageOverride,
    autoTranslationEnabledOverride,
  }) {
    try {
      await updateDepartmentLanguagesSetup({
        departmentId,
        visitorLanguagesOverride,
        agentLanguagesOverride,
        defaultDepartmentLanguageOverride,
      });
      commit('SET_DEPARTMENT_LANGUAGE_SETUP', {
        settings: {
          visitor_languages_override: visitorLanguagesOverride,
          agent_languages_override: agentLanguagesOverride,
          default_department_language_override: defaultDepartmentLanguageOverride,
        },
        departmentId,
      });
      if (rootState.featureFlags.TRANSLATION_ENABLED) {
        await updateDepartmentTranslationsSetup({
          departmentId,
          autoTranslationEnabledOverride,
        });
        commit('SET_DEPARTMENT_TRANSLATION_SETUP', {
          settings: {
            auto_translation_enabled_override: autoTranslationEnabledOverride,
          },
          departmentId,
        });
      }
    } catch (error) {
      // TODO: play catch 🫴⚾
    }
  },
};

export default {
  namespaced: true,
  state: languagesState,
  getters: languageGetters,
  mutations,
  actions,
};
