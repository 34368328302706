import Vue from 'vue';
import { sortBy } from 'lodash';
import { deepFreeze } from 'supwiz/supchat/generalUtils';
import {
  getEntryWidgets,
  addEntryWidget,
  updateEntryWidget,
  deleteEntryWidget,
} from '@/api/apiList';

const tenantConfigState = {
  fetchingState: {},
  configs: [],
};

const tenantConfigGetters = {
  configsAsOptions: (state) => sortBy(
    state.configs.map(({ id, name }) => ({ text: name, value: id })),
    [({ text }) => text.toLowerCase()],
  ),
  getSelectedConfig: (state) => (selectedId) => {
    const selectedConfig = state.configs.find(({ id }) => selectedId === id);
    return selectedConfig;
  },
  allConfigIDs: (state) => state.configs.map(({ id }) => id),
  allConfigNames: (state) => state.configs.map(({ name }) => name),
  chatConfigOptions: (state, getters, rootState, rootGetters) => {
    const tenants = rootGetters['agent/tenants'];
    const result = [];
    tenants.forEach((tenant) => {
      const chatConfigs = rootGetters['tenants/getChatWidgetConfigs'](tenant.id);
      const chatConfigsMapped = chatConfigs.map(({ id, name }) => ({ id, name }));
      chatConfigsMapped.forEach(({ id, name }) => (
        result.push({
          value: id,
          text: `${name} (${tenant.name})`,
        })));
    });
    return sortBy(result, [(o) => o.text.toLowerCase()]);
  },
};

const mutations = {
  SET_CONFIG(state, config) {
    const index = state.configs.findIndex(({ id }) => config.id === id);
    if (index !== -1) Vue.set(state.configs, index, deepFreeze(config));
    else state.configs.push(deepFreeze(config));
  },
  DELETE_CONFIG(state, id) {
    const index = state.configs.findIndex((config) => config.id === id);
    if (index !== -1) Vue.delete(state.configs, index);
  },
  SET_FETCHING_STATE(state, { key, val }) {
    if (!val) Vue.delete(state.fetchingState, key);
    else Vue.set(state.fetchingState, key, val);
  },
};

const actions = {
  async ensureAllChatWidgetConfigsFetched({
    state, commit, dispatch, rootGetters,
  }) {
    const taskName = 'ensureAllChatWidgetConfigsFetched';
    if (state[taskName]) return;
    try {
      commit('SET_FETCHING_STATE', { key: taskName, val: true });
      await dispatch('agent/ensureTenantsFetched', true, { root: true });
      const tenantIds = rootGetters['agent/tenants'].map(({ id }) => id);
      await Promise.all(tenantIds
        .map((tenantId) => dispatch('tenants/ensureChatWidgetConfigsFetched', { tenantId }, { root: true })));
    } catch (error) {
      commit('errorDisplay/ADD_MSG', {
        message: 'errors.unknownError',
        variant: 'danger',
      }, { root: true });
      throw error;
    } finally {
      commit('SET_FETCHING_STATE', { key: taskName, val: false });
    }
  },
  async fetchEntryWidgetConfigs({ state, commit }, id = '') {
    const taskName = `fetchEntryWidgetConfigs${id}`;
    if (state[taskName]) return;
    try {
      commit('SET_FETCHING_STATE', { key: taskName, val: true });
      const configs = await getEntryWidgets(id);
      configs.forEach((config) => {
        commit('SET_CONFIG', config);
      });
    } catch (error) {
      commit('errorDisplay/ADD_MSG', {
        message: 'errors.unknownError',
        variant: 'danger',
      }, { root: true });
      throw error;
    } finally {
      commit('SET_FETCHING_STATE', { key: taskName, val: false });
    }
  },
  async createEntryWidgetConfig({ state, commit }, { name, content }) {
    const taskName = `create${name}`;
    let resultId = null;
    if (state[taskName]) return resultId;
    try {
      commit('SET_FETCHING_STATE', { key: taskName, val: true });
      const config = await addEntryWidget({ name, content });
      commit('SET_CONFIG', config);
      resultId = config.id;
    } finally {
      commit('SET_FETCHING_STATE', { key: taskName, val: false });
    }
    return resultId;
  },
  async updateEntryWidgetConfig({ state, commit }, { id, name, content }) {
    const taskName = `update${id}`;
    if (state[taskName]) return;
    try {
      commit('SET_FETCHING_STATE', { key: taskName, val: true });
      const config = await updateEntryWidget({ id, name, content });
      commit('SET_CONFIG', config);
    } catch (error) {
      commit('errorDisplay/ADD_MSG', {
        message: 'errors.unknownError',
        variant: 'danger',
      }, { root: true });
      throw error;
    } finally {
      commit('SET_FETCHING_STATE', { key: taskName, val: false });
    }
  },
  async deleteEntryWidgetConfigs({ state, commit }, id) {
    const taskName = `delete${id}`;
    if (state[taskName]) return;
    try {
      commit('SET_FETCHING_STATE', { key: taskName, val: true });
      await deleteEntryWidget(id);
      commit('DELETE_CONFIG', id);
    } catch (error) {
      commit('errorDisplay/ADD_MSG', {
        message: 'errors.unknownError',
        variant: 'danger',
      }, { root: true });
      throw error;
    } finally {
      commit('SET_FETCHING_STATE', { key: taskName, val: false });
    }
  },
};

export default {
  namespaced: true,
  state: tenantConfigState,
  getters: tenantConfigGetters,
  mutations,
  actions,
};
