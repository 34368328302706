/* stolen from https://kazupon.github.io/vue-i18n/guide/lazy-loading.html */
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from '@/localization/en';

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: { en: messages }, // set locale messages
});

const loadedLanguages = ['en']; // our default language that is preloaded

function setI18nLanguage(lang) {
  i18n.locale = lang;
  document.querySelector('html').setAttribute('lang', lang);
  localStorage.setItem('language', lang);
  return lang;
}

export function loadLanguageAsync(lang) {
  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  // If the language hasn't been loaded yet
  // SupChat note: It won't work with `` and ${}. Might just be misconfiguration.
  return import(
    // eslint-disable-next-line prefer-template
    /* webpackChunkName: "lang-[request]" */'@/localization/' + lang
  )
    .then((dictionary) => {
      i18n.setLocaleMessage(lang, dictionary.default);
      loadedLanguages.push(lang);
      return setI18nLanguage(lang);
    });
}
