<template>
  <div>
    <HiddenDepartmentsSelector
      v-if="displayDepartmentSelector"
      blob-key="hiddenDepartmentsOngoing"
    />
    <floating-container
      v-if="chatsFiltered.myChats.length > 0"
      body-class="p-2"
      :title="$tc('message.chatsOngTitle', 1)"
    >
      <b-table
        tbody-tr-class="cursor-pointer text-break"
        hover
        small
        bordered
        fixed
        stacked="sm"
        :items="chatsFiltered.myChats"
        :fields="fields"
        class="mb-0"
        @row-clicked="rowClickHandler"
      >
        <template #cell(visitor_name)="data">
          <span
            v-b-popover.hover.right="data.value"
          >
            {{ trimLength(data.value) }}
          </span>
        </template>
        <template #cell(msgs)="{ item, value: { preview } }">
          <ChatLanguageBadge v-bind="{ chat: item }" />
          <span
            :id="item.id"
            @mouseenter="hoveredChatId = item.id"
            @mouseleave="hoveredChatId = ''"
          >
            {{ /\S/.test(preview) ? preview : $t('message.noVisitorMessages') }}
          </span>
        </template>
      </b-table>
    </floating-container>
    <floating-container
      v-if="chatsFiltered.otherChats.length > 0"
      body-class="p-2"
      :title="$tc('message.chatsOngTitle', 2)"
    >
      <b-table
        tbody-tr-class="cursor-pointer text-break"
        hover
        small
        bordered
        fixed
        stacked="sm"
        :items="chatsFiltered.otherChats"
        :fields="fields"
        class="mb-0"
        @row-clicked="rowClickHandler"
      >
        <template #cell(visitor_name)="data">
          <span
            v-b-popover.hover.right="data.value"
          >
            {{ trimLength(data.value) }}
          </span>
        </template>
        <template #cell(msgs)="{ item, value: { preview } }">
          <ChatLanguageBadge v-bind="{ chat: item }" />
          <span
            :id="item.id"
            @mouseenter="hoveredChatId = item.id"
            @mouseleave="hoveredChatId = ''"
          >
            {{ /\S/.test(preview) ? preview : $t('message.noVisitorMessages') }}
          </span>
        </template>
      </b-table>
    </floating-container>
    <floating-container
      v-if="chatsFiltered.otherChats.length === 0 && chatsFiltered.myChats.length === 0"
      no-body
    >
      <h4 class="py-5 text-center text-muted">
        {{ $t('message.chatsNoOngFound') }}
      </h4>
    </floating-container>
    <hover-chat-preview
      v-if="hoveredChatId"
      :target="hoveredChatId"
      :chat="visibleOngoingChats.find((chat) => chat.id === hoveredChatId)"
      :show="!!hoveredChatId"
      @mouseenter="hoveredChatId = hoveredChatId"
      @mouseleave="hoveredChatId = ''"
    />
    <ChatPreviewModal ref="chatPreviewModal" />
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters, mapState } from 'vuex';

import {
  previewVisitorMessages,
  trimLength,
} from 'supwiz/supchat/generalUtils';
import { chatEvent } from 'supwiz/supchat/constants';

import ChatLanguageBadge from '@/components/ChatLanguageBadge.vue';
import HoverChatPreview from '@/components/HoverChatPreview.vue';
import HiddenDepartmentsSelector from '@/components/HiddenDepartmentsSelector.vue';

export default {
  name: 'OngoingChatsPage',
  components: {
    ChatLanguageBadge,
    HiddenDepartmentsSelector,
    HoverChatPreview,
    ChatPreviewModal: () => import('@/components/chatModal/ChatPreviewModal.vue'),
  },
  data() {
    return {
      hoveredChatId: '',
    };
  },
  computed: {
    ...mapGetters('agent', ['id', 'departmentNameIncludeTenantName']),
    ...mapGetters('chat', ['visibleOngoingChats']),
    ...mapState('chat', ['lastOngoingChatsRefresh']),
    fields() {
      return [
        {
          key: 'active_agents',
          label: this.$tc('vocabulary.agent', 1),
          sortable: true,
          formatter: (obj) => {
            if (!obj) { return ''; }
            return Object.values(obj).join(', ');
          },
        },
        {
          key: 'status', label: this.$t('vocabulary.status'), sortable: true, thStyle: 'width:85px',
        },
        { key: 'visitor_name', label: this.$t('message.visitorName'), sortable: true },
        {
          key: 'department_id',
          label: this.$tc('vocabulary.department', 1),
          sortable: true,
          formatter: this.formatDepartments,
        },
        {
          key: 'waiting_times',
          label: this.$t('message.waitTime'),
          sortable: true,
          formatter: (wt) => {
            let s;
            if (wt.length === 0) { return ''; }
            s = this.minuteString(wt[0]);

            if (wt.length > 1) {
              s = `${s} (${this.minuteString(wt[1])})`;
            }
            return s;
          },
        },
        {
          key: 'total_seconds',
          label: this.$t('message.totalTime'),
          sortable: true,
          formatter: this.minuteString,
          thStyle: 'width:100px',
        },
        {
          key: 'msgs',
          label: this.$t('message.previewOfVisitorMessages'),
          thClass: 'w-25',
          sortable: false,
          formatter: this.previewFormatter,
        },
      ];
    },
    chatsFiltered() {
      const chats = this.visibleOngoingChats;
      const preparedChats = chats.map((chat) => {
        const thisChat = { ...chat };
        const chatAgents = Object.keys(thisChat.active_agents).filter((x) => x !== '');
        if (chatAgents.length === 0) {
          // this may show for a few seconds. The frontend has moved the chat from
          // incoming to ongoing and is awaiting a refresh of ongoing chats
          thisChat.status = this.$t('vocabulary.waiting');
        } else {
          thisChat.status = this.$t('vocabulary.talking');
        }
        thisChat.agent_ids = chatAgents;
        thisChat.my_chat = chatAgents.includes(String(this.id));
        const lastAlign = (this.lastOngoingChatsRefresh || Date.now()) / 1000;
        if (Array.isArray(thisChat.msgs) && thisChat.msgs?.length > 0) {
          thisChat.total_seconds = lastAlign - thisChat.msgs[0].timestamp;
        } else {
          thisChat.total_seconds = 0;
        }
        thisChat.waiting_times = this.waitingTimes(thisChat, lastAlign);
        if (Array.isArray(thisChat.msgs) && thisChat.msgs?.length > 0) {
          thisChat.visitor_name = thisChat?.visitor_display_name
          || this.$t('vocabulary.visitorSingle');
        }

        // eslint-disable-next-line no-underscore-dangle
        if (thisChat.department === 'My chats') thisChat._rowVariant = 'warning';
        return thisChat;
      });
      return {
        myChats: preparedChats.filter((c) => c.my_chat),
        otherChats: preparedChats.filter((c) => !c.my_chat),
      };
    },
    displayDepartmentSelector() {
      return this.departmentNameIncludeTenantName.length > 1;
    },
  },
  created() {
    this.ensureControlSocketSet();
    // force ongoing chats update, bypassing time check
    this.$store.dispatch('chat/refreshAllOngoingChats', { bypassTimeCheck: true });
  },
  methods: {
    ...mapActions('controlSocket', ['ensureControlSocketSet']),
    previewVisitorMessages,
    minuteString(seconds) {
      if (seconds === undefined || seconds === null) {
        return '';
      }
      const d = moment.duration(seconds * 1000);
      const m = Math.floor(d.asMinutes());
      if (seconds === 0) {
        return '0 min.';
      } if (m > 0) {
        return `${m} min.`;
      }
      return '< 1 min.';
    },
    rowClickHandler(clickedChat) {
      if (clickedChat.my_chat) {
        this.$parent.$parent.$refs.chat.$refs.chatInterface.showModal(clickedChat.id);
        return;
      }
      const chatId = clickedChat.id;
      const tenantId = clickedChat.tenant;
      const departmentId = clickedChat.department_id;
      const visitorLanguage = clickedChat.visitor_language;
      this.$refs.chatPreviewModal.displayModal({
        chatId,
        tenantId,
        departmentId,
        visitorLanguage,
      });
    },
    waitingTimes(chat, now) {
      /* return two values where the first one is the time visitor waiting for the reply
      and the second number is the initial waiting time */
      if (!Array.isArray(chat.msgs)) return [0, 0];
      let initialStart;
      if (chat.msgs.length > 0) {
        initialStart = chat.msgs[0].timestamp;
      }

      const waitingTimesArray = [];
      for (const msg of chat.msgs) {
        if (msg && msg.command === chatEvent.JOIN && msg.sender_role === 'agent') {
          const initialWaitingTime = msg.timestamp - initialStart;
          waitingTimesArray.push(initialWaitingTime);
          break;
        }
      }

      for (let i = chat.msgs.length - 1; i > 0; i--) {
        const msg = chat.msgs[i];
        if (msg.sender_role === 'agent' && msg.command === chatEvent.SAY) {
          waitingTimesArray.push(0);
          break;
        }
        if (msg.sender_role === 'visitor' && msg.command === chatEvent.SAY) {
          waitingTimesArray.push(now - msg.timestamp);
          break;
        }
      }

      waitingTimesArray.reverse();
      return waitingTimesArray;
    },
    formatDepartments(chatDep) {
      return this.departmentNameIncludeTenantName
        .find((department) => department.id === chatDep).name;
    },
    previewFormatter(msgs) {
      if (!Array.isArray(msgs)) return { preview: '' };
      return { preview: trimLength(previewVisitorMessages(msgs), 96) };
    },
    trimLength,
  },
};
</script>
