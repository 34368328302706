<template>
  <b-alert
    :show="dismissCountDown"
    dismissible
    :variant="error.variant || 'danger'"
    style="border-color:transparent"
    class="mb-2"
    @dismissed="dismiss()"
    @dismiss-count-down="countDownChanged"
  >
    <p class="my-0">
      {{ message }}
    </p>
    <b-progress
      v-if="error.timer"
      variant="warning"
      :max="error.timer"
      :value="dismissCountDown"
      class="mt-2"
      height="5px"
    />
  </b-alert>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  props: {
    error: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dismissCountDown: this.error.timer || 10,
      hide: false,

    };
  },
  computed: {
    message() {
      let msg = this.error.message;
      let args = null;
      if (Array.isArray(msg)) {
        msg = this.error.message[0];
        args = this.error.message[1];
      }
      const translationFound = this.$te(msg);
      if (translationFound && this.error.timer) {
        if (args) {
          return this.$t(msg, {
            ...args,
            timer: this.dismissCountDown,
          });
        }
        return this.$t(msg, { timer: this.dismissCountDown });
      }
      if (translationFound) {
        return this.$t(msg, args || undefined);
      }
      return msg;
    },
  },
  methods: {
    ...mapMutations('errorDisplay', ['REMOVE_MSG']),
    countDownChanged(seconds) {
      this.dismissCountDown = seconds;
    },
    dismiss() {
      this.dismissCountDown = 0;
      this.REMOVE_MSG({ id: this.error.id });
    },
  },
};
</script>

<style>

</style>
