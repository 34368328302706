import Vue from 'vue';
import {
  getAgentSettings,
  updateAgentSettings,
  getAgentNotificationSettings,
  updateAgentNotificationSettings,
  getAgentBlob,
  updateAgentBlob,
} from '@/api/apiList';

const blobTypes = {
  hiddenDepartmentsIncoming: 'array',
  hiddenDepartmentsOngoing: 'array',
  savedFilters: 'array',
  statusList: 'object',
};

function blobKeyValidator([key, val]) {
  if (!(key in blobTypes)) throw new Error(`${key} as key is not allowed in blob`);
  const reqType = blobTypes[key];
  const valType = typeof val;
  const error = new Error(`${key}s value does not have type ${reqType}`);
  if (reqType === 'array') {
    if (!Array.isArray(val)) throw error;
  } else if (valType !== reqType) throw error;
}

const settingsState = {
  agentSettings: {
    notifications: null,
    preferredLanguage: '',
  },
  blob: {
    hiddenDepartmentsIncoming: [],
    hiddenDepartmentsOngoing: [],
    savedFilters: [],
    statusList: {},
  },
  fetchingState: {},
  browserNotificationsPermission: null,
};

/*
  "blob" structure:
  {
    savedFilters: object[] // stores list of saved filters for filters component
  }
*/

const settingsGetters = {
  notificationEnabled: (state) => (id) => {
    const notifications = state.agentSettings.notifications;
    if (!notifications || !Array.isArray(notifications)) {
      return false;
    }
    const match = notifications.find(({ notification_id: name }) => name === id);
    return match?.enabled;
  },
  getBlobSavedFilters: (state) => state
    .blob?.savedFilters?.filter((filter) => filter?.filterName) || [],
};

const mutations = {
  SET_NOTIFICATIONS(state, notifications) {
    Vue.set(state.agentSettings, 'notifications', notifications);
  },
  SET_FETCHING_STATE(state, { task, isFetching }) {
    Vue.set(state.fetchingState, task, isFetching);
  },
  SET_AGENT_BLOB(state, blob) {
    Object.entries(blob).forEach(([key, val]) => {
      Vue.set(state.blob, key, val);
    });
  },
  SET_AGENT_SETTINGS(state, settingsObj) {
    Object.entries(settingsObj).forEach(([key, val]) => {
      Vue.set(state.agentSettings, key, val);
    });
  },
  SET_NOTIFICATIONS_PERMISSIONS(state, permission) {
    state.browserNotificationsPermission = permission;
  },
};
const actions = {
  async getNotifications({ commit }) {
    try {
      commit('SET_FETCHING_STATE', { task: 'getNotifications', isFetching: true });
      const notifications = await getAgentNotificationSettings();
      commit('SET_NOTIFICATIONS', notifications);
    } catch (error) {
      commit('errorDisplay/ADD_MSG', {
        message: 'errors.unknownError',
        variant: 'danger',
      }, { root: true });
    } finally {
      commit('SET_FETCHING_STATE', { task: 'getNotifications', isFetching: false });
    }
  },
  async updateNotifications({ commit }, notificationsArray) {
    try {
      commit('SET_FETCHING_STATE', { task: 'updateNotifications', isFetching: true });
      await Promise.all(notificationsArray
        .map(({ notification_id: name, enabled, id }) => updateAgentNotificationSettings(
          id,
          {
            notification_id: name,
            enabled,
          },
        )));
      commit('SET_NOTIFICATIONS', notificationsArray);
    } catch (error) {
      commit('errorDisplay/ADD_MSG', {
        message: 'errors.unknownError',
        variant: 'danger',
      }, { root: true });
    } finally {
      commit('SET_FETCHING_STATE', { task: 'updateNotifications', isFetching: false });
    }
  },
  async getAgentBlob({ commit }) {
    const task = 'getAgentBlob';
    try {
      commit('SET_FETCHING_STATE', { task, isFetching: true });
      const { data } = await getAgentBlob();
      commit('SET_AGENT_BLOB', data);
    } catch (error) {
      commit('errorDisplay/ADD_MSG', {
        message: 'errors.unknownError',
        variant: 'danger',
      }, { root: true });
    } finally {
      commit('SET_FETCHING_STATE', { task, isFetching: false });
    }
  },
  async updateAgentBlob({ commit }, updatedBlob) {
    try {
      commit('SET_FETCHING_STATE', { task: 'updateAgentBlob', isFetching: true });
      await updateAgentBlob(updatedBlob);
    } catch (error) {
      commit('errorDisplay/ADD_MSG', {
        message: 'errors.unknownError',
        variant: 'danger',
      }, { root: true });
    } finally {
      commit('SET_FETCHING_STATE', { task: 'updateAgentBlob', isFetching: false });
    }
  },
  async writeToAgentBlob({ state, commit, dispatch }, data) {
    const currentBlob = state.blob || {};
    const updatedBlob = Object.assign(currentBlob, data);
    try {
      Object.entries(updatedBlob).forEach(blobKeyValidator);
      await dispatch('updateAgentBlob', updatedBlob);
      commit('SET_AGENT_BLOB', updatedBlob);
    } catch (error) {
      Vue.$log.error(error);
      commit('errorDisplay/ADD_MSG', {
        message: 'errors.blobError',
        variant: 'danger',
      }, { root: true });
      dispatch('getAgentBlob');
    }
  },
  async getAgentSettings({ commit }) {
    const task = 'getAgentSettings';
    try {
      commit('SET_FETCHING_STATE', { task, isFetching: true });
      const {
        preferred_language: preferredLanguage,
      } = await getAgentSettings();
      commit('SET_AGENT_SETTINGS', { preferredLanguage });
    } catch (error) {
      commit('errorDisplay/ADD_MSG', {
        message: 'errors.unknownError',
        variant: 'danger',
      }, { root: true });
    } finally {
      commit('SET_FETCHING_STATE', { task, isFetching: false });
    }
  },
  async updateAgentSettings({ commit }, { preferredLanguage }) {
    const task = 'updateAgentSettings';
    try {
      commit('SET_FETCHING_STATE', { task, isFetching: true });
      await updateAgentSettings({ preferred_language: preferredLanguage });
      commit('SET_AGENT_SETTINGS', { preferredLanguage });
    } catch (error) {
      commit('errorDisplay/ADD_MSG', {
        message: 'errors.unknownError',
        variant: 'danger',
      }, { root: true });
    } finally {
      commit('SET_FETCHING_STATE', { task, isFetching: false });
    }
  },
  ensureAgentSettings({ state, dispatch }) {
    if (!state.fetchingState.getNotifications && state.agentSettings.notifications === null) {
      dispatch('getNotifications');
    }
    if (!state.fetchingState.getAgentSettings && state.agentSettings.preferredLanguage === '') {
      dispatch('getAgentSettings');
    }
    if (!state.fetchingState.getAgentBlob && state.agentSettings.notifications === null) {
      dispatch('getAgentBlob');
    }
  },
};

export default {
  namespaced: true,
  state: settingsState,
  getters: settingsGetters,
  mutations,
  actions,
};
