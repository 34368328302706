import Vue from 'vue';
import Vuex from 'vuex';

import templateStore from 'supwiz/components/template/templateStore';

import { getFeatureFlags } from '@/api/apiList';
import buildInfo from '@/assets/buildinfo.json';
import packageJson from '@/../package.json';

import agent from './modules/agent/index';
import systemAgents from './modules/systemAgents';
import chat from './modules/chat/index';
import tenants from './modules/tenants/index';
import departments from './modules/department/index';
import controlSocket from './modules/controlSocket';
import integrations from './modules/tenants/integrations';
import analytics from './modules/analytics';
import status from './modules/status';
import insights from './modules/insights';
import errorDisplay from './modules/errorDisplay';
import entryWidgets from './modules/entryWidgets';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isSidebarOpen: false,
    supchatVersion: {
      version: packageJson.version,
      buildInfo: buildInfo || undefined,
    },
    featureFlags: {},
  },
  getters: {
    supchatVersion: (state) => state.supchatVersion,
  },
  mutations: {
    SET_SIDEBAR_OPEN(state, payload) {
      state.isSidebarOpen = payload;
    },
    SET_FEATURE_FLAGS(state, payload) {
      state.featureFlags = payload;
    },
  },
  actions: {
    async updateSidebarState({ commit }, payload) {
      commit('SET_SIDEBAR_OPEN', payload);
    },
    async fetchFeatureFlags({ commit }) {
      const featureFlags = await getFeatureFlags();
      commit('SET_FEATURE_FLAGS', featureFlags);
    },
    async ensureFeatureFlags({ state, dispatch }) {
      if (Object.keys(state.featureFlags).length) return;
      dispatch('fetchFeatureFlags');
    },
  },

  modules: {
    agent,
    systemAgents,
    chat,
    tenants,
    departments,
    controlSocket,
    integrations,
    analytics,
    status,
    insights,
    errorDisplay,
    templateStore,
    entryWidgets,
  },
});
