import Vue from 'vue';

import {
  getSupSearchOpenAIConfig,
  setSupSearchOpenAIConfig,
} from '@/api/apiList';

const gptReplyState = {
  fetchingState: {},
  config: {},
};

const gptReplyGetters = {
  isArticleReplyEnabled: (state) => (tenantId) => state.config?.[tenantId]?.enabled,
};

const mutations = {
  SET_FETCHING_STATUS(state, { tenantId, status }) {
    Vue.set(state.fetchingState, tenantId, status);
  },
  SET_CONFIG(state, {
    tenantId, enabled, maxMsgs,
  }) {
    if (!(tenantId in state.config)) Vue.set(state.config, tenantId, {});
    Vue.set(state.config, tenantId, { enabled, maxMsgs });
  },
};

const actions = {
  async ensureConfig({ state, dispatch }, tenantId) {
    if (!(tenantId in state)) await dispatch('getConfig', tenantId);
  },
  async getConfig({ commit, rootState }, tenantId) {
    const {
      AI_COPILOT_SUPSEARCH_ENABLED,
      AI_COPILOT_GPT_ENABLED,
    } = rootState.featureFlags;
    if (!AI_COPILOT_SUPSEARCH_ENABLED || !AI_COPILOT_GPT_ENABLED) return;
    try {
      commit('SET_FETCHING_STATUS', { tenantId, status: true });
      const {
        allow_autogenerated_responses: enabled,
        max_chat_message_context: maxMsgs,
      } = await getSupSearchOpenAIConfig(tenantId);
      commit('SET_CONFIG', {
        tenantId, enabled, maxMsgs,
      });
    } finally {
      commit('SET_FETCHING_STATUS', { tenantId, status: false });
    }
  },
  async setConfig({ commit, rootState }, {
    tenantId, enabled,
    maxMsgs,
  }) {
    const {
      AI_COPILOT_SUPSEARCH_ENABLED,
      AI_COPILOT_GPT_ENABLED,
    } = rootState.featureFlags;
    if (!AI_COPILOT_SUPSEARCH_ENABLED || !AI_COPILOT_GPT_ENABLED) return;
    try {
      commit('SET_FETCHING_STATUS', { tenantId, status: true });
      const params = {
        tenantId,
        enabled,
        maxMsgs,
      };
      await setSupSearchOpenAIConfig(params);
    } finally {
      commit('SET_FETCHING_STATUS', { tenantId, status: false });
    }
  },
};

export default {
  namespaced: true,
  state: gptReplyState,
  getters: gptReplyGetters,
  mutations,
  actions,
};
