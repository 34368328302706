/* eslint-disable camelcase */
import fetchData, { SUPCHAT_API } from 'supwiz/supchat/httpRequest';
import { ssoConfigIds } from 'supwiz/util/sso';

export function checkAgentAuth(baseURL = null) {
  return fetchData({ url: '/control/is-agent-logged-in', method: 'GET', baseURL });
}

export function getAgentTenants(baseURL = null) {
  return fetchData({ url: '/control/agent-tenants', method: 'GET', baseURL });
}

export function getTags(baseURL = null) {
  return fetchData({ url: '/control/tag-options', method: 'GET', baseURL });
}

export function getSystemTenants(baseURL = null) {
  return fetchData({ url: '/control/tenants', method: 'GET', baseURL });
}

export function addSystemTenant(tenantName, baseURL = null) {
  return fetchData({
    url: '/control/tenants', data: { tenant_name: tenantName }, method: 'POST', baseURL,
  });
}

export function deleteSystemTenant(tenantId, baseURL = null) {
  return fetchData({
    url: '/control/tenants', data: { tenant_id: tenantId }, method: 'DELETE', baseURL,
  });
}

export function getTenantConfigs(tenantId, tag = '', baseURL = null) {
  return fetchData({
    url: '/control/tenant-config', data: { tenant_id: tenantId, tag }, method: 'GET', baseURL,
  });
}

export function setTenantConfig(tenantId, tag, config, baseURL = null) {
  return fetchData({
    url: '/control/tenant-config',
    data: { tenant_id: tenantId, tag, config },
    method: 'POST',
    baseURL,
  });
}

export function getTenantConfig(tenantId, tag, baseURL = null) {
  return fetchData({
    url: `/control/tenant-config/${tag}/${tenantId}`, method: 'GET', baseURL,
  });
}

export function updateTenantConfig(tenantId, tag, config, baseURL = null) {
  return fetchData({
    url: `/control/tenant-config/${tag}/${tenantId}`, data: config, method: 'PATCH', baseURL,
  });
}

export function getAnonymizers(tenantId, baseURL = null) {
  return fetchData({
    url: '/control/anonymizer-configs', data: { tenant_id: tenantId }, method: 'GET', baseURL,
  });
}

export function getAnonymizationAffectedChats(tenantId, baseURL = null) {
  return fetchData({
    url: '/control/agent/tenant-chat-count', data: { tenant_id: tenantId }, method: 'GET', baseURL,
  });
}

export function addAnonymizer({
  type, data, replacement, tenantId,
}, baseURL = null) {
  return fetchData({
    url: '/control/anonymizer-configs',
    data: {
      type, data, replacement, tenant_id: tenantId,
    },
    method: 'POST',
    baseURL,
  });
}

export function editAnonymizer({
  id, type, data, replacement,
}, baseURL = null) {
  return fetchData({
    url: '/control/anonymizer-configs',
    data: {
      id, type, data, replacement,
    },
    method: 'PUT',
    baseURL,
  });
}

export function deleteAnonymizer(id, baseURL = null) {
  return fetchData({
    url: '/control/anonymizer-configs', data: { id }, method: 'DELETE', baseURL,
  });
}

export function getWidgetConfigs(tenantId, baseURL = null) {
  return fetchData({
    url: '/control/widget-configs',
    data: { tenant_id: tenantId },
    method: 'GET',
    baseURL,
  });
}

export function addWidgetConfig(widgetObject, baseURL = null) {
  return fetchData({
    url: '/control/widget-configs',
    data: widgetObject,
    method: 'POST',
    baseURL,
  });
}

export function editWidgetConfig(widgetObject, baseURL = null) {
  return fetchData({
    url: `/control/widget-configs/${widgetObject.id}`,
    data: widgetObject,
    method: 'PUT',
    baseURL,
  });
}

export function deleteWidgetConfig(pk, baseURL = null) {
  return fetchData({ url: `/control/widget-configs/${pk}`, method: 'DELETE', baseURL });
}

export function getInactivityConfig(tenantId, baseURL = null) {
  return fetchData({
    url: '/control/inactivity-message-config',
    data: { tenant_id: tenantId },
    method: 'GET',
    baseURL,
  });
}

export function editInactivityConfig(config, baseURL = null) {
  return fetchData({
    url: '/control/inactivity-message-config',
    data: config,
    method: 'PUT',
    baseURL,
  });
}

export function getAgentDepartments(baseURL = null) {
  return fetchData({ url: '/control/agent-departments', method: 'GET', baseURL });
}

export function getDepartments(baseURL = null) {
  return fetchData({
    url: '/control/departments/', method: 'GET', baseURL,
  });
}

export function addDepartment({ tenantId, departmentName, selectableByVisitor }, baseURL = null) {
  return fetchData({
    url: '/control/departments/',
    data: {
      name: departmentName,
      tenant_id: tenantId,
      selectable_by_visitor: selectableByVisitor,
    },
    method: 'POST',
    baseURL,
  });
}

export function updateDepartment({
  departmentId,
  selectableByVisitor,
  kpiChatResponseTime,
  kpiTotalWaitingTime,
  canBeTransferredTo,
  dataField,
}, baseURL = null) {
  return fetchData({
    url: `/control/departments/${departmentId}/`,
    data: {
      selectable_by_visitor: selectableByVisitor,
      kpi_chat_response_time: kpiChatResponseTime,
      kpi_total_waiting_time: kpiTotalWaitingTime,
      can_be_transferred_to: canBeTransferredTo,
      data_field: dataField,
    },
    method: 'PATCH',
    baseURL,
  });
}

export function deleteDepartment(departmentId, baseURL = null) {
  return fetchData({
    url: `/control/departments/${departmentId}/`,
    method: 'DELETE',
    baseURL,
  });
}

export function getInactivtyTimeouts(baseURL = null) {
  return fetchData({ url: '/control/inactivity-timeout', method: 'GET', baseURL });
}

export function getAgents(baseURL = null) {
  return fetchData({ url: '/control/agents', method: 'GET', baseURL });
}

export function addAgent({
  username, password, is_bot: isBot, email,
}, baseURL = null) {
  return fetchData({
    url: '/control/agents',
    data: {
      username, password, is_bot: isBot, email,
    },
    method: 'POST',
    baseURL,
  });
}

export function updateAgent(agent, baseURL = null) {
  return fetchData({
    url: '/control/agents',
    data: agent,
    method: 'PUT',
    baseURL,
  });
}

export function deleteAgent(id, baseURL = null) {
  return fetchData({
    url: '/control/agents', data: { id }, method: 'DELETE', baseURL,
  });
}

/* Get the personal information of the current agent */
export function getAgentInfo(baseURL = null) {
  return fetchData({ url: '/control/current-agent', method: 'GET', baseURL });
}

export function getAgentTodayStats(data, baseURL = null) {
  return fetchData({
    url: '/control/agent-today-stats', data, method: 'GET', baseURL,
  });
}

export function getCannedMsgs(tenant, baseURL = null) {
  return fetchData({
    url: '/canned-messages/tenant-msgs', data: { tenant }, method: 'GET', baseURL,
  });
}

export function addCannedMessage(
  tenant,
  content,
  subject,
  eligible_for_prediction,
  baseURL = null,
) {
  return fetchData(
    {
      url: '/canned-messages/tenant-msgs',
      data: {
        tenant, content, subject, eligible_for_prediction,
      },
      method: 'POST',
      baseURL,
    },
  );
}

export function deleteCannedMessage(tenant, id, baseURL = null) {
  return fetchData({
    url: '/canned-messages/tenant-msgs', data: { tenant, id }, method: 'DELETE', baseURL,
  });
}

export function editCannedMessage(
  tenant,
  id,
  content,
  subject,
  eligible_for_prediction,
  baseURL = null,
) {
  return fetchData({
    url: '/canned-messages/tenant-msgs',
    data: {
      tenant, id, content, subject, eligible_for_prediction,
    },
    method: 'PUT',
    baseURL,
  });
}

export function getDepartmentCannedMsgs(departmentId, baseURL = null) {
  return fetchData({
    url: '/canned-messages/department-msgs',
    data: { department_id: departmentId },
    method: 'GET',
    baseURL,
  });
}

export function addDepartmentCannedMessage(departmentId, content, subject,
  eligible_for_prediction, baseURL = null) {
  return fetchData(
    {
      url: '/canned-messages/department-msgs',
      data: {
        department_id: departmentId,
        content,
        subject,
        eligible_for_prediction,
      },
      method: 'POST',
      baseURL,
    },
  );
}

export function deleteDepartmentCannedMessage(departmentId, id, baseURL = null) {
  return fetchData({
    url: '/canned-messages/department-msgs',
    data: { department_id: departmentId, id },
    method: 'DELETE',
    baseURL,
  });
}

export function editDepartmentCannedMessage(departmentId, id, content, subject,
  eligible_for_prediction, baseURL = null) {
  return fetchData({
    url: '/canned-messages/department-msgs',
    data: {
      department_id: departmentId,
      id,
      content,
      subject,
      eligible_for_prediction,
    },
    method: 'PUT',
    baseURL,
  });
}

export function getMyChats(chatId, baseURL = null) {
  return fetchData({
    url: '/control/my-chats', data: { chat_id: chatId, show_closed_chat: true }, method: 'GET', baseURL,
  });
}

export function getChatsMeta(data, baseURL = null) {
  return fetchData({
    url: '/control/closed-chats-meta', data, method: 'POST', baseURL,
  });
}
export function getChatsMetaNext(data, baseURL) {
  return fetchData({
    url: '', data, method: 'POST', baseURL,
  });
}

export function deleteChat(data, baseURL) {
  return fetchData({
    url: '/control/closed-chats-meta', data, method: 'DELETE', baseURL,
  });
}

export function exportArchiveChats(data, baseURL = null) {
  return fetchData({
    url: '/control/closed-chats-export', data, method: 'POST', baseURL,
  });
}

export function getClosedChatMeta(chatId, baseURL = null) {
  return fetchData({
    url: '/control/closed-chat-meta', data: { chat_id: chatId }, method: 'GET', baseURL,
  });
}

export function getChatLog(chatId, baseURL = null) {
  return fetchData({
    url: '/control/chatlog', data: { id: chatId }, method: 'GET', baseURL,
  });
}

export function getManagedAgents(baseURL = null) {
  return fetchData({ url: '/control/managed-agents', method: 'GET', baseURL });
}

export function getQueueStats(baseURL = null) {
  return fetchData({ url: '/control/queue-stats', method: 'GET', baseURL });
}

export function getMyQueue({ filter, includeMessages = false }, baseURL = null) {
  return fetchData({
    url: '/control/my-queues', data: { filter, include_messages: includeMessages }, method: 'GET', baseURL,
  });
}

export function getVisitorInfoOfChat(chatId, anonymize = false, baseURL = null) {
  return fetchData({
    url: '/control/visitor-info', data: { chat_id: chatId, anonymize }, method: 'GET', baseURL,
  });
}

export function isSSOInstalled() {
  return fetch(`https://${SUPCHAT_API}/control/sso/enabled/${ssoConfigIds.CUSTOMER}`).then(
    (response) => response.text(),
  ).then(
    (value) => value.toLowerCase() === 'true',
  );
}

export function getADConfig(tenantId, baseURL = null) {
  return fetchData({
    url: '/control/tenant-config',
    data: { tenant_id: tenantId, field: 'general_ad_group_ids' },
    method: 'GET',
    baseURL,
  });
}

export function updateADConfig({ tenantId, value }, baseURL = null) {
  return fetchData({
    url: '/control/tenant-config',
    data: { tenant_id: tenantId, field: 'general_ad_group_ids', value },
    method: 'PUT',
    baseURL,
  });
}

export function getADSysAdminGroups(baseURL = null) {
  return fetchData({
    url: '/control/admin-sso-groups-config',
    method: 'GET',
    baseURL,
  });
}
export function updateADSysAdminGroups(data, baseURL = null) {
  return fetchData({
    url: '/control/admin-sso-groups-config',
    data: { data },
    method: 'PUT',
    baseURL,
  });
}

export function loginAgent(data, baseURL = null) {
  return fetchData({
    url: '/control/login', data, method: 'POST', baseURL,
  });
}

export function logoutAgent(redirectURL, baseURL = null) {
  return fetchData({
    url: '/control/logout', data: { redirectURL }, method: 'POST', baseURL,
  });
}

export function pickupChat(data, baseURL = null) {
  return fetchData({
    url: '/control/pickup-chat', data, method: 'POST', baseURL,
  });
}

export function startChat(data, baseURL = null) {
  return fetchData({
    url: '/control/join-chat', data, method: 'POST', baseURL,
  });
}

export function transferChat(data, baseURL = null) {
  return fetchData({
    url: '/control/transfer-chat', data, method: 'POST', baseURL,
  });
}

export function sendCustomVisitorInfo(data, baseURL = null) {
  return fetchData({
    url: '/control/custom-visitor-info', data, method: 'POST', baseURL,
  });
}

export function listIntegrations(tenantId, baseURL = null) {
  const data = {};
  if (tenantId) {
    data.tenant_id = tenantId;
  }
  return fetchData({
    url: '/control/integrations', data, method: 'GET', baseURL,
  });
}

export function createIntegration(data, baseURL = null) {
  return fetchData({
    url: '/control/integrations', data, method: 'POST', baseURL,
  });
}

export function getIntegration(integrationId, baseURL = null) {
  return fetchData({
    url: `/control/integrations/${integrationId}`, method: 'GET', baseURL,
  });
}

export function modifyIntegration(data, baseURL = null) {
  return fetchData({
    url: `/control/integrations/${data.id}`, data, method: 'PUT', baseURL,
  });
}

export function deleteIntegration(integrationId, baseURL = null) {
  return fetchData({
    url: `/control/integrations/${integrationId}`, method: 'DELETE', baseURL,
  });
}

export function applyIntegration(integrationId, parameters, baseURL = null) {
  return fetchData({
    url: `/control/apply-integration/${integrationId}`, data: parameters, method: 'POST', baseURL,
  });
}

export function testIntegration(data, baseURL = null) {
  return fetchData({
    url: '/control/apply-integration-no-database', data, method: 'POST', baseURL,
  });
}

export function setAgentStatus({ agentId, status }, baseURL = null) {
  return fetchData({
    url: `/control/agent-status/${agentId}`,
    method: 'PUT',
    data: { status },
    baseURL,
  });
}

export function getStatusOverview(baseURL = null) {
  return fetchData({
    url: '/control/agent-status-overview',
    method: 'GET',
    baseURL,
  });
}

export function getDepartmentStatusOverview(baseURL = null) {
  return fetchData({
    url: '/control/department-status-for-ui',
    method: 'GET',
    baseURL,
  });
}

export function setCustomizedTranslations({ config }, baseURL = null) {
  return fetchData({
    url: '/control/translation-provider-config/1/',
    method: 'PUT',
    data: { config },
    baseURL,
  });
}

export function getCustomizedTranslations(baseURL = null) {
  return fetchData({
    url: '/control/translation-provider-config/1/',
    method: 'GET',
    data: {},
    baseURL,
  });
}

export function setChatTranslationLanguage({ language, chatId }, baseURL = null) {
  return fetchData({
    url: '/control/agent/set-visitor-language',
    method: 'POST',
    data: {
      chat_id: chatId,
      language,
    },
    baseURL,
  });
}

export function disableChatTranslation({ chatId, enable }, baseURL = null) {
  return fetchData({
    url: '/control/agent/toggle-auto-translation',
    method: 'POST',
    data: {
      chat_id: chatId,
      auto_translation_enabled: enable,
    },
    baseURL,
  });
}

export function translateMessage({
  chatId, text, fromLanguage, toLanguage,
}, baseURL = null) {
  return fetchData({
    url: '/control/agent/translate',
    method: 'POST',
    data: {
      chat_id: chatId,
      text,
      from_language: fromLanguage,
      to_language: toLanguage,
    },
    baseURL,
  });
}

export function getLanguages(baseURL = null) {
  return fetchData({
    url: '/control/get-supported-languages',
    method: 'GET',
    baseURL,
  });
}

export function getTenantLanguagesSetup({ isAgent, tenantId }, baseURL = null) {
  return fetchData({
    url: `/control/${isAgent ? 'agent' : 'manager'}/tenant-language-config/${tenantId}/`,
    method: 'GET',
    baseURL,
  });
}

export function getDepartmentLanguagesSetup({ isAgent, departmentId }, baseURL = null) {
  return fetchData({
    url: `/control/${isAgent ? 'agent' : 'manager'}/department-language-config/${departmentId}/`,
    method: 'GET',
    baseURL,
  });
}

export function updateTenantLanguagesSetup({
  tenantId,
  visitorLanguages,
  agentLanguages,
  defaultDepartmentLanguage,
}, baseURL = null) {
  const data = {
    visitor_languages: visitorLanguages,
    agent_languages: agentLanguages,
    default_department_language: defaultDepartmentLanguage,
  };
  return fetchData({
    url: `/control/manager/tenant-language-config/${tenantId}/`,
    method: 'PUT',
    data,
    baseURL,
  });
}

export function updateDepartmentLanguagesSetup({
  departmentId,
  visitorLanguagesOverride,
  agentLanguagesOverride,
  defaultDepartmentLanguageOverride,
}, baseURL = null) {
  const data = {
    visitor_languages_override: visitorLanguagesOverride,
    agent_languages_override: agentLanguagesOverride,
    default_department_language_override: defaultDepartmentLanguageOverride,
  };
  return fetchData({
    url: `/control/manager/department-language-config/${departmentId}/`,
    method: 'PUT',
    data,
    baseURL,
  });
}

export function getTenantTranslationsSetup({ isAgent, tenantId }, baseURL = null) {
  return fetchData({
    url: `/control/${isAgent ? 'agent' : 'manager'}/tenant-translation-config/${tenantId}/`,
    method: 'GET',
    baseURL,
  });
}

export function getDepartmentTranslationsSetup({ isAgent, departmentId }, baseURL = null) {
  return fetchData({
    url: `/control/${isAgent ? 'agent' : 'manager'}/department-translation-config/${departmentId}/`,
    method: 'GET',
    baseURL,
  });
}

export function updateTenantTranslationsSetup({
  tenantId,
  autoTranslationEnabled,
  translationEnabled,
}, baseURL = null) {
  const data = {
    auto_translation_enabled: autoTranslationEnabled,
    translation_enabled: translationEnabled,
  };
  return fetchData({
    url: `/control/manager/tenant-translation-config/${tenantId}/`,
    method: 'PUT',
    data,
    baseURL,
  });
}

export function updateDepartmentTranslationsSetup({
  departmentId,
  autoTranslationEnabledOverride,
}, baseURL = null) {
  const data = {
    auto_translation_enabled_override: autoTranslationEnabledOverride,
  };
  return fetchData({
    url: `/control/manager/department-translation-config/${departmentId}/`,
    method: 'PUT',
    data,
    baseURL,
  });
}

export function listDashboards(baseURL = null) {
  return fetchData({
    url: '/control/dashboards', method: 'GET', baseURL,
  });
}

export function createDashboard({ dashboard }, baseURL = null) {
  return fetchData({
    url: '/control/dashboards', data: dashboard, method: 'POST', baseURL,
  });
}

export function modifyDashboard({ dashboard }, baseURL = null) {
  return fetchData({
    url: `/control/dashboards/${dashboard.id}`, data: dashboard, method: 'PATCH', baseURL,
  });
}

export function deleteDashboard({ dashboardId }, baseURL = null) {
  return fetchData({
    url: `/control/dashboards/${dashboardId}`, method: 'DELETE', baseURL,
  });
}

export function listDashboardGraphs(baseURL = null) {
  return fetchData({
    url: '/control/dashboard-graphs', method: 'GET', baseURL,
  });
}

export function createDashboardGraph({ graph }, baseURL = null) {
  return fetchData({
    url: '/control/dashboard-graphs', data: graph, method: 'POST', baseURL,
  });
}

export function initializeDashboardGraph(data, baseURL = null) {
  return fetchData({
    url: '/control/dashboard-graph-initialize', data, method: 'POST', baseURL,
  });
}

export function modifyDashboardGraph({ graph }, baseURL = null) {
  return fetchData({
    url: `/control/dashboard-graphs/${graph.id}`, data: graph, method: 'PATCH', baseURL,
  });
}

export function deleteDashboardGraph({ graphId }, baseURL = null) {
  return fetchData({
    url: `/control/dashboard-graphs/${graphId}`, method: 'DELETE', baseURL,
  });
}

export function orderDashboardGraphs({ dashboardId, graphIds }, baseURL = null) {
  return fetchData({
    url: `/control/order-dashboard-graphs/${dashboardId}`,
    method: 'POST',
    data: { graphs: graphIds },
    baseURL,
  });
}

export function getDashboardGraphChartData(data, baseURL = null) {
  return fetchData({
    url: '/control/dashboard-graph-chart-data', data, method: 'GET', baseURL,
  });
}

export function getInsightsChatExamples(data, baseURL = null) {
  return fetchData({
    url: '/control/insights-chat-examples', data, method: 'GET', baseURL,
  });
}

export function getFuzzySupportedLanguages(tenantId, baseURL = null) {
  return fetchData({
    url: '/control/insights-fuzzy-logic-languages',
    data: { tenant_id: tenantId },
    method: 'GET',
    baseURL,
  });
}

export function insightsTestMatching(data, baseURL = null) {
  return fetchData({
    url: '/control/insights-match-test',
    data,
    method: 'POST',
    baseURL,
  });
}

export function testAgentPassword(data, baseURL = null) {
  return fetchData({
    url: '/control/agent-password-validation', data, method: 'POST', baseURL,
  });
}

export function getAnalyticsReport(reportId = '', baseURL = null) {
  return fetchData({
    url: `/control/dashboard/report/${reportId}/`, method: 'GET', baseURL,
  });
}

export function createAnalyticsReport(data, baseURL = null) {
  return fetchData({
    url: '/control/dashboard/report/', data, method: 'POST', baseURL,
  });
}
export function updateAnalyticsReport(reportId, data, baseURL = null) {
  return fetchData({
    url: `/control/dashboard/report/${reportId}/`, data, method: 'PATCH', baseURL,
  });
}

export function deleteAnalyticsReport(reportId = '', baseURL = null) {
  return fetchData({
    url: `/control/dashboard/report/${reportId}/`, method: 'DELETE', baseURL,
  });
}

export function getAnalyticsReportBox(boxId = '', url = '', baseURL = null) {
  return fetchData({
    url: url || `/control/dashboard/box/${boxId}`, method: 'GET', baseURL,
  });
}

export function addAnalyticsReportBox(data, baseURL = null) {
  return fetchData({
    url: '/control/dashboard/box/', data, method: 'POST', baseURL,
  });
}

export function updateAnalyticsReportBox(boxId, data, baseURL = null) {
  return fetchData({
    url: `/control/dashboard/box/${boxId}/`, data, method: 'PATCH', baseURL,
  });
}

export function deleteAnalyticsReportBox(boxId, baseURL = null) {
  return fetchData({
    url: `/control/dashboard/box/${boxId}/`, method: 'DELETE', baseURL,
  });
}

export function getMetricData(data, baseURL = null) {
  return fetchData({
    url: '/control/dashboard/data', data, method: 'POST', baseURL,
  });
}

export function getInsightsCategories(categoryId = '', baseURL = null) {
  return fetchData({
    url: `/control/insights-category/${categoryId}/`, method: 'GET', baseURL,
  });
}

export function addInsightsCategories(data, baseURL = null) {
  return fetchData({
    url: '/control/insights-category/', data, method: 'POST', baseURL,
  });
}

export function updateInsightsCategories(categoryId, data, baseURL = null) {
  return fetchData({
    url: `/control/insights-category/${categoryId}/`, data, method: 'PATCH', baseURL,
  });
}

export function deleteInsightsCategories(categoryId, baseURL = null) {
  return fetchData({
    url: `/control/insights-category/${categoryId}/`, method: 'DELETE', baseURL,
  });
}

export function getIntegrationSecrets(secretId = '', baseURL = null) {
  return fetchData({
    url: `/control/agent/secret/${secretId}/`, method: 'GET', baseURL,
  });
}

export function addIntegrationSecret(data, baseURL = null) {
  return fetchData({
    url: '/control/agent/secret/', data, method: 'POST', baseURL,
  });
}

export function updateIntegrationSecret(secretId, data, baseURL = null) {
  return fetchData({
    url: `/control/agent/secret/${secretId}/`, data, method: 'PATCH', baseURL,
  });
}

export function deleteIntegrationSecret(secretId, baseURL = null) {
  return fetchData({
    url: `/control/agent/secret/${secretId}/`, method: 'DELETE', baseURL,
  });
}
export function getFeatureFlags(baseURL = null) {
  return fetchData({
    url: '/main/get_feature_flags', method: 'GET', baseURL,
  });
}

export function getHistory(chatId, baseURL = null) {
  const url = '/control/ongoing-chat-msgs';
  return fetchData({
    url, data: { chat_id: chatId }, method: 'GET', baseURL,
  });
}

export function endChatSession(chatSocket, chatId, baseURL = null) {
  return fetchData({
    url: '/control/agent-end-chat', data: { chat_id: chatId }, method: 'POST', baseURL,
  });
}

export function uploadFile(fileUpload, baseURL = null) {
  return fetchData({
    url: '/control/file-upload/', fileUpload, method: 'POST', baseURL,
  });
}

export function getVisitorFileUpload(fileUuid, baseURL = null) {
  return fetchData({
    url: `/control/visitor-file-upload/${fileUuid}/`, method: 'GET', baseURL,
  });
}

export function getAutoAssignConfig(tenantId, baseURL = null) {
  return fetchData({
    url: `/control/tenant-config/auto-assign/${tenantId}`, method: 'GET', baseURL,
  });
}

export function updateAutoAssignConfig(tenantId, data, baseURL = null) {
  return fetchData({
    url: `/control/tenant-config/auto-assign/${tenantId}`, data, method: 'PATCH', baseURL,
  });
}

export function getAgentSettings(baseURL = null) {
  return fetchData({
    url: '/control/agent/setting', method: 'GET', baseURL,
  });
}
export function updateAgentSettings(data, baseURL = null) {
  return fetchData({
    url: '/control/agent/setting', data, method: 'PATCH', baseURL,
  });
}

export function getAgentNotificationSettings(baseURL = null) {
  return fetchData({
    url: '/control/agent/setting-notification/', method: 'GET', baseURL,
  });
}

export function updateAgentNotificationSettings(id, data, baseURL = null) {
  return fetchData({
    url: `/control/agent/setting-notification/${id}/`,
    data,
    method: 'PATCH',
    baseURL,
  });
}

export function getOpeningHourRules(nextUrl = '', ruleId = null, baseURL = null) {
  const ruleToGet = ruleId !== null ? `${ruleId}/` : '';
  return fetchData({
    url: nextUrl || `/control/opening-hours-rule/${ruleToGet}`,
    method: 'GET',
    baseURL,
  });
}

export function createOpeningHourRules(data, baseURL = null) {
  return fetchData({
    url: '/control/opening-hours-rule/',
    method: 'POST',
    data,
    baseURL,
  });
}

export function updateOpeningHourRules(ruleId, data, baseURL = null) {
  return fetchData({
    url: `/control/opening-hours-rule/${ruleId}/`,
    method: 'PATCH',
    data,
    baseURL,
  });
}
export function deleteOpeningHourRules(ruleId, baseURL = null) {
  return fetchData({
    url: `/control/opening-hours-rule/${ruleId}/`,
    method: 'DELETE',
    baseURL,
  });
}

export function getDepartmentOpeningHourRules(departmentId, baseURL = null) {
  return fetchData({
    url: `/control/opening-hours-rule-department/${departmentId}/`,
    method: 'GET',
    baseURL,
  });
}

export function updateDepartmentOpeningHourRules(departmentId, data, baseURL = null) {
  return fetchData({
    url: `/control/opening-hours-rule-department/${departmentId}/apply_rules/`,
    method: 'POST',
    data,
    baseURL,
  });
}

// also used by transfer menu
export function getOpeningHoursPreview(tenantId, startDate, days = 7, baseURL = null) {
  return fetchData({
    url: `/control/next-opening-hours?tenant_id=${tenantId}&days=${days}&start_time=${startDate}`,
    method: 'GET',
    baseURL,
  });
}

export function getAgentBlob(baseURL = null) {
  return fetchData({
    url: '/control/agent/data-blob',
    method: 'GET',
    baseURL,
  });
}

export function updateAgentBlob(data, baseURL = null) {
  return fetchData({
    url: '/control/agent/data-blob',
    method: 'POST',
    data: { data },
    baseURL,
  });
}

export function getAllUsersForExport(baseURL = null) {
  return fetchData({
    url: '/control/full-list-of-agents',
    method: 'GET',
    baseURL,
  });
}

export function getUserAuthTokens(username, baseURL = null) {
  return fetchData({
    url: '/control/agent/auth-keys',
    data: { selected_user: username },
    method: 'GET',
    baseURL,
  });
}
export function createUserAuthToken(data, baseURL = null) {
  return fetchData({
    url: '/control/agent/auth-keys',
    data,
    method: 'POST',
    baseURL,
  });
}
export function deleteUserAuthToken(digest, baseURL = null) {
  return fetchData({
    url: '/control/agent/auth-keys',
    data: { digest },
    method: 'DELETE',
    baseURL,
  });
}
/**
 * Notices
 */
export function getNotice(noticeId = '', isManager = false, baseURL = null) {
  const agentOrManager = isManager ? 'manager' : 'agent';
  return fetchData({
    url: `/control/${agentOrManager}/notice_card/${noticeId}/`,
    method: 'GET',
    baseURL,
  });
}
export function addNotice(notice, baseURL = null) {
  return fetchData({
    url: '/control/manager/notice_card/',
    method: 'POST',
    data: notice,
    baseURL,
  });
}
export function updateNotice(notice, baseURL = null) {
  const noticeId = notice.id;
  return fetchData({
    url: `/control/manager/notice_card/${noticeId}/`,
    method: 'PATCH',
    data: {
      ...notice,
      id: undefined,
    },
    baseURL,
  });
}
export function deleteNotice(noticeId, baseURL = null) {
  return fetchData({
    url: `/control/manager/notice_card/${noticeId}/`,
    method: 'DELETE',
    baseURL,
  });
}

/**
 * Agent Overviews
*/
export function getAgentTimeStatus(data, baseURL = null) {
  return fetchData({
    url: '/control/manager/online-status',
    data,
    method: 'POST',
    baseURL,
  });
}

export function getAgentOverviews(overviewId = '', baseURL = null) {
  return fetchData({
    url: `/control/agent/overview/${overviewId}`,
    method: 'GET',
    baseURL,
  });
}
export function createAgentOverviews(data, baseURL = null) {
  return fetchData({
    url: '/control/agent/overview/',
    data,
    method: 'POST',
    baseURL,
  });
}
export function updateAgentOverviews(data, baseURL = null) {
  return fetchData({
    url: `/control/agent/overview/${data.id}/`,
    data: { ...data, id: undefined },
    method: 'PUT',
    baseURL,
  });
}
export function deleteAgentOverviews(id, baseURL = null) {
  return fetchData({
    url: `/control/agent/overview/${id}/`,
    method: 'DELETE',
    baseURL,
  });
}

export function getPretrainedModels(baseURL = null) {
  return fetchData({
    url: '/control/manager/ai-pretrained-models',
    method: 'GET',
    baseURL,
  });
}

export function getSupChatModels(baseURL = null) {
  return fetchData({
    url: '/control/manager/ai-model/',
    method: 'GET',
    baseURL,
  });
}

export function createSupChatModel({
  aienginePretrainedId,
  tenant,
  type,
  subtype,
  language,
}, baseURL = null) {
  const data = {
    aiengine_pretrained_id: aienginePretrainedId,
    tenant,
    type,
    subtype,
    language,
  };
  return fetchData({
    url: '/control/manager/ai-model/',
    data,
    method: 'POST',
    baseURL,
  });
}

export function deleteSupChatModel(modelId, baseURL = null) {
  return fetchData({
    url: `/control/manager/ai-model/${modelId}/`,
    method: 'DELETE',
    baseURL,
  });
}

export function getSupChatRankers(rankerId = '', baseURL = null) {
  return fetchData({
    url: `/control/manager/ai-ranker/${rankerId}/`,
    method: 'GET',
    baseURL,
  });
}

export function createSupChatRanker({
  model, name, periodStart, periodEnd,
}, baseURL = null) {
  const data = {
    model,
    name,
    period_start: periodStart,
    period_end: periodEnd,
  };
  return fetchData({
    url: '/control/manager/ai-ranker/',
    method: 'POST',
    data,
    baseURL,
  });
}

export function deleteSupChatRanker(rankerId, baseURL = null) {
  return fetchData({
    url: `/control/manager/ai-ranker/${rankerId}/`,
    method: 'DELETE',
    baseURL,
  });
}

export function getEnabledRanker(modelId, baseURL = null) {
  return fetchData({
    url: '/control/manager/ai-enabled-ranker',
    data: {
      model_id: modelId,
    },
    method: 'GET',
    baseURL,
  });
}

export function setEnabledRanker({ modelId, rankerId }, baseURL = null) {
  return fetchData({
    url: '/control/manager/ai-enabled-ranker',
    data: {
      model_id: modelId,
      ranker_id: rankerId,
    },
    method: 'POST',
    baseURL,
  });
}

export function getArticleRankers(baseURL = null) {
  return fetchData({
    url: '/control/manager/supsearch-available-rankers',
    method: 'GET',
    baseURL,
  });
}

export function getEnabledArticleRanker(baseURL = null) {
  return fetchData({
    url: '/control/agent/supsearch-ranker',
    method: 'GET',
    baseURL,
  });
}

export function setEnabledArticleRanker({
  rankerId, tenantId, type, language,
}, baseURL = null) {
  const data = {
    ranker_id: rankerId,
  };
  return fetchData({
    url: `/control/manager/supsearch-ranker/${tenantId}/${type}/${language}`,
    method: 'POST',
    data,
    baseURL,
  });
}

export function disableArticleRanker({ tenantId, language }, baseURL = null) {
  return fetchData({
    url: `/control/manager/supsearch-ranker/${tenantId}/article/${language}`,
    method: 'DELETE',
    baseURL,
  });
}

export function getRankerStats(rankerId, baseURL = null) {
  return fetchData({
    url: `/control/manager/ai-ranker-statistics/${rankerId}`,
    method: 'GET',
    baseURL,
  });
}

export function getEntryWidgets(configId, baseURL = null) {
  return fetchData({
    url: `/control/tenantwidget/${configId}/`,
    method: 'GET',
    baseURL,
  });
}

export function addEntryWidget({ name, content }, baseURL = null) {
  return fetchData({
    url: '/control/tenantwidget/',
    method: 'POST',
    data: { name, content },
    baseURL,
  });
}

export function updateEntryWidget({ name, content, id }, baseURL = null) {
  return fetchData({
    url: `/control/tenantwidget/${id}/`,
    method: 'PUT',
    data: { name, content },
    baseURL,
  });
}

export function deleteEntryWidget(configId, baseURL = null) {
  return fetchData({
    url: `/control/tenantwidget/${configId}/`,
    method: 'DELETE',
    baseURL,
  });
}

export function generateChatSummary({ chatId, chatIds, language }, baseURL = null) {
  let url = '/control/agent/generate_ai_summary_of_ongoing_chat';
  let data = { chat_id: chatId, language };
  if (chatIds) {
    url = '/control/agent/generate_ai_summary_of_ended_chat';
    data = { chat_id: chatIds[0] };
  }
  return fetchData({
    url,
    method: 'POST',
    data,
    baseURL,
  });
}

export function getArticles({ tenantId, question, language }, baseURL = null) {
  return fetchData({
    url: '/control/agent/article_prediction',
    method: 'POST',
    data: {
      tenant_id: tenantId,
      question_text: question,
      language,
    },
    baseURL,
  });
}
export function generateReplyFromArticles({
  articleLanguage,
  articles,
  chatId,
  language,
  question,
  tenantId,
}, baseURL = null) {
  return fetchData({
    url: '/control/agent/make_gpt_reply',
    method: 'POST',
    data: {
      article_language: articleLanguage,
      articles,
      chat_id: chatId,
      language,
      question_text: question,
      tenant_id: tenantId,
    },
    baseURL,
  });
}

export function articleFeedback({
  quid, articleId, isNegative,
}, baseURL = null) {
  const data = { quid };
  if (isNegative) {
    data.negative_feedback_for_all_articles = true;
  } else {
    data.positive_feedback_for_id = articleId;
  }
  return fetchData({
    url: '/control/agent/article_prediction_feedback',
    method: 'POST',
    data,
    baseURL,
  });
}

export function getSupSearchOpenAIConfig(tenantId, baseURL = null) {
  return fetchData({
    url: `/control/manager/tenant-supsearch-openai-config/${tenantId}`,
    method: 'GET',
    baseURL,
  });
}

export function setSupSearchOpenAIConfig({
  tenantId, enabled, maxMsgs,
}, baseURL = null) {
  const data = {
    tenant: tenantId,
    allow_autogenerated_responses: enabled,
    max_chat_message_context: maxMsgs,
  };
  return fetchData({
    url: `/control/manager/tenant-supsearch-openai-config/${tenantId}`,
    method: 'PATCH',
    data,
    baseURL,
  });
}

export function generateAgentResponse({
  agentResponse,
  chatId,
  language,
  visitorLanguage,
  visitorResponse,
}, baseURL = null) {
  const data = {
    agent_draft_reply: agentResponse,
    chat_id: chatId,
    language,
    last_visitor_msg: visitorResponse,
    last_visitor_msg_language: visitorLanguage,
  };
  return fetchData({
    url: '/control/agent/improve_agent_reply',
    data,
    method: 'POST',
    baseURL,
  });
}
export function improveAgentResponse({
  chatId, visitorResponse, agentResponse, language, flavor,
}, baseURL = null) {
  const data = {
    chat_id: chatId,
    language,
    last_visitor_msg: visitorResponse,
    text: agentResponse,
    flavour: flavor,
  };
  return fetchData({
    url: '/control/agent/rewrite_text_with_flavour',
    data,
    method: 'POST',
    baseURL,
  });
}

export function getTenantSentimentEnabled({ tenantId }, baseURL = null) {
  return fetchData({
    url: `/control/manager/tenant-sentiment-config/${tenantId}`,
    method: 'GET',
    baseURL,
  });
}
export function setTenantSentimentEnabled({ tenantId, enabled }, baseURL = null) {
  const data = { enabled };
  return fetchData({
    url: `/control/manager/tenant-sentiment-config/${tenantId}`,
    data,
    method: 'PATCH',
    baseURL,
  });
}

export function SSOFetchMethod(id, baseURL = null) {
  return fetchData({
    url: `/control/sso/config/${id}`,
    data: {},
    method: 'GET',
    baseURL,
  });
}
export function SSOUpdateMethod(id, data, baseURL = null) {
  return fetchData({
    url: `/control/sso/config/${id}`,
    data,
    method: 'PATCH',
    baseURL,
  });
}
export function SSOExpirationMethod(id, baseURL = null) {
  return fetchData({
    url: `/control/sso/expiration/${id}`,
    data: {},
    method: 'GET',
    baseURL,
  });
}
