const statusEN = {
  chatForcedClosed: 'Due to security reasons the chat has been closed and you have been '
    + 'restricted from viewing the transcript of the message.\n\nPlease start a new chat.',
  chatTransfer: 'The chat was transferred | You are now being transferred',
  connectionGood: 'Connection established',
  connectionLost: 'Connection has been lost. Retrying connection...',
  cookiesBlocked: 'Cookies are required for the chat to work.\n'
    + 'Please allow cookies in your browser settings and refresh the page.',
  firewallBlocking: 'A firewall seems to be blocking the usage of the chat. Please contact '
    + 'your network administrator or try again.',
  findingAgent: 'Please wait while finding an agent',
  msgSendFail: 'Failed to send message. Click me to try again',
  userClosed: '{name} closed the chat',
  inactivityClosed: 'The chat was closed because of inactivity',
  userAnsweredForm: '{name} received and answered a form',
  userInteracted: '{name} clicked on {value}',
  userJoin: '{name} joined the chat',
  userUploadAltText: '{name} uploaded {file}. Click here to download or open.',
  userUpload: '{name} uploaded a file ({url})',
  userLeft: '{name} left the chat',
  userLeftPage: '{name} left the page',
  userQueuePos: 'You are number <strong>{number}</strong> in the queue',
  userQueuePosDocTitle: 'No. {number} in the queue',
  userUnreadMsg: '{number} unread message',
  userUnreadMsgs: '{number} unread messages',
  userWriting: '{name} is writing',
  languageSet: '{name} set the language to {language}',
  languageDetected: 'Based on the message from {name} the language is set to {language}',
  languageRemoved: '{name} disabled automatic translation',
  languageSetVisitor: '{name} selected {language} as the language',
  languageDetectedVisitor: '{language} was automatically set as the language',
  languageRemovedVisitor: '{name} disabled automatic translation',
  seeTranslation: 'See translation',
  seeTranslationLanguage: 'See in {language}',
  seeOriginal: 'See original',
  markAsWrongTranslation: 'Report incorrect translation',
  hasBeenMarkedAsWrongTranslation: 'Reported, click to undo',
  translateNow: 'Translate to {language}',
  requestHCArticles: 'Request articles for this message',
  errors: {
    unexpectedError: 'An unexpected error occured.',
    websocketBad: 'The connection has been lost. Retrying...',
    websocketOk: 'The connection is restored.',
    upload: {
      fileTooBig: 'The file must be less than 10 MB.',
    },
  },
};
const statusDK = {
  chatForcedClosed: 'Af sikkerhedsårsager er den seneste chat blevet afsluttet og du kan '
    + 'ikke se indholdet af den.\n\nStart venligst en ny chat.',
  chatTransfer: 'Chatten blev viderestillet | Du bliver nu viderestillet',
  connectionLost: 'Forbindelsen blev tabt. Prøver at forbinde igen...',
  connectionGood: 'Forbindelse oprettet',
  cookiesBlocked: 'Cookies er nødvendige for at chatten kan fungere.<br>'
    + 'Tillad cookies i dine browser indstillinger og genindlæs siden.',
  firewallBlocking: 'Det ser ud til at en firewall blokerer for at du kan bruge chatten. '
    + 'Kontakt venligst din netværksadministrator eller prøv igen.',
  findingAgent: 'Vent venligst på at en agent bliver ledig',
  msgSendFail: 'Beskeden blev ikke sendt. Tryk for at prøve igen',
  userClosed: '{name} afsluttede chatten',
  inactivityClosed: 'Chatten blev afsluttet på grund af inaktivitet',
  userAnsweredForm: '{name} modtog og besvarede en form',
  userInteracted: '{name} trykkede på {value}',
  userJoin: '{name} deltager i chatten',
  userUploadAltText: '{name} uploadede filen {file}. Tryk for at downloade eller åbne.',
  userUpload: '{name} uploadede en fil ({url})',
  userLeft: '{name} forlod chatten',
  userLeftPage: '{name} forlod siden',
  userQueuePos: 'Du er nummer <strong>{number}</strong> i køen',
  userQueuePosDocTitle: 'Nr. {number} i køen',
  userUnreadMsg: '{number} ulæst besked',
  userUnreadMsgs: '{number} ulæste beskeder',
  userWriting: '{name} skriver',
  languageSet: '{name} satte sproget til {language}',
  languageDetected: 'Baseret på beskeden fra {name} er sproget sat til {language}',
  languageRemoved: '{name} slog automatisk oversættelse fra',
  languageSetVisitor: '{name} valgte {language} som sprog',
  languageDetectedVisitor: '{language} blev automatisk valgt som sprog',
  languageRemovedVisitor: '{name} har slået automatisk oversættelse fra',
  seeTranslation: 'Se oversættelse',
  seeTranslationLanguage: 'Se på {language}',
  seeOriginal: 'Se original',
  markAsWrongTranslation: 'Anmeld forkert oversættelse',
  hasBeenMarkedAsWrongTranslation: 'Anmeldt, tryk for at fortryde',
  translateNow: 'Oversæt til {language}',
  requestHCArticles: 'Anmod om artikler til denne besked',
  errors: {
    unexpectedError: 'Der skete en ukendt fejl.',
    websocketBad: 'Forbindelsen blev tabt. Prøver igen...',
    websocketOk: 'Forbindelsen er oprettet igen.',
    upload: {
      fileTooBig: 'Filen skal være mindre end 10 MB.',
    },
  },
};

export default {
  en: {
    status: statusEN,
    interaction: {
      beginChat: 'Start Chat',
      cancel: 'Cancel',
      descriptionCancel: 'Go Back',
      descriptionSelect: 'Continue',
      dialogClose: 'Close',
      endChat: 'End Chat',
      endChatConfirm: 'Are you sure that you want to end the chat?',
      enterValidEmail: 'Enter a valid email.',
      formAwait: 'Please fill out the form',
      formDeny: 'Deny',
      formDenyLabel: 'Deny filling out the form.',
      formSubmit: 'Submit',
      formResponded: 'You submitted the form',
      getEmail: 'Email Chat Transcript',
      getEmailConfirm: 'Please enter the email address you would like to receive the transcript at',
      getEmailError: 'An error occurred while generating email transcript. Please try again, '
        + 'or select Copy Chat Transcript to save the chat to your clipboard.',
      getTranscript: 'Copy Chat Transcript',
      inputFieldDisabled: 'Input is disabled',
      logInConfirm: 'Do you want to log in?',
      gotoTenantWidget: 'Back To Overview',
      minimizeChat: 'Minimize Chat',
      muteSound: 'Mute Sound',
      newChat: 'New Chat',
      no: 'No',
      optionSeeDescription: 'See explanation for the option: {option}',
      ratingSkip: 'Skip',
      ratingClose: 'Close',
      ratingSubmit: 'Submit',
      restartChat: 'New Question?',
      scrollToBottom: 'Scroll To Bottom',
      sendEmail: 'Send Email',
      sendFile: 'Send a file in the chat',
      sendMsg: 'Send',
      showChat: 'Show Chat',
      soundQuestion: 'Do you want to enable notification sounds?',
      unmuteSound: 'Unmute Sound',
      yes: 'Yes',
      selectTranslationLanguage: 'Select support language',
      translateTo: {
        error: 'No languages found, invalid setup',
        none: '@:vocabulary.untranslated',
        af: 'Afrikaans',
        am: 'Amharic',
        ar: 'Arabic',
        as: 'Assamese',
        az: 'Azerbaijani',
        ba: 'Bashkir',
        be: 'Belarusian',
        bg: 'Bulgarian',
        bn: 'Bangla',
        bo: 'Tibetan',
        bs: 'Bosnian',
        ca: 'Catalan',
        cs: 'Czech',
        cy: 'Welsh',
        da: 'Danish',
        'da-dk': '@:interaction.translateTo.da',
        dan: '@:interaction.translateTo.da',
        de: 'German',
        deu: 'German',
        'de-de': 'German',
        dsb: 'Lower Sorbian',
        dv: 'Dhivehi',
        el: 'Greek',
        'el-gr': 'Greek',
        en: 'English',
        'en-gb': 'English (United Kingdom)',
        'en-us': 'English',
        eng: '@:interaction.translateTo.en',
        es: 'Spanish',
        'es-es': 'Spanish',
        et: 'Estonian',
        eu: 'Basque',
        fa: 'Persian',
        'fa-af': 'Persian (Afghanistan)',
        fi: 'Finnish',
        fil: 'Filipino',
        'fi-fi': 'Finnish',
        fj: 'Fijian',
        fo: 'Faroese',
        fr: 'French',
        'fr-ca': 'French (Canada)',
        'fr-fr': 'French',
        fra: 'French',
        ga: 'Irish',
        gl: 'Galician',
        gom: 'Goansk konkaki',
        gu: 'Gujarati',
        ha: 'Hausa',
        he: 'Hebrew',
        hi: 'Hindi',
        hr: 'Croatian',
        hsb: 'Upper Sorbian',
        ht: 'Haitian Creole',
        hu: 'Hungarian',
        hy: 'Armenian',
        id: 'Indonesian',
        'id-id': 'Indonesian (Indonesia)',
        ig: 'Igbo',
        ikt: 'Inuinnaqtun',
        is: 'Icelandic',
        it: 'Italian',
        ita: 'Italian',
        'it-it': 'Italian',
        iu: 'Inuktitut',
        'iu-latn': 'Inuktitut (Latin)',
        ja: 'Japanese',
        ka: 'Georgian',
        kk: 'Kazakh',
        km: 'Khmer',
        kmr: 'Kurdish (Northern)',
        kn: 'Kannada',
        ko: 'Korean',
        ku: 'Kurdish (Central)',
        ky: 'Kyrgyz',
        ln: 'Lingala',
        lo: 'Lao',
        lt: 'Lithuanian',
        lug: 'Ganda',
        lv: 'Latvian',
        lzh: 'Chinese (Literary)',
        mai: 'Maay Maay',
        mg: 'Malagasy',
        mi: 'Maori',
        mk: 'Macedonian',
        ml: 'Malayalam',
        'mn-cyrl': 'Mongolian (Cyrillic)',
        'mn-mong': 'Mongolian (Mongolian)',
        mr: 'Marathi',
        ms: 'Malay',
        'ms-my': 'Malay (Malaysia)',
        mt: 'Maltese',
        mww: 'Hmong Daw',
        my: 'Burmese',
        nb: 'Norwegian',
        ne: 'Nepali',
        nl: 'Dutch',
        'nl-nl': 'Dutch',
        'no-no': 'Norwegian',
        nso: 'Nordsotho',
        nya: 'Nyanja',
        or: 'Odia',
        otq: 'Querétaro Otomi',
        pa: 'Punjabi',
        pl: 'Polish',
        'pl-pl': 'Polish',
        prs: 'Dari',
        ps: 'Pashto',
        pt: 'Portuguese (Brazil)',
        'pt-br': 'Portuguese (Brazil)',
        'pt-pt': 'Portuguese (Portugal)',
        ro: 'Romanian',
        'ro-ro': 'Romanian',
        ru: 'Russian',
        'ru-ru': 'Russian',
        run: 'Rundi',
        rw: 'Kinyarwanda',
        sd: 'Sindhi',
        si: 'Singalesisk',
        sk: 'Slovak',
        sl: 'Slovenian',
        sm: 'Samoan',
        sn: 'Shona',
        so: 'Somali',
        spa: 'Spanish',
        sq: 'Albanian',
        'sr-cyrl': 'Serbian (Cyrillic)',
        'sr-latn': 'Serbian (Latin)',
        srl: 'Serbian (Latin)',
        st: 'Sydsotho',
        sv: 'Swedish',
        'sv-se': 'Swedish',
        sw: 'Swahili',
        swe: 'Swahili',
        ta: 'Tamil',
        te: 'Telugu',
        th: 'Thai',
        ti: 'Tigrinya',
        tk: 'Turkmen',
        'tlh-latn': 'Klingon (Latin)',
        'tlh-piqd': 'Klingon (pIqaD)',
        tn: 'Tswana',
        to: 'Tongan',
        tr: 'Turkish',
        tt: 'Tatar',
        tur: 'Turkish',
        ty: 'Tahitian',
        ug: 'Uyghur',
        uk: 'Ukrainian',
        ur: 'Urdu',
        uz: 'Uzbek',
        vi: 'Vietnamese',
        xh: 'IsiXhosa',
        yo: 'Yoruba',
        yua: 'Yucatec Maya',
        yue: 'Cantonese (Traditional)',
        zh: 'Chinese',
        'zh-cn': 'Chinese',
        'zh-hans': 'Chinese (Simplified)',
        'zh-hant': 'Chinese (Traditional)',
        zu: 'IsiZulu',
      },
      pleaseWrite: 'Please write down your {field}',
      loginPromptLogin: 'Go to login',
      loginPromptSkip: 'Skip',
      selectDepartment: 'Select Department',
      authenticationRequired: 'You need to log in before you can access the chat.',
      authenticationRequiredBtn: 'Go To Login',
      dismissMessage: 'Dismiss Message',
      agent: 'Agent',
      visitor: 'Visitor',
    },
    locales: {
      weekDays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      closed: 'Closed',
    },
    accessibility: {
      optionsMenu: 'Toggle options menu',
      ratingSelect: '{option} out of {total}',
    },
    form: {
      validation: {
        email: 'Enter a valid email.',
        telDK: 'Enter a valid danish phone number.',
      },
    },
  },
  da: {
    status: statusDK,
    interaction: {
      beginChat: 'Start chat',
      cancel: 'Afbryd',
      descriptionCancel: 'Gå tilbage',
      descriptionSelect: 'Fortsæt',
      dialogClose: 'Luk',
      endChat: 'Afslut chat',
      endChatConfirm: 'Er du sikker på, at du vil afslutte chatten?',
      enterValidEmail: 'Indtast en gyldig email.',
      formAwait: 'Udfyld venligst formularen',
      formDeny: 'Afvis',
      formDenyLabel: 'Afvis at udfylde formen.',
      formSubmit: 'Indsend',
      formResponded: 'Du besvarede formularen',
      getEmail: 'E-mail chatudskrift',
      getEmailConfirm: 'Indtast den e-mail du gerne vil modtage chatudskriftet på',
      getEmailError: 'Der opstod en fejl under afsendelse af chatudskriftet. Prøv venligst igen, '
        + 'eller vælg Kopiér chatudskrift for at gemme chatten til din udklipsholder.',
      getTranscript: 'Kopiér chatudskrift',
      inputFieldDisabled: 'Feltet er slået fra',
      logInConfirm: 'Ønsker du at logge ind?',
      gotoTenantWidget: 'Tilbage til oversigt',
      minimizeChat: 'Minimér chat',
      muteSound: 'Slå lyd fra',
      newChat: 'Ny chat',
      no: 'Nej',
      optionSeeDescription: 'Se forklaring af valgmuligheden: {option}',
      ratingSkip: 'Spring over',
      ratingClose: 'Luk',
      ratingSubmit: 'Send',
      restartChat: 'Stil nyt spørgsmål?',
      scrollToBottom: 'Scroll til bunden',
      sendEmail: 'Send e-mail',
      sendFile: 'Send en fil i chatten',
      sendMsg: 'Send',
      showChat: 'Vis chat',
      soundQuestion: 'Vil du gerne høre en lyd, når du modtager en ny besked?',
      unmuteSound: 'Slå lyd til',
      yes: 'Ja',
      selectTranslationLanguage: 'Vælg chat sprog',
      translateTo: {
        error: 'Ingen sprog fundet, ugyldig opsætning',
        none: '@:vocabulary.untranslated',
        af: 'Afrikaans',
        am: 'Amharisk',
        ar: 'Arabisk',
        as: 'Assamesisk',
        az: 'Aserbajdsjansk',
        ba: 'Bashkir',
        be: 'Hviderussisk',
        bg: 'Bulgarsk',
        bn: 'Bangla',
        bo: 'Tibetansk',
        bs: 'Bosnisk',
        ca: 'Catalansk',
        cs: 'Tjekkisk',
        cy: 'Walisisk',
        da: 'Dansk',
        'da-dk': '@:interaction.translateTo.da',
        dan: '@:interaction.translateTo.da',
        de: 'Tysk',
        deu: 'Tysk',
        'de-de': 'Tysk',
        dsb: 'Nedre sorbisk',
        dv: 'Dhivehi',
        el: 'Græsk',
        'el-gr': 'Græsk',
        en: 'Engelsk',
        'en-gb': 'Engelsk (Storbritannien)',
        'en-us': 'Engelsk',
        eng: '@:interaction.translateTo.en',
        es: 'Spansk',
        'es-es': 'Spansk',
        et: 'Estisk',
        eu: 'Baskisk',
        fa: 'Persisk',
        'fa-af': 'Dari',
        fi: 'Finsk',
        fil: 'Filippinsk',
        'fi-fi': 'Finsk',
        fj: 'Fijiansk',
        fo: 'Færøsk',
        fr: 'Fransk',
        'fr-ca': 'Fransk (Canada)',
        'fr-fr': 'Fransk',
        fra: 'Fransk',
        ga: 'Irsk ',
        gl: 'Galicisk',
        gom: 'Goan Konkaki',
        gu: 'Gujarati',
        ha: 'Hausa',
        he: 'Hebraisk',
        hi: 'Hindi',
        hr: 'Kroatisk',
        hsb: 'Øvresorbisk',
        ht: 'Haitisk kreolsk',
        hu: 'Ungarsk',
        hy: 'Armensk',
        id: 'Indonesisk',
        'id-id': 'Indonesisk (Indonesien)',
        ig: 'Igbo',
        ikt: 'Inuinnaqtun',
        is: 'Islandsk',
        it: 'Italiensk',
        'it-it': 'Italiensk',
        ita: 'Italiensk',
        iu: 'Inuktitut',
        'iu-latn': 'Inuktitut (latinsk)',
        ja: 'Japansk',
        ka: 'Georgisk',
        kk: 'Kasakhisk',
        km: 'Khmer',
        kmr: 'Kurdisk (nord)',
        kn: 'Kannada',
        ko: 'Koreansk',
        ku: 'Kurdisk (central)',
        ky: 'Kirgisisk',
        ln: 'Lingala',
        lo: 'Lao',
        lt: 'Litauisk',
        lug: 'Ganda',
        lv: 'Lettisk',
        lzh: 'Kinesisk (litterært)',
        mai: 'Maay Maay',
        mg: 'Malagassisk',
        mi: 'Maori',
        mk: 'Makedonsk',
        ml: 'Malayalam',
        'mn-cyrl': 'Mongolsk (kyrillisk)',
        'mn-mong': 'Mongolsk (mongolsk)',
        mr: 'Marathi',
        ms: 'Malaysisk',
        'ms-my': 'Malajisk (Malaysia)',
        mt: 'Maltesisk',
        mww: 'Hmong Daw',
        my: 'Burmesisk',
        nb: 'Norsk',
        ne: 'Nepalesisk',
        nl: 'Hollandsk',
        'nl-nl': 'Hollandsk',
        'no-no': 'Norsk',
        nso: 'Sesotho sa Leboa',
        nya: 'Nyanja',
        or: 'Odia',
        otq: 'Querétaro Otomi',
        pa: 'Punjabi',
        pl: 'Polsk',
        'pl-pl': 'Polsk',
        prs: 'Dari',
        ps: 'Pashto',
        pt: 'Portugisisk (Brasilien)',
        'pt-br': 'Portugisisk (Brasilien)',
        'pt-pt': 'Portugisisk (Portugal)',
        ro: 'Rumænsk',
        'ro-ro': 'Rumænsk',
        ru: 'Russisk',
        'ru-ru': 'Russisk',
        run: 'Rundi',
        rw: 'Kinyarwanda',
        sd: 'Sindhi',
        si: 'Sinhala',
        sk: 'Slovakisk',
        sl: 'Slovensk',
        sm: 'Samoansk',
        sn: 'Shona',
        so: 'Somali',
        st: 'Sesotho',
        spa: 'Spansk',
        sq: 'Albansk',
        'sr-cyrl': 'Serbisk (kyrillisk)',
        'sr-latn': 'Serbisk (latinsk)',
        srl: 'Serbisk (latinsk)',
        sv: 'Svensk',
        'sv-se': 'Svensk',
        sw: 'Swahili',
        swe: 'Svensk',
        ta: 'Tamil',
        te: 'Telugu',
        th: 'Thai',
        ti: 'Tigrinsk',
        tk: 'Turkmensk',
        'tlh-latn': 'Klingon (Latin)',
        'tlh-piqd': 'Klingon (pIqaD)',
        tn: 'Setswana',
        to: 'Tongansk',
        tr: 'Tyrkisk',
        tt: 'Tatarisk',
        tur: 'Tyrkisk',
        ty: 'Tahitisk',
        ug: 'Uygurisk',
        uk: 'Ukrainsk',
        ur: 'Urdu',
        uz: 'Usbekisk',
        vi: 'Vietnamesisk',
        xh: 'isiXhosa',
        yo: 'Yoruba',
        yua: 'Yucatec Maya',
        yue: 'Kantonesisk (Traditionel)',
        zh: 'Kinesisk',
        'zh-cn': 'Kinesisk',
        'zh-hans': 'Kinesisk (forenklet)',
        'zh-hant': 'Kinesisk (traditionelt)',
        zu: 'Zulu',
      },
      pleaseWrite: 'Skriv venligst dit {field}',
      loginPromptLogin: 'Gå til log ind',
      loginPromptSkip: 'Spring over',
      selectDepartment: 'Vælg Afdeling',
      authenticationRequired: 'Du skal logge ind for at bruge chatten.',
      authenticationRequiredBtn: 'Gå til login',
      dismissMessage: 'Luk besked',
      agent: 'Agent',
      visitor: 'Besøgende',
    },
    locales: {
      weekDays: ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'],
      closed: 'Lukket',
    },
    accessibility: {
      optionsMenu: 'Åben eller luk indstillinger',
      ratingSelect: '{option} ud af {total}',
    },
    form: {
      validation: {
        email: 'Indtast en gyldig e-mail.',
        telDK: 'Indtast et gyldigt dansk telefonnummer.',
      },
    },
  },
};
