<template>
  <div>
    <chat-interface ref="chatInterface" />
    <b-modal
      ref="missingLinkModal"
      v-bind="{
        ...modalOptions,
      }"
    >
      <p class="m-0 i18n-newlines">
        {{ $t('message.externalURI.missingLinkModal.body') }}
      </p>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import ChatInterface from '@/components/chatModal/ChatInterface.vue';

export default {
  name: 'ChatEntry',
  components: {
    ChatInterface,
  },
  computed: {
    ...mapState(['featureFlags']),
    modalOptions() {
      return {
        title: this.$t('message.externalURI.missingLinkModal.title'),
        okVariant: 'primary',
        okTitle: this.$t('vocabulary.ok'),
        hideHeaderClose: true,
        centered: true,
        okOnly: true,
      };
    },
  },
  beforeDestroy() {
    this.$store.commit('chat/CLEAR_INCOMING_POLLING_INTERVAL');
    this.$store.commit('chat/CLEAR_ONGOING_POLLING_INTERVAL');
  },
  mounted() {
    // intended for BeyondTrust remote session links
    // modal will mention BeyondTrust, so enable with care
    if (this.featureFlags.SHOW_EXTERNAL_URI_BTN) {
      const agentInfo = this.$store.state.agent.info;
      if (agentInfo?.externalUri === 'error' && agentInfo.maxRole === 1) {
        this.$refs.missingLinkModal.show();
      }
    }
    this.$store.dispatch('chat/beginPollingIncomingChats');
    this.$store.dispatch('chat/beginPollingOngoingChats');
  },
};
</script>
