<template>
  <div>
    <b-modal
      id="logoutModal"
      ref="logoutModal"
      centered
      :title="$t('message.signOut')"
      :cancel-title="$t('vocabulary.cancel')"
      :ok-title="$t('message.signOut')"
      @ok="logoutAnyWay"
      @hidden="$router.push({ name: 'home' })"
    >
      <template v-if="!logoutPossible">
        {{ $tc('message.signOutWarning', chatNum, { number: chatNum }) }}
      <!-- You still have {{ chatNum }} active chat{{ chatNum > 1 ? 's' : '' }}.
        Please consider closing or transferring them before signing out. -->
      </template>
      <template v-else>
        {{ $t('vocabulary.loading') }}...
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getMyChats } from '@/api/apiList';

export default {
  name: 'AppLogout',
  data() {
    return {
      chatNum: null,
      returnPage: null,
    };
  },
  computed: {
    ...mapGetters('agent', ['displayName']),
    logoutPossible() {
      if (this.chatNum !== null) {
        return this.chatNum === 0;
      }
      return null;
    },
  },
  created() {
    this.logout();
  },
  methods: {
    async logout() {
      try {
        this.chatNum = null;
        const myChats = await getMyChats();
        this.chatNum = Object.getOwnPropertyNames(myChats.chats).length;
        if (this.logoutPossible) {
          // tricky of redirecting the page to the previous place when triggering logout
          this.logoutAnyWay();
        } else {
          this.$refs.logoutModal.show();
        }
      } catch (error) {
        this.$log.error(error);
      }
    },
    async logoutAnyWay() {
      await this.$store.dispatch('agent/logout');
      // Due to some programming errors other places (socket reconnects) we need to do a
      //  hard page reload (i.e. set window.location instead of using this.$router.push).
      window.location = this.$router.resolve({ name: 'login' }).href;
    },
  },
};
</script>

<style scoped>

</style>
