<template>
  <div
    class="position-fixed error-alerts d-flex justify-content-end align-items-start"
  >
    <div class="d-flex flex-column flex-fill">
      <error-display-alert
        v-for="error in getErrors"
        :key="error.id"
        :error="error"
      />
    </div>
    <ConnectionDisplay />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import ErrorDisplayAlert from './ErrorDisplayAlert.vue';
import ConnectionDisplay from './ConnectionDisplay.vue';

export default {
  name: 'ErrorDisplay',
  components: {
    ErrorDisplayAlert,
    ConnectionDisplay,
  },
  computed: {
    ...mapGetters('errorDisplay', ['getErrors']),
  },
};
</script>

<style scoped>
.error-alerts {
  z-index:999999999;
  /* same as page content */
  width: calc(100vw - 230px - 2rem);
}
</style>
