import Vue from 'vue';
import moment from 'moment';
import { sortBy, has } from 'lodash';

import { deepFreeze } from 'supwiz/supchat/generalUtils';
import {
  getAnalyticsReport,
  createAnalyticsReport,
  updateAnalyticsReport,
  deleteAnalyticsReport,
  getAnalyticsReportBox,
  addAnalyticsReportBox,
  updateAnalyticsReportBox,
  deleteAnalyticsReportBox,
} from '@/api/apiList';

const periodUnitMapping = Object.freeze({
  lastMonth: 'month',
  lastWeek: 'isoWeek',
  yesterday: 'day',
  day: 'day',
  week: 'isoWeek',
  month: 'month',
  custom: 'milliseconds',
});

function mstoSec(ms) { return Math.floor(ms / 1000); }

function preparePeriod(period, customPeriod = null) {
  const unit = periodUnitMapping[period];
  let start;
  let end;
  if (period === 'custom' && Array.isArray(customPeriod)) {
    start = new Date(customPeriod[0]).getTime();
    end = new Date(customPeriod[1]).getTime();
  } else if (['lastMonth', 'lastWeek', 'yesterday'].includes(period)) {
    start = new Date(moment().startOf(unit).subtract(1, unit)).getTime();
    end = new Date(moment().endOf(unit).subtract(1, unit)).getTime() + 1000;
  } else {
    start = new Date(moment().startOf(unit)).getTime();
    end = new Date().getTime();
  }
  return {
    start,
    end,
  };
}

const reportState = {
  selectedPeriod: 'day',
  customPeriod: null,
  predefinedPeriods: Object.freeze([
    'lastMonth',
    'lastWeek',
    'yesterday',
    'day',
    'week',
    'month',
  ]),
  reportData: {},
  reportStructure: {},
  reportBoxes: {},
  fetchingState: {
    pageload: true,
  },
};

const reportGetters = {
  possibleReports: (
    state, getters, rootState, rootGetters,
  ) => {
    // generate a list of all the tenants and departments we can generate a report for
    const result = [];
    const managedTenantIds = rootGetters['agent/tenantsAsManager'].map((t) => t.id);
    const managedDepartments = sortBy(
      rootGetters['agent/departments'].filter((d) => d.role >= 2),
      ['id'],
    );
    for (const department of managedDepartments) {
      const tenantId = department.tenant_id;
      // add the tenant to the list of reports if we're a manager and it's not already added
      if (
        !result.some((r) => r.tenant === department.tenant_id)
        && managedTenantIds.includes(tenantId)
      ) {
        result.push({
          tenant: tenantId,
          name: department.tenant_name,
        });
      }
      // finally, push the department
      result.push({
        department: department.id,
        tenant: tenantId,
        name: department.fullName,
      });
    }
    return deepFreeze(result);
  },
  createdReports: (state, getters) => {
    const createdReports = Object.keys(state.reportStructure);
    return getters.possibleReports
      .filter(({ department, tenant }) => createdReports.includes(department || tenant));
  },
  remainingReports: (state, getters) => {
    const createdReports = Object.keys(state.reportStructure);
    return getters.possibleReports
      .filter(({ department, tenant }) => !createdReports.includes(department || tenant));
  },
  getSingleReportOrder: (state) => (report) => state
    .reportStructure[report]?.order || [],
  getReportBox: (state) => (boxId) => state.reportBoxes[boxId],
  getMetricData: (state) => ({
    reportFor, type, metric, groupBy,
  }) => state
    .reportData[reportFor]?.[state.selectedPeriod]?.[groupBy]?.[type]?.[metric],
  getMetricDataList: (state) => ({
    reportFor, groupBy, type,
  }) => state
    .reportData[reportFor]?.[state.selectedPeriod]?.[groupBy]?.[type],
  hasReport: (state) => (report) => report in state.reportStructure,
  getFetchStatusId: () => (
    { reportFor, groupBy, previous },
  ) => `${reportFor}${groupBy}${previous ? 'previous' : 'current'}`,
};

const mutations = {
  SET_PERIOD(state, newPeriod) {
    Vue.set(state, 'selectedPeriod', newPeriod);
  },
  SET_CUSTOM_PERIOD(state, newPeriod) {
    Vue.set(state, 'customPeriod', newPeriod);
  },
  SET_REPORT_DATA(state, {
    reportData, period, reportFor, previous, groupBy, isList,
  }) {
    if (!(reportFor in state.reportData)) Vue.set(state.reportData, reportFor, {});
    if (!(period in state.reportData[reportFor])) Vue.set(state.reportData[reportFor], period, {});
    if (!(groupBy in state.reportData[reportFor][period])) {
      Vue.set(state.reportData[reportFor][period], groupBy, {});
    }
    if (isList) {
      Vue.set(state.reportData[reportFor][period][groupBy], [previous ? 'previous' : 'current'], reportData);
    } else {
      Vue.set(state.reportData[reportFor][period][groupBy], [previous ? 'previous' : 'current'], reportData[reportFor]);
    }
  },
  SET_REPORT(state, {
    layout: order, id, department_id: department, tenant_id: tenant,
  }) {
    Vue.set(state.reportStructure, department || tenant, Object.freeze({
      order,
      id,
      department,
      tenant,
    }));
  },
  UPDATE_REPORT_LAYOUT(state, {
    newLayout, department, tenant,
  }) {
    const oldReport = state.reportStructure[department || tenant];
    Vue.set(state.reportStructure, department || tenant, Object.freeze({
      ...oldReport,
      order: newLayout,
    }));
  },
  DELETE_REPORT(state, reportFor) {
    Vue.delete(state.reportStructure, reportFor);
  },
  SET_REPORT_BOXES(state, boxes) {
    for (const box of boxes) {
      Vue.set(state.reportBoxes, box.id, Object.freeze({
        ...box,
        groupBy: box.group_by,
      }));
    }
  },
  DELETE_REPORT_BOX(state, boxId) {
    Vue.delete(state.reportBoxes, boxId);
  },
  SET_FETCHING_STATUS(state, { id, status, metrics }) {
    Vue.set(state.fetchingState, id, status || metrics);
  },
};

const actions = {
  async initializeReportPage({ commit, dispatch }) {
    commit('SET_FETCHING_STATUS', { id: 'pageload', status: true });
    const [reportsResult, boxesResult] = await Promise.all([
      getAnalyticsReport(),
      getAnalyticsReportBox(),
    ]);
    commit('SET_REPORT_BOXES', boxesResult.results);
    reportsResult.results.forEach((report) => {
      commit('SET_REPORT', report);
    });
    if (boxesResult.next) {
      await dispatch('fetchNextPageBoxes', boxesResult.next);
    }
    commit('SET_FETCHING_STATUS', { id: 'pageload', status: false });
  },
  async prepareFetchingReportData({
    state, commit, getters, dispatch,
  }, {
    department,
    tenant,
    previous = false,
    forceFetch = false,
  }) {
    const period = state.selectedPeriod;

    // if we have a department param then we are looking for department stats
    const reportFor = department || tenant;

    // create a set with all metrics and groupBys needed for our report
    const reportBoxIds = getters.getSingleReportOrder(reportFor);
    const groupBysSet = new Set(reportBoxIds.map((boxId) => getters
      .getReportBox(boxId).groupBy));
    const firstGroupBy = groupBysSet.values().next().value;

    // if we already have the data and we are not forcing a fetch, we don't need to do anything
    // we'll just check the first groupBy, that's probably fine
    if (!forceFetch && has(
      state.reportData,
      [reportFor, period, firstGroupBy, previous ? 'previous' : 'current'],
    )) return;
    if (state.fetchingState[reportFor]) return;
    // prepare params for the analytics fetch function for each of the groupBys
    groupBysSet.forEach((groupBy) => {
      // find the metrics we need to fetch for the current groupBy
      const metricsSet = new Set();
      for (const boxId of reportBoxIds) {
        const boxDetails = getters.getReportBox(boxId);
        if (boxDetails.groupBy === groupBy) {
          metricsSet.add(boxDetails.metric);
        }
      }
      const metricsAsArray = [...metricsSet];
      const reportStatusId = getters.getFetchStatusId({ reportFor, groupBy, previous });
      commit('SET_FETCHING_STATUS', { id: reportStatusId, metrics: metricsAsArray });

      // prepare parameters for fetching the analytics data
      const params = {
        intervalEndpoints: [],
        groupBy,
        // filter results for the current department or tenant
        filters: { [department ? 'department' : 'tenant']: [reportFor] },
        metrics: metricsAsArray,
      };
      // get start and end time from period and prepare it
      const { start, end } = preparePeriod(period, state.customPeriod);

      if (previous) {
        let toSubtract = 1;
        if (period === 'custom') toSubtract = end - start;
        params.intervalEndpoints[0] = mstoSec(moment(start)
          .subtract(toSubtract, periodUnitMapping[period]));
        params.intervalEndpoints[1] = params.intervalEndpoints[0] + mstoSec(end - start);
      } else {
        params.intervalEndpoints[0] = mstoSec(start);
        params.intervalEndpoints[1] = mstoSec(end);
      }
      // finally push the action to dataFetches which we will await later
      dispatch('analytics/addFetchToQueue', {
        params,
        callback: (result) => {
          commit('SET_REPORT_DATA', deepFreeze({
            reportData: result,
            previous,
            period,
            reportFor,
            isList: !result[reportFor],
            groupBy,
          }));
          commit('SET_FETCHING_STATUS', { id: reportStatusId, metrics: [] });
        },
        errorCallback: () => {
          commit('SET_FETCHING_STATUS', { id: reportStatusId, metrics: [] });
        },
      }, { root: true });
    });
    // If this wasn't a fetch for the previous period, we call the function again
    if (!previous) {
      setTimeout(() => {
        dispatch('prepareFetchingReportData', {
          department, tenant, previous: true, forceFetch,
        });
      }, 250);
    }
  },
  async createReport({ commit }, { department, tenant }) {
    const taskId = `createreport${department || tenant}`;
    try {
      commit('SET_FETCHING_STATUS', { id: taskId, status: true });
      const newReport = await createAnalyticsReport({
        department_id: department || undefined,
        tenant_id: tenant || undefined,
      });
      commit('SET_REPORT', {
        ...newReport,
        reportFor: newReport.department_id || newReport.tenant_id,
      });
      commit('SET_REPORT_BOXES', newReport.layout);
    } catch (error) {
      commit('errorDisplay/ADD_MSG', {
        message: 'errors.unknownError',
        variant: 'danger',
      }, { root: true });
    } finally {
      commit('SET_FETCHING_STATUS', { id: taskId, status: false });
    }
  },
  async fetchSingleReport({ state, commit, dispatch }, { department, tenant }) {
    try {
      // adding report to fetching state
      commit('SET_FETCHING_STATUS', { id: department || tenant, status: true });
      const reportId = state.reportStructure[department || tenant]?.id;
      const updatedReport = await getAnalyticsReport(reportId);
      commit('SET_REPORT', updatedReport);

      // removing report from fetching state
      commit('SET_FETCHING_STATUS', { id: department || tenant, status: false });

      // fetch data
      dispatch('prepareFetchingReportData', { department, tenant, forceFetch: true });
    } catch (error) {
      commit('errorDisplay/ADD_MSG', {
        message: 'errors.unknownError',
        variant: 'danger',
      }, { root: true });
    }
  },
  async updateReport({ state, commit }, { department, tenant, order }) {
    const reportId = state.reportStructure[department || tenant]?.id;

    try {
      await updateAnalyticsReport(reportId, {
        department_id: department || undefined,
        tenant_id: tenant || undefined,
        layout: order,
      });
      commit('UPDATE_REPORT_LAYOUT', { newLayout: order, department, tenant });
    } catch (error) {
      commit('errorDisplay/ADD_MSG', {
        message: 'errors.unknownError',
        variant: 'danger',
      }, { root: true });
    }
  },
  async deleteReport({ state, commit }, { department, tenant }) {
    const reportId = state.reportStructure[department || tenant]?.id;

    try {
      await deleteAnalyticsReport(reportId);
      commit('DELETE_REPORT', department || tenant);
    } catch (error) {
      commit('errorDisplay/ADD_MSG', {
        message: 'errors.unknownError',
        variant: 'danger',
      }, { root: true });
    }
  },
  async addReportBox({ state, commit, dispatch }, {
    department, tenant, metric, layout, groupBy, ascending, skipRefresh = false,
  }) {
    const reportId = state.reportStructure[department || tenant]?.id;
    try {
      const newReportBox = await addAnalyticsReportBox({
        report: reportId,
        metric,
        layout,
        group_by: groupBy,
        ascending,
      });
      commit('SET_REPORT_BOXES', [newReportBox]);
      if (!skipRefresh) dispatch('fetchSingleReport', { department, tenant });
    } catch (error) {
      commit('errorDisplay/ADD_MSG', {
        message: 'errors.unknownError',
        variant: 'danger',
      }, { root: true });
    }
  },
  async updateReportBox({ commit }, {
    id, metric, layout, groupBy, ascending,
  }) {
    try {
      const updatedReportBox = await updateAnalyticsReportBox(id, {
        metric,
        layout,
        group_by: groupBy,
        ascending,
      });
      commit('SET_REPORT_BOXES', [updatedReportBox]);
    } catch (error) {
      commit('errorDisplay/ADD_MSG', {
        message: 'errors.unknownError',
        variant: 'danger',
      }, { root: true });
    }
  },
  async deleteReportBox({ getters, commit }, { id, department, tenant }) {
    try {
      commit('SET_FETCHING_STATUS', { id: department || tenant, status: true });
      const newLayout = getters.getSingleReportOrder(department || tenant)
        .filter((boxId) => boxId !== id);
      commit('UPDATE_REPORT_LAYOUT', { newLayout, department, tenant });
      commit('DELETE_REPORT_BOX', id);
      await deleteAnalyticsReportBox(id);
    } catch (error) {
      commit('errorDisplay/ADD_MSG', {
        message: 'errors.unknownError',
        variant: 'danger',
      }, { root: true });
    } finally {
      commit('SET_FETCHING_STATUS', { id: department || tenant, status: false });
    }
  },
  async fetchNextPageBoxes({ commit, dispatch }, nextUrl) {
    // this was made during a testweek. Could probably be made nicer one day
    const next = `/control${nextUrl.split('/control')[1]}`;
    const boxesResult = await getAnalyticsReportBox('', next);
    commit('SET_REPORT_BOXES', boxesResult.results);
    if (boxesResult.next) await dispatch('fetchNextPageBoxes', boxesResult.next);
  },
  // I GAVE UP
  /* async fetchReportChatIds(
    { state, dispatch },
    { params, department, tenant },
  ) {
    const metric = 'chat_ids';
    const period = state.selectedPeriod;
    const { start, end } = preparePeriod(period, state.customPeriod);
    const data = await dispatch(
      'analytics/fetchAnalyticsData',
      {
        ...params,
        intervalEndpoints: [mstoSec(start), mstoSec(end)],
        metrics: [metric],
      },
      { root: true });
    return data?.[department || tenant]?.[metric]?.[0] || [];
  }, */
};

export default {
  namespaced: true,
  state: reportState,
  getters: reportGetters,
  mutations,
  actions,
};
