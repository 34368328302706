import Vue from 'vue';
import { waitUntil, deepFreeze } from 'supwiz/supchat/generalUtils';
import { isEmpty } from 'lodash';
import { simplifyWordMatching, prepareBoolExpr } from '@/utils/insights';
import {
  listDashboards,
  createDashboard,
  modifyDashboard,
  deleteDashboard,
  listDashboardGraphs,
  createDashboardGraph,
  initializeDashboardGraph,
  modifyDashboardGraph,
  deleteDashboardGraph,
  orderDashboardGraphs,
  getDashboardGraphChartData,
  getInsightsCategories,
  addInsightsCategories,
  updateInsightsCategories,
  deleteInsightsCategories,
} from '@/api/apiList';

const insightsState = {
  insightsObjectsReady: false,
  dashboardIds: [],
  dashboards: {},
  alarmGroupIds: [],
  alarmGroups: {},
  dashboardGraphIds: [],
  dashboardGraphs: {},
  dashboardGraphChartData: {},
  dashboardGraphChartCompleteData: {},
  categories: {},
  fetchingState: new Set(),
};

const insightGetters = {
  getAvailableDashboardsForTenants: (state) => (tenants) => {
    const availableDashboards = [];
    for (const dashboard of state.dashboardIds.map((id) => state.dashboards[id])) {
      let available = true;
      for (const tenant of dashboard.tenants) {
        if (!tenants.includes(tenant)) {
          available = false;
        }
      }
      if (available) {
        availableDashboards.push(dashboard);
      }
    }
    return availableDashboards;
  },
  getGraphFromId: (state) => (graphId) => state.dashboardGraphs[graphId],
  getGraphChartDataFromId: (state) => (graphId) => state.dashboardGraphChartData[graphId],
  getCategoryFromId: (state) => (categoryId) => state.categories[categoryId],
  categoryOptions: (state) => (!isEmpty(state.categories)
    ? Object.values(state.categories).map((cat) => ({ value: cat.id, text: cat.name }))
    : []),
  categoryInUseIn: (state) => (categoryId) => {
    // run through the boolean_expr and check if the category is used anywhere
    const graphs = state.dashboardGraphs;
    const categoryUsedIn = [];
    Object.values(graphs).forEach((graph) => {
      const boolexprChildren = graph.boolean_expr?.children;
      if (boolexprChildren) {
        const kindAllChildren = boolexprChildren.filter((child) => child.kind === 'all');
        if (kindAllChildren.length) {
          const isCategoryUsed = kindAllChildren
            .some((child) => child.children
              .some((grandChild) => grandChild
                .kind === 'category' && grandChild.category_id === categoryId));
          if (isCategoryUsed) {
            categoryUsedIn.push(graph.name);
          }
        }
      }
    });
    return categoryUsedIn;
  },
};

const mutations = {
  SET_INSIGHTS_OBJECTS_READY(state, { ready }) {
    state.insightsObjectsReady = ready;
  },
  SET_DASHBOARDS(state, { dashboards }) {
    const dashboardIds = [];
    const dashboardDict = {};
    for (const dashboard of dashboards) {
      dashboardIds.push(dashboard.id);
      dashboardDict[dashboard.id] = dashboard;
    }
    Vue.set(state, 'dashboardIds', dashboardIds);
    Vue.set(state, 'dashboards', dashboardDict);
  },
  SET_DASHBOARD_GRAPHS(state, { graphs }) {
    const graphIds = [];
    const graphDict = {};
    for (const graph of graphs) {
      graphIds.push(graph.id);
      graphDict[graph.id] = graph;
      Vue.set(state.dashboardGraphChartData, graph.id, null);
    }
    Vue.set(state, 'dashboardGraphIds', graphIds);
    Vue.set(state, 'dashboardGraphs', graphDict);
  },
  SET_DASHBOARD_GRAPH_CHART_DATA(state, { graphId, data }) {
    Vue.set(state.dashboardGraphChartData, graphId, data.chart_data);
    Vue.set(
      state.dashboardGraphChartCompleteData,
      graphId,
      data.contains_complete_data,
    );
  },
  SET_FETCHING_STATE(state, { id, command }) {
    state.fetchingState[command](id);
  },
  SET_INSIGHTS_CATEGORIES(state, categories) {
    if (!categories.length) return;
    const categoryIds = categories.map((cat) => cat.id);
    categoryIds.forEach((id) => {
      const categoryObject = categories.find((cat) => cat.id === id);
      if (categoryObject) {
        const {
          requiredWords,
          disallowedWords,
        } = simplifyWordMatching(categoryObject.boolean_expr);
        Vue.set(state.categories, id, deepFreeze({
          name: categoryObject.name,
          id: categoryObject.id,
          requiredWords,
          disallowedWords,
        }));
      }
    });
  },
  DELETE_INSIGHTS_CATEGORY(state, categoryId) {
    Vue.delete(state.categories, categoryId);
  },
};

const actions = {
  async fetchInsightObjects({ commit }) {
    commit('SET_INSIGHTS_OBJECTS_READY', { ready: false });
    const [dashboards, graphs] = await Promise.all([
      listDashboards(),
      listDashboardGraphs(),
    ]);
    commit('SET_DASHBOARDS', { dashboards });
    commit('SET_DASHBOARD_GRAPHS', { graphs });
    commit('SET_INSIGHTS_OBJECTS_READY', { ready: true });
  },
  async fetchDashboardChartData({ state, dispatch },
    {
      dashboardId, startTime, endTime, groupBy, timezone,
    }) {
    const results = [];
    for (const graphId of state.dashboards[dashboardId].graphs) {
      results.push(dispatch('fetchGraphChartData', {
        graphId,
        startTime,
        endTime,
        groupBy,
        timezone,
      }));
    }
    await Promise.all(results);
  },
  async fetchGraphChartData({ commit },
    {
      graphId, startTime, endTime, groupBy, timezone,
    }) {
    const data = await getDashboardGraphChartData({
      dashboard_graph_id: graphId,
      start_time: startTime,
      end_time: endTime + 1,
      group_by: groupBy,
      timezone,
    });

    commit('SET_DASHBOARD_GRAPH_CHART_DATA', { graphId, data });
    return data;
  },
  async setGraphOrdering({ commit, dispatch }, { dashboardId, graphIds }) {
    commit('SET_INSIGHTS_OBJECTS_READY', { ready: false });
    await orderDashboardGraphs({ dashboardId, graphIds });
    await dispatch('fetchInsightObjects');
  },
  async createDashboard({ commit, dispatch }, { dashboard }) {
    commit('SET_INSIGHTS_OBJECTS_READY', { ready: false });
    await createDashboard({ dashboard: { ...dashboard, graphs: [] } });
    await dispatch('fetchInsightObjects');
  },
  async updateDashboard({ commit, dispatch }, { dashboard }) {
    commit('SET_INSIGHTS_OBJECTS_READY', { ready: false });
    await modifyDashboard({ dashboard });
    await dispatch('fetchInsightObjects');
  },
  async deleteDashboard({ commit, dispatch }, { dashboardId }) {
    commit('SET_INSIGHTS_OBJECTS_READY', { ready: false });
    await deleteDashboard({ dashboardId });
    await dispatch('fetchInsightObjects');
  },
  async createDashboardGraph({ state, commit, dispatch }, { graph }) {
    const dashboard = state.dashboards[graph.dashboard];
    const existingGraphs = dashboard.graphs.map((graphId) => state.dashboardGraphs[graphId]);
    let maxPosition = 0;
    if (existingGraphs.length > 0) {
      maxPosition = Math.max(...existingGraphs.map(({ position }) => position));
    }
    commit('SET_INSIGHTS_OBJECTS_READY', { ready: false });
    const resp = await Promise.resolve(createDashboardGraph({
      graph: {
        ...graph, position: maxPosition + 1,
      },
    }));
    dispatch('initializeDashboardGraph', { data: { dashboard_graph_id: resp.id } });
  },
  async initializeDashboardGraph({ dispatch }, { data }) {
    await initializeDashboardGraph(data);
    await dispatch('fetchInsightObjects');
  },
  async updateDashboardGraph({ commit, dispatch }, { graph }) {
    commit('SET_INSIGHTS_OBJECTS_READY', { ready: false });
    await modifyDashboardGraph({ graph });
    await dispatch('fetchInsightObjects');
  },
  async deleteDashboardGraph({ commit, dispatch }, { graphId }) {
    commit('SET_INSIGHTS_OBJECTS_READY', { ready: false });
    await deleteDashboardGraph({ graphId });
    await dispatch('fetchInsightObjects');
  },
  async fetchCategories({ state, commit }, categoryId = '') {
    const taskId = `categories${categoryId}`;
    if (state.fetchingState.has(taskId)) {
      await waitUntil(() => !state.fetchingState.has(taskId));
    } else {
      try {
        commit('SET_FETCHING_STATE', { id: taskId, command: 'add' });
        const categories = await getInsightsCategories(categoryId);
        commit('SET_INSIGHTS_CATEGORIES', categoryId !== '' ? [categories] : categories.results);
        commit('SET_FETCHING_STATE', { id: taskId, command: 'delete' });
      } catch (error) {
        Vue.$log.error(error);
      }
    }
  },
  async addCategory({ state, commit }, category) {
    const taskId = `categories${category.name}add`;
    if (state.fetchingState.has(taskId)) {
      await waitUntil(() => !state.fetchingState.has(taskId));
    } else {
      try {
        commit('SET_FETCHING_STATE', { id: taskId, command: 'add' });
        const addedCategory = await addInsightsCategories({
          name: category.name,
          language: null,
          boolean_expr: prepareBoolExpr({
            hasWordMatching: true,
            fuzzyMatchEnabled: false,
            requiredWords: category.requiredWords,
            disallowedWords: category.disallowedWords,
          }),
        });
        commit('SET_INSIGHTS_CATEGORIES', [addedCategory]);
        commit('SET_FETCHING_STATE', { id: taskId, command: 'delete' });
      } catch (error) {
        Vue.$log.error(error);
        throw error;
      }
    }
  },
  async updateCategory({ state, commit }, category) {
    const taskId = `categories${category.id}update`;
    if (state.fetchingState.has(taskId)) {
      await waitUntil(() => !state.fetchingState.has(taskId));
    } else {
      try {
        commit('SET_FETCHING_STATE', { id: taskId, command: 'add' });
        const updatedCategory = await updateInsightsCategories(category.id, {
          name: category.name,
          language: null,
          boolean_expr: prepareBoolExpr({
            hasWordMatching: true,
            fuzzyMatchEnabled: false,
            requiredWords: category.requiredWords,
            disallowedWords: category.disallowedWords,
          }),
        });
        commit('SET_INSIGHTS_CATEGORIES', [updatedCategory]);
        commit('SET_FETCHING_STATE', { id: taskId, command: 'delete' });
      } catch (error) {
        Vue.$log.error(error);
        throw error;
      }
    }
  },
  async deleteCategory({ state, commit }, { id: categoryId }) {
    const taskId = `categories${categoryId}delete`;
    if (state.fetchingState.has(taskId)) {
      await waitUntil(() => !state.fetchingState.has(taskId));
    } else {
      try {
        commit('SET_FETCHING_STATE', { id: taskId, command: 'add' });
        await deleteInsightsCategories(categoryId);
        commit('DELETE_INSIGHTS_CATEGORY', categoryId);
        commit('SET_FETCHING_STATE', { id: taskId, command: 'delete' });
      } catch (error) {
        Vue.$log.error(error);
        throw error;
      }
    }
  },
};

export default {
  namespaced: true,
  state: insightsState,
  getters: insightGetters,
  mutations,
  actions,
};
